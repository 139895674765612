import ActiveInactiveText from "../common/ActiveInactiveText";
import HeaderCommon from "../common/HeaderCommon";

import "./ServerRoomHeader.css";

export default function ServerRoomHeader(props) {
  const { title, onClickBack, onHomeClick, beforeNavigate = () => {} } = props;

  return (
    <HeaderCommon beforeNavigate={beforeNavigate} onHomeClick={onHomeClick}>
      <div className="server-room-header__title">
        {onClickBack && (
          <img
            src="/img/common/left-arrow-gray.svg"
            width={36}
            onClick={onClickBack}
          />
        )}
        <div>
          <ActiveInactiveText
            text={title}
            size={3}
            activeStyle="text"
          ></ActiveInactiveText>
        </div>
      </div>
    </HeaderCommon>
  );
}
