import React, { useEffect, useState } from "react"
import {range} from "lodash"
import { FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import HeaderCommon from "./HeaderCommon";

export default function PinPage(props) {
  const [pin, setPin] = useState("");

  useEffect(() => {
    if (pin.length == 6 && props.onClick) {
      props.onClick(pin);
      setPin("");
    }
  }, [pin])

  return (
    <div>
      <HeaderCommon title={props.title || "Enter Pin"} showHome={false}></HeaderCommon>

      <div style={{...FLEX_ROW_CENTER_CENTER, marginTop: 96, marginBottom: 128}}>
        {[0,1,2,3,4,5].map(x => (
          <div style={{
            marginLeft: 8,
            marginRight: 8,
            borderRadius: '50%',
            width: 24,
            height: 24,
            backgroundColor: 'white',
            opacity: x < pin.length ? 1 : 0.4,
          }}></div>
        ))}
      </div>

      <div style={{
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        rowGap: 96,
        columnGap: 96,
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, -1, 0].map(num => (
          <div 
            style={{
              ...FLEX_ROW_CENTER_CENTER,
              borderRadius: '50%',
              padding: 16,
              backgroundColor: "rgba(255,255,255,0.3)",
              width: 128,
              height: 128,
              fontSize: '3rem',
            }}
            onClick={() => setPin(num != -1 ? pin + num : pin.substr(0, pin.length - 1))}
          >
            {num != -1 ? num : 'X'}
          </div>
        ))}
      </div>
    </div>
  )
}