import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import { FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";

const DAY_LABEL = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];

export default observer<any>(({ value, onToggle }) => {
  function isActive(d) {
    return value.indexOf(d) != -1;
  }

  return (
    <div style={{...FLEX_ROW_CENTER, justifyContent: "space-between" }}>
      {[0, 1, 2, 3, 4, 5, 6].map(d => (
        <div
          style={{
            ...FLEX_ROW_CENTER_CENTER,
            borderRadius: "50%",
            borderStyle: "solid",
            borderColor: "white",
            borderWidth: 3,
            padding: 8,
            width: 56,
            height: 56,
            opacity: isActive(d) ? 1 : 0.6
          }}
          onClick={() => onToggle(d)}
        >
          {DAY_LABEL[d]}
        </div>
      ))}

    </div>
  );
});
