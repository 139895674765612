import React, { useState, useEffect } from "react";
import { api } from "../../utils/api";
import { globalLoadingFn } from "../../utils/loading";
import { ACTIVE_BUILDING_ID } from "../../utils/globalState";
import ActiveInactiveText from "../common/ActiveInactiveText";
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_COLUMN, FLEX_COLUMN_CENTER_CENTER, BLACK_CARD, FLEX_LR_CENTER } from "../../theme/style";
import DeviceListItem from "./DeviceListItem";
import DeviceList from "./DeviceList";
import AirControlPanel from "../control/AirControlPanel";
import DeviceTypeSelect from "./DeviceTypeSelect";
import ControlPanel from "../control/ControlPanel";
import DeviceCountPanel from "./DeviceCountPanel";
import { MapView } from "./MapView";
import DeviceListFloorSelect from "./DeviceListFloorSelect";
import TimeWithDate from "../common/TimeWithDate";
import HeaderCommon from "../common/HeaderCommon";
import { DeviceStore, fetchBuilding } from "../../stores/DeviceStore";
import { observer } from "mobx-react-lite";
import { newScene, SceneStore, updateSceneData } from "../../stores/SceneStore";
import { baseScrollEvent } from "../../utils/scroll";
import SceneControl from "./SceneControl";
import Switch from "react-switch";

const NAME_DEVICE_TYPE_ID = [
  ['light', 3],
  ['air', 1],
  ['curtain', 4],
]

export default observer(function RoomDevices() {
  const scenes = SceneStore.scenes;
  const selectedScene = SceneStore.editingScene;

  const [building, setBuilding] = useState<any>()
  const [roomId, setRoomId] = useState<number | null>(95)
  const [devices, setDevices] = useState<any[]>([]);
  const [tabMode, setTabMode] = useState("device");
  const [activeDevice, setActiveDevice] = useState<any>(null);
  const activeRoom = DeviceStore.activeRoom;
  const [activeDeviceType, setActiveDeviceType] = useState(1);
  const [selectRoomMode, setSelectRoomMode] = useState(false);

  const controlStyle = (tabMode == "device" ? 0 : (tabMode == "room" ? 1 : 2));

  // console.log("xxx");

  async function refreshBuilding() {
    let buildingResponse = await globalLoadingFn(() =>
      api.get("/buildings/" + ACTIVE_BUILDING_ID)
    );

    setBuilding(buildingResponse.data);
  }

  async function refreshData() {
    await fetchBuilding({alwaysLoading: true});
    setActiveDevice(DeviceStore.activeRoom.children.find(x => x.id == activeDevice.id));

    return;

    // console.log(roomResponse.data)
  }

  useEffect(() => {
    refreshBuilding();
  }, [])

  // console.log(floor);

  console.log(activeRoom?.children)

  if (!activeRoom.children) return <div></div>

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >      
      <HeaderCommon>
        <div style={{ ...FLEX_ROW_CENTER_CENTER, marginBottom: 16 }}>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <ActiveInactiveText
              text="Scene"
              active={tabMode == "scene"}
              size={2}
              onClick={() => {
                setTabMode("scene");
              }}
            />
          </div>

          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <ActiveInactiveText
              text="อุปกรณ์"
              active={tabMode == "device"}
              size={2}
              onClick={() => {
                setTabMode("device");
              }}
            />
          </div>
        </div>
      </HeaderCommon>

      {activeRoom && tabMode == 'device' &&
        <div style={{
          display: 'grid',
          gridTemplateColumns: '60% 1fr',
          alignItems: 'center',
          columnGap: 36,
          paddingLeft: 36,
          paddingRight: 36,
          height: '100%',
        }}>
          <div>
            {activeRoom &&
              <div style={{marginTop: 8, marginBottom: 8}}>
                <MapView 
                  data={activeRoom}
                  roomMode={true}
                  onChangeDevice={(device) => setActiveDevice(device)}
                  activeDeviceId={activeDevice?.id}
                ></MapView>
              </div>
            }
          </div>

          <div>
            <div style={{zoom: 2}}>
              {activeRoom.children.find(device => device.device_type_id == activeDeviceType) && (
                <ControlPanel
                  scopeId={activeDevice ? activeDevice.id : activeRoom.id}
                  scopeType={activeDevice ? "device" : "room"}
                  device={activeDevice || activeRoom.children.find(device => device.device_type_id == activeDeviceType)}
                  refreshData={refreshData}
                  showType={controlStyle}
                ></ControlPanel>
              )}
            </div>

            <div style={{flexBasis: 300, zoom: 1.5, marginTop: 28}}>
              <DeviceTypeSelect
                activeDeviceType={activeDeviceType}
                setActiveDeviceType={(type) => {
                  setActiveDeviceType(type);
                  setActiveDevice(null);
                }}
                onBreakerSelect={() => {
                  setActiveDevice(DeviceStore.breaker);
                }}
              ></DeviceTypeSelect>
            </div>
          </div>
        </div>
      }

      <div style={{ flexGrow: 1 }}>
        <div style={{ ...FLEX_ROW_CENTER_CENTER, marginBottom: 16 }}></div>

        {tabMode == "scene" && <>
          <div style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "start", padding: 24 }}>
            <div style={{ flexGrow: 1, marginRight: 24, ...FLEX_COLUMN_CENTER_CENTER }}>
              {scenes.map(scene => (
                <div className="text-overflow-ellipsis">
                  <ActiveInactiveText
                    text={scene.name}
                    active={selectedScene && selectedScene.id == scene.id}
                    onClick={() => SceneStore.loadSceneForEdit(scene)}
                  />
                </div>
              ))}
            </div>

            {(selectedScene || true) && 
              <div style={{ ...BLACK_CARD, backgroundColor: "rgba(0,0,0,0.4)" }}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: 8}}>
                  {NAME_DEVICE_TYPE_ID.map(entity => (
                    <div style={{overflowY: "scroll", overflowX: "hidden", maxHeight: 500}} {...baseScrollEvent}>
                      {
                        selectedScene && [activeRoom ?? SceneStore.getSceneForDeviceList()].map((room) => (
                          <DeviceList
                            flexDirection="column"
                            roomId={room.id}
                            key={room.id}
                            roomData={selectedScene && activeRoom ? SceneStore.patchDevice(room) : room}
                            deviceType={entity[1] as number}
                            activeDevice={activeDevice}
                            activeDeviceType={selectRoomMode ? activeDeviceType : null}
                            setActiveDevice={(device: any) => {
                              setActiveDevice(device);
                            }}
                          ></DeviceList>
                        ))
                      }
                    </div>
                  ))}
                </div>
              </div>
            }

            <div style={{ flexBasis: 100, ...FLEX_ROW_CENTER_CENTER, marginTop: 24 }}>
              <div 
                style={{ width: 50, height: 50, textAlign: "center", fontSize: '2rem', backgroundColor: "black", borderRadius: "50%" }}
                onClick={() => {
                  newScene();
                }}
              >
                +
              </div>
            </div>

            <div style={{ ...FLEX_ROW_CENTER, alignItems: "flex-start", marginTop: 24 }}>
              <div style={{marginRight: 24, display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <div style={{ ...BLACK_CARD }}>
                  <div>
                    <SceneControl></SceneControl>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {/* <DeviceListFloorSelect data={{
                      children: [
                        {
                          id: 0,
                          name: "อุปกรณ์ใน Scene",
                        },
                        ...floor.children
                      ]}} 
                      selectedId={activeRoom?.id ?? 0} 
                      optional={true}
                      onChange={(x) => setActiveRoom(floor.children.find(c => c.id == x) ?? null)}
                    ></DeviceListFloorSelect> */}
                  </div>
                </div>
              </div>

              {activeDevice && selectedScene &&
                <div style={{ flexGrow: 1 }}>
                  {/* Switch on/off for each device */}
                  <div style={{ ...BLACK_CARD, marginBottom: 16 }}>
                    <div style={{ ...FLEX_LR_CENTER }} >
                      <div>ควบคุม</div>
                      <div>
                        <Switch
                          onChange={async (checked) => {
                            SceneStore.setControlActive(activeDevice, checked);
                            updateSceneData();
                          }}
                          checked={SceneStore.getControlData(activeDevice).active}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#FFA726"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Control section */}
                  {SceneStore.getControlData(activeDevice).active &&
                    <ControlPanel
                      scopeId={activeDevice.id}
                      scopeType="device"
                      device={SceneStore.patchDevice(activeDevice)}
                      refreshData={() => {}}
                      showType={controlStyle}
                      overrideOnControl={true}
                      onControl={(device, controlKey, controlValue) => {
                        SceneStore.setControlData(device, controlKey, controlValue);
                        updateSceneData();
                      }}
                    ></ControlPanel>
                  }
                </div>
              }
            </div>

          </div>

        </>}
      </div>
    </div>
  );
})
