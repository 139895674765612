export default function SaveButton({
  size = 1,
  style = {},
  onClick = () => {},
}) {
  let IMAGE_SIZE = 20 * size;
  return (
    <div onClick={onClick} style={{ ...style }}>
      <img
        src="/img/common/save.svg"
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
      ></img>
    </div>
  );
}
