import { useEffect, useState } from "react";
import { FLEX_LR_CENTER, GRAY_BACKGROUND_COLOR } from "../../theme/style";
import ControlButton from "../control/ControlButton";

import "./ACControlCard.css";
import ACFanControlButton from "./ACFanControlButton";
import ACModeControlButton from "./ACModeControlButton";
import ACSwingControlButton from "./ACSwingControlButton";
import ACTempControlButton from "./ACTempControlButton";

export default function ACControlCard({
  scopeType,
  scopeId,
  device,
  deviceControl,
  refreshData,
  size = 2,
  overrideOnControl = false,
  onChange = (_) => {},
}) {
  const commonParameter = {
    scopeType,
    scopeId,
    device,
    refreshData,
    overrideOnControl,
  };

  const [deviceStatus, setDeviceStatus] = useState(deviceControl.device_status);

  const [deviceTemp, setDeviceTemp] = useState(deviceControl.device_temp);

  const [deviceMode, setDeviceMode] = useState(deviceControl.device_mode);

  const [deviceSwingMode, setDeviceSwingMode] = useState(
    deviceControl.device_swing_mode
  );

  const [deviceFanSpeed, setDeviceFanSpeed] = useState(
    deviceControl.device_fan_speed
  );

  useEffect(() => {
    setDeviceStatus(deviceControl.device_status);
    setDeviceTemp(deviceControl.device_temp);
    setDeviceMode(deviceControl.device_mode);
    setDeviceSwingMode(deviceControl.device_swing_mode);
    setDeviceFanSpeed(deviceControl.device_fan_speed);
  }, [
    deviceControl.device_status,
    deviceControl.device_temp,
    deviceControl.device_mode,
    deviceControl.device_swing_mode,
    deviceControl.device_fan_speed,
  ]);

  function tempChangeHandler(temp) {
    setDeviceTemp(temp);
    onChange({ ...deviceControl, device_temp: temp });
  }

  function modeChangeHandler(mode) {
    setDeviceMode(mode);
    onChange({ ...deviceControl, device_mode: mode });
  }

  function swingModeChangeHandler(swingMode) {
    setDeviceSwingMode(swingMode);
    onChange({ ...deviceControl, device_swing_mode: swingMode });
  }

  function fanSpeedChangeHandler(fanSpeed) {
    setDeviceFanSpeed(fanSpeed);
    onChange({ ...deviceControl, device_fan_speed: fanSpeed });
  }

  function statusChangeHandler(status) {
    onChange({ ...deviceControl, device_status: status });
    setDeviceStatus(status);
  }

  const off = !parseInt(deviceStatus);

  return (
    <>
      {/* MODE PANEL */}
      <ACModeControlButton
        {...commonParameter}
        deviceMode={deviceMode}
        deviceStatus={deviceStatus}
        onModeChange={modeChangeHandler}
        onStatusChange={statusChangeHandler}
        size={size}
      />
      {/* TEMP PANEL */}
      <ACTempControlButton
        {...commonParameter}
        deviceStatus={deviceStatus}
        deviceTemp={deviceTemp}
        deviceControl={deviceControl}
        onTempChange={tempChangeHandler}
        size={size}
      />

      {/* BOTTOM PANEL */}
      <div
        className={off ? "common-inactive" : ""}
        style={{ ...FLEX_LR_CENTER }}
      >
        <ACSwingControlButton
          {...commonParameter}
          deviceStatus={deviceStatus}
          deviceSwingMode={deviceSwingMode}
          onSwingModeChange={swingModeChangeHandler}
          size={size * 2}
        />
        <ACFanControlButton
          {...commonParameter}
          deviceFanSpeed={deviceFanSpeed}
          onFanSpeedChange={fanSpeedChangeHandler}
          size={size * 2}
        />
      </div>
    </>
  );
}
