import { cloneDeep } from "lodash";
import { makeAutoObservable } from "mobx";
import { api } from "../utils/api";
import { ACCESS_TOKEN, ACTIVE_BUILDING_ID } from "../utils/globalState";
import { globalLoadingFn } from "../utils/loading";
import { DeviceStore } from "./DeviceStore";
import { ServerRoomStore } from "./ServerRoomStore";

const getOpenTime = (a) => {
  return a.device_schedules
    .find((x) => x.schedule_wrapper_type == "common_on")
    ?.every_time.slice(0, 5);
};

const getCloseTime = (a) => {
  return a.device_schedules
    .find((x) => x.schedule_wrapper_type == "common_off")
    ?.every_time.slice(0, 5);
};

const defaultControls = {
  common_off: {
    control_type: "fixed",
    every_time: "14:00",
    control_data: {
      device_status: "0",
    },
  },
};

const compareTime = (a, b, a_, b_) => {
  console.log(a, b);

  if (!a && !b) {
    return a_ < b_ ? -1 : 1;
  }
  if (a && !b) return -1;
  if (b && !a) return 1;

  let partsA = a.split(":").map(x => parseInt(x));
  let partsB = b.split(":").map(x => parseInt(x));

  let aTime = partsA[0] * 3600 + partsA[1] * 60 + partsA[2];
  let bTime = partsB[0] * 3600 + partsB[1] * 60 + partsB[2];

  console.log(partsA, partsB);

  if (aTime < bTime) return -1;
  else if (bTime < aTime) return 1;

  return a_ < b_ ? -1 : 1;
}

const groupByNameForEdit = (s) => {
  const schedulesMap = s.reduce((acc, schedule) => {
    const scheduleForEdit = { ...schedule };

    const openDeviceSchedule = scheduleForEdit.device_schedules.find(
      (x) => x.schedule_wrapper_type == "common_on"
    );
    const closeDeviceSchedule = scheduleForEdit.device_schedules.find(
      (x) => x.schedule_wrapper_type == "common_off"
    );

    if (!openDeviceSchedule && !closeDeviceSchedule) return acc;

    const elem = {
      controls: {
        common_on: openDeviceSchedule,
        common_off: closeDeviceSchedule,
      },
      device_scopes: cloneDeep(
        scheduleForEdit.device_schedule_scopes.map((s) => s.scope)
      ),
      schedule_id: scheduleForEdit.id,
      schedule_wrapper_id: openDeviceSchedule
        ? openDeviceSchedule.device_schedule_wrapper_id
        : closeDeviceSchedule.device_schedule_wrapper_id,
      status: scheduleForEdit.status,
    };

    if (scheduleForEdit.name in acc) {
      acc[scheduleForEdit.name].device_schedule_wrappers.push(elem);
    } else {
      scheduleForEdit.device_schedule_wrappers = [];
      scheduleForEdit.device_schedule_wrappers.push(elem);
      acc[schedule.name] = scheduleForEdit;
    }
    acc[schedule.name].device_schedule_wrappers.sort((a, b) => {
      return compareTime(a.controls.common_on?.every_time || a.controls.common_off?.every_time, b.controls.common_on?.every_time || b.controls.common_off?.every_time, a.schedule_id, b.schedule_id);
    });
    return acc;
  }, {});

  return Object.values(schedulesMap).sort(byNameFunction);
};

const byNameFunction = (a, b) => {
  if (a.name < b.name) return -1;
  else return 1;
};

const scheduleSortFunction = (a, b) => {
  if (a.name < b.name) return -1;
  else if (a.name > b.name) return 1;

  if (getOpenTime(a) < getOpenTime(b)) return -1;
  else if (getOpenTime(a) > getOpenTime(b)) return 1;

  if (getCloseTime(a) < getCloseTime(b)) return -1;
  return 1;
};

export class ScheduleStoreClass {
  schedules: any = [];
  originalGroupedSchedules: any = [];
  activeScheduleIndex: number = 0;
  activeDeviceScheduleWrapperIndex: number = 0;
  editingGroupedSchedule: any = {};

  editingScheduleEnabled = false;

  currentScopeType: string = 'room';
  currentScopeId: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setSchedules(schedules: any) {
    const sortedSchedules = schedules.sort(scheduleSortFunction);
    this.originalGroupedSchedules = groupByNameForEdit(schedules);
    this.schedules = sortedSchedules;
  }

  newSchedule() {
    let editingSchedule = {
      name: "Untitled",
      request_reason: "Untitled",
      control_entity: "device_control_air",
      controls: cloneDeep(defaultControls),
      repeat_day: [0, 1, 2, 3, 4, 5, 6],
      repeat_date: [],
      include_date: [],
      except_date: [],
      status: "",
      timezone: "+07:00",
      building_id: ACTIVE_BUILDING_ID,
      scopes: [
        {
          scope_type: this.currentScopeType,
          scope_id: this.currentScopeId,
        },
        // {
        //   scope_type: "building",
        //   scope_id: ACTIVE_BUILDING_ID,
        //   type: "building",
        //   id: ACTIVE_BUILDING_ID,
        //   name: "Test",
        //   total_count: 1,
        // },
        // {
        //   scope_type: "device",
        //   scope_id: ServerRoomStore.serverRoom.children[0].id,
        //   type: "device",
        //   id: ServerRoomStore.serverRoom.children[0].id,
        //   name: ServerRoomStore.serverRoom.children[0].name,
        //   total_count: 1,
        // },
      ],
      id: 0,
    };
    return editingSchedule;
  }

  loadGroupedScheduleForEdit(index) {
    if (!this.originalGroupedSchedules[index]) return;
    ScheduleStore.setActiveScheduleIndex(index);
    this.editingGroupedSchedule = cloneDeep(
      this.originalGroupedSchedules[index]
    );
    ScheduleStore.setActiveDeviceScheduleWrapperIndex(0);
  }

  setActiveScheduleIndex(index) {
    this.activeScheduleIndex = index;
  }

  setActiveDeviceScheduleWrapperIndex(index) {
    if (index != this.activeDeviceScheduleWrapperIndex) {
      if (
        this.activeDeviceScheduleWrapperIndex <=
        this.originalGroupedSchedules[this.activeScheduleIndex]
          .device_schedule_wrappers.length -
          1
      ) {
        this.editingGroupedSchedule.device_schedule_wrappers[
          this.activeDeviceScheduleWrapperIndex
        ] = cloneDeep(
          this.originalGroupedSchedules[this.activeScheduleIndex]
            .device_schedule_wrappers[this.activeDeviceScheduleWrapperIndex]
        );
      }
    }

    this.activeDeviceScheduleWrapperIndex = index;
  }

  addDeviceScheduleWrapper() {
    const elem = {
      controls: cloneDeep(defaultControls),
      device_scopes: cloneDeep(
        this.editingGroupedSchedule.device_schedule_scopes.map((s) => s.scope)
      ),
    };

    const count =
      this.editingGroupedSchedule.device_schedule_wrappers.push(elem);

    this.setActiveDeviceScheduleWrapperIndex(count - 1);
  }

  toggleWrapperStatus(index) {
    const status =
      this.editingGroupedSchedule.device_schedule_wrappers[index].status;
    this.editingGroupedSchedule.device_schedule_wrappers[index].status =
      status == "active" ? "inactive" : "active";
  }

  setName(name) {
    this.editingGroupedSchedule.name = name;
  }

  setRepeatDays(days) {
    this.editingGroupedSchedule.repeat_day = days;
  }

  setActive(active) {
    this.editingGroupedSchedule.status = active ? "active" : "inactive";
  }

  setOpenTime(index, time, includeGroup = false) {
    const controls =
      this.editingGroupedSchedule.device_schedule_wrappers[index].controls;
    if (time == "") {
      delete controls.common_on;
    } else if (controls.common_on) {
      controls.common_on.every_time = time;
    } else {
      controls.common_on = {
        control_type: "fixed",
        every_time: time,
        control_data: {
          ...cloneDeep(
            includeGroup ? DeviceStore.devices.find(x => x.device_control_air) : ServerRoomStore.serverRoom.children[0].device_control_air
          ),
          device_status: "1",
        },
      };
    }
  }

  setCloseTime(index, time) {
    const controls =
      this.editingGroupedSchedule.device_schedule_wrappers[index].controls;
    if (time == "") {
      delete controls.common_off;
    } else if (controls.common_off) {
      controls.common_off.every_time = time;
    } else {
      controls.common_off = {
        control_type: "fixed",
        every_time: time,
        control_data: {
          device_status: "0",
        },
      };
    }
  }

  setACForDeviceSchedule(index, scopes) {
    this.editingGroupedSchedule.device_schedule_wrappers[index].device_scopes =
      scopes;
  }

  setACTemp(index, temp) {
    if (
      this.editingGroupedSchedule.device_schedule_wrappers[index].controls
        .common_on
    ) {
      this.editingGroupedSchedule.device_schedule_wrappers[
        index
      ].controls.common_on.control_data.device_temp = temp;
    }
  }

  setACMode(index, mode) {
    if (
      this.editingGroupedSchedule.device_schedule_wrappers[index].controls
        .common_on
    ) {
      this.editingGroupedSchedule.device_schedule_wrappers[
        index
      ].controls.common_on.control_data.device_mode = mode;
    }
  }

  setACStatus(index, status) {
    if (
      this.editingGroupedSchedule.device_schedule_wrappers[index].controls
        .common_on
    ) {
      this.editingGroupedSchedule.device_schedule_wrappers[
        index
      ].controls.common_on.control_data.device_status = status.toString();
    }
  }

  setACSwingMode(index, swingMode) {
    if (
      this.editingGroupedSchedule.device_schedule_wrappers[index].controls
        .common_on
    ) {
      this.editingGroupedSchedule.device_schedule_wrappers[
        index
      ].controls.common_on.control_data.device_swing_mode = swingMode;
    }
  }

  setACFanSpeed(index, fanSpeed) {
    if (
      this.editingGroupedSchedule.device_schedule_wrappers[index].controls
        .common_on
    ) {
      this.editingGroupedSchedule.device_schedule_wrappers[
        index
      ].controls.common_on.control_data.device_fan_speed = fanSpeed;
    }
  }

  setCurrentScope(scopeType, scopeId) {
    this.currentScopeType = scopeType;
    this.currentScopeId = scopeId;
  }
}

export const ScheduleStore = new ScheduleStoreClass();

export async function fetchSchedules(roomId, scopeType = 'room') {
  if (ACCESS_TOKEN && ACTIVE_BUILDING_ID) {
    ScheduleStore.setCurrentScope(scopeType, roomId);

    let response = await globalLoadingFn(() =>
      api.get(`/schedules?scope_type=${scopeType}&scope_id=${roomId}`)
    );
    const schedules = response.data;

    ScheduleStore.setSchedules(schedules);
    ScheduleStore.loadGroupedScheduleForEdit(0);
  }
}

export async function fetchSchedulesRefresh() {
  return await fetchSchedules(ScheduleStore.currentScopeId, ScheduleStore.currentScopeType);
}

function findLatestScheduleIndex() {
  const latestSchedule = ScheduleStore.originalGroupedSchedules.reduce(
    (a, b) => {
      const aTime = "updated_at" in a ? a.updated_at : a.created_at;
      const bTime = "updated_at" in b ? b.updated_at : b.created_at;
      return aTime < bTime ? b : a;
    }
  );
  const latestScheduleId = latestSchedule.id;
  return ScheduleStore.originalGroupedSchedules.findIndex(
    (s) => s.id == latestScheduleId
  );
}

export async function newSchedule() {
  let schedule = ScheduleStore.newSchedule();
  let response = await globalLoadingFn(() => api.post("/schedules", schedule));
  // await fetchSchedules(ServerRoomStore.serverRoom.id);
  await fetchSchedulesRefresh();

  const index = findLatestScheduleIndex();
  ScheduleStore.loadGroupedScheduleForEdit(index);
}

function scopeTypeDetector(x) {
  if ('room_id' in x) {
    return 'device';
  }

  if ('floor_id' in x) {
    return 'room';
  }

  if ('building_id' in x) {
    return DeviceStore.building.is_two_level ? 'room' : 'floor';
  }

  return 'building';
}

function convert(groupedSchedule, index) {
  const editingSchedule = cloneDeep(groupedSchedule);

  const wrapper = groupedSchedule.device_schedule_wrappers[index];
  console.log(wrapper);
  editingSchedule.id = wrapper.schedule_id;
  editingSchedule.schedule_wrapper_id = wrapper.schedule_wrapper_id;
  editingSchedule.status = wrapper.status;
  editingSchedule.controls = wrapper.controls;
  editingSchedule.scopes = wrapper.device_scopes.map((x) => {
    return {
      scope_type: scopeTypeDetector(x),
      scope_id: x.id,
      // type: "device",
      // id: x.id,
      // name: x.name,
      // total_count: 1,
    };
  });

  const fieldsToDelete = [
    "device_schedule_timers",
    "device_schedules",
    "device_schedule_scopes",
    "device_schedule_wrappers",
  ];
  const fieldsToAdd = ["repeat_date", "include_date", "except_date"];

  for (let field of fieldsToAdd) {
    editingSchedule[field] = [];
  }

  for (let field of fieldsToDelete) {
    delete editingSchedule[field];
  }

  return editingSchedule;
}
export async function patchSchedule() {
  const editingSchedule = convert(
    ScheduleStore.editingGroupedSchedule,
    ScheduleStore.activeDeviceScheduleWrapperIndex
  );
  const editingWrappers =
    ScheduleStore.editingGroupedSchedule.device_schedule_wrappers;
  const originalWrappers =
    ScheduleStore.originalGroupedSchedules[ScheduleStore.activeScheduleIndex]
      .device_schedule_wrappers;

  console.log('WRAPPERS')
  console.log(editingWrappers)
  console.log(originalWrappers)

  if (editingWrappers.length <= originalWrappers.length) {
    console.log('XXXXXXXXXXXXXXXX')
    if (editingSchedule.status == "active") {
      await globalLoadingFn(() =>
        api.post(
          "/schedules/" + editingSchedule.schedule_wrapper_id + "/resume"
        )
      );
    } else {
      await globalLoadingFn(() =>
        api.post("/schedules/" + editingSchedule.schedule_wrapper_id + "/pause")
      );
    }
    let response = await globalLoadingFn(() =>
      api.patch("/schedules/" + editingSchedule.id, editingSchedule)
    );

    // await fetchSchedules(ServerRoomStore.serverRoom.id);
    await fetchSchedulesRefresh();
    ScheduleStore.loadGroupedScheduleForEdit(findLatestScheduleIndex());
  } else {
    let response = await globalLoadingFn(() =>
      api.post("/schedules", editingSchedule)
    );

    if (editingSchedule.status != "active") {
      await globalLoadingFn(() =>
        api.post("/schedules/" + response.data.id + "/pause")
      );
    }

    // await fetchSchedules(ServerRoomStore.serverRoom.id);
    await fetchSchedulesRefresh();
    ScheduleStore.loadGroupedScheduleForEdit(findLatestScheduleIndex());
    ScheduleStore.setActiveDeviceScheduleWrapperIndex(
      ScheduleStore.editingGroupedSchedule.device_schedule_wrappers.length - 1
    );
  }
}

export async function patchAllSchedule() {
  const editingSchedule = convert(
    ScheduleStore.editingGroupedSchedule,
    ScheduleStore.activeDeviceScheduleWrapperIndex
  );
  const editingWrappers =
    ScheduleStore.editingGroupedSchedule.device_schedule_wrappers;
  const originalWrappers =
    ScheduleStore.originalGroupedSchedules[ScheduleStore.activeScheduleIndex]
      .device_schedule_wrappers;
  
  for (let i in editingWrappers) {
    let schedule = editingWrappers[i];
    if (i < originalWrappers.length) {
      if (schedule.status == "active") {
        await globalLoadingFn(() =>
          api.post(
            "/schedules/" + schedule.schedule_wrapper_id + "/resume"
          )
        );
      } else {
        await globalLoadingFn(() =>
          api.post("/schedules/" + schedule.schedule_wrapper_id + "/pause")
        );
      }

      let response = await globalLoadingFn(() =>
        api.patch("/schedules/" + schedule.schedule_wrapper_id, convert(ScheduleStore.editingGroupedSchedule, i))
      );
    } else {
      let response = await globalLoadingFn(() =>
        api.post("/schedules", convert(ScheduleStore.editingGroupedSchedule, i))
      );

      if (editingSchedule.status != "active") {
        await globalLoadingFn(() =>
          api.post("/schedules/" + response.data.id + "/pause")
        );
      }
    }
  }

  // await fetchSchedules(ServerRoomStore.serverRoom.id);
  await fetchSchedulesRefresh();
  ScheduleStore.loadGroupedScheduleForEdit(findLatestScheduleIndex());
}

export async function deleteSchedule() {
  await globalLoadingFn(() =>
    api.delete("/schedules/" + ScheduleStore.editingGroupedSchedule.id)
  );
  // await fetchSchedules(ServerRoomStore.serverRoom.id);
  await fetchSchedulesRefresh();
  ScheduleStore.loadGroupedScheduleForEdit(0);
}

export async function deleteAllSchedule() {
  const editingWrappers =
    ScheduleStore.editingGroupedSchedule.device_schedule_wrappers;
  // console.log(editingWrappers)
  for (let wrapper of editingWrappers) {
    await globalLoadingFn(() =>
      api.delete("/schedules/" + wrapper.schedule_wrapper_id)
    );
  }

  // await fetchSchedules(ServerRoomStore.serverRoom.id);
  await fetchSchedulesRefresh();
  ScheduleStore.loadGroupedScheduleForEdit(0);
}

export async function deleteScheduleWrapper() {
  const deletedSchedule = convert(
    ScheduleStore.editingGroupedSchedule,
    ScheduleStore.activeDeviceScheduleWrapperIndex
  );

  await globalLoadingFn(() => api.delete("/schedules/" + deletedSchedule.id));
  // await fetchSchedules(ServerRoomStore.serverRoom.id);
  await fetchSchedulesRefresh();
  ScheduleStore.loadGroupedScheduleForEdit(findLatestScheduleIndex());
  ScheduleStore.setActiveDeviceScheduleWrapperIndex(0);
}
