import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import React, { useMemo, useState } from "react";

import Dialog from "../common/Dialog";

import { v4 as uuidv4 } from "uuid";

export default function BlackSelect({
  value,
  options,
  onChange,
  optional = false,
  minWidth = 150,
  variant = "standard",
  disableUnderline = false,
  sx = {},
  style = {},
}) {
  const labelId = useMemo(() => uuidv4(), []);
  const selectId = useMemo(() => uuidv4(), []);

  if (!value && !optional) return <div></div>;

  return (
    <div>
      <div
        style={{
          backgroundColor: "black",
          ...style,
          fontSize: "1.5rem",
        }}
        className="black-select"
      >
        <FormControl variant={variant as any} sx={{ m: 1, minWidth, ...sx }}>
          <InputLabel id={labelId}></InputLabel>
          <Select
            labelId={labelId}
            id={selectId}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            disableUnderline={disableUnderline}
            style={{ textAlign: "center" }}
          >
            {options.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                style={{ justifyContent: "center" }}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
