export function getDeviceColor(device_type_id) {
  switch (device_type_id) {
    case 1: return '#56D9FE';
    case 3: return '#FFA726';
    case 4: return '#5FE3A1';
    case 5: return '#A3A1FB';
    default: return 'white';
  }
}

