import React from "react"
import { FLEX_ROW_CENTER } from "../../theme/style"
import { countDevices } from "../../utils/device"

function DeviceCountItem({icon, label, count, unit, color}) {
  return <div style={{backgroundColor: "black", borderRadius: 8, ...FLEX_ROW_CENTER, padding: 12, justifyContent: "space-between"}}>
    <div style={{...FLEX_ROW_CENTER}}>
      <div>
        <img src={icon}></img>
      </div>
  
      <div style={{marginLeft: 12}}>{label}</div>
    </div>

    <div style={{textAlign: "center"}}>
      <div style={{color, fontSize: "2rem", lineHeight: "36px"}}>{count}</div>
      <div>{unit}</div>
    </div>
  </div>
}

export default function DeviceCountPanel({data}) {
  const lightCount = countDevices(data, 3)
  const airCount = countDevices(data, 1)

  return (
    <div className="device-count-panel">
      <DeviceCountItem
        icon="/img/control/light-outlined-active.svg"
        label="หลอดไฟที่เปิด"
        count={lightCount.online}
        unit="ดวง"
        color="#FFA726"
      ></DeviceCountItem>

      <DeviceCountItem
        icon="/img/control/air-outlined-active.svg"
        label="แอร์ที่เปิด"
        count={airCount.online}
        unit="เครื่อง"
        color="#56D9FE"
      ></DeviceCountItem>

      <DeviceCountItem
        icon="/img/control/curtain-outlined-active.svg"
        label="ม่านที่เปิด"
        count={0}
        unit="จุด"
        color="#5FE3A1"
      ></DeviceCountItem>
    </div>
  )
}