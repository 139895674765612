export const EMPTY_CHART_DATA = {
  x: [],
  y: [],
}

export function weeklyBarData(data) {
  if (!data) return data;

  let x: any[] = [];
  let y: any[] = [];

  for (let i = -6; i <= 0; i++) {
    let targetDate = new Date();
    targetDate.setDate(targetDate.getDate() + i);
    let targetDateNum = targetDate.getDate();

    x.push(data.x[targetDateNum - 1]);
    y.push(data.y[targetDateNum - 1]);
  }

  return {
    ...data,
    x,
    y,
  }
}