import { useState } from "react";
import LoginCard from "./LoginCard";
import RecoveryPasswordCard from "./RecoveryPasswordCard";

import "./ServerRoomLoginPage.css";

export default function ServerRoomLoginPage({ onAuthenticate, title = "Server Room" }) {
  const [forgetPassword, setForgetPassword] = useState(false);

  function onClickForgotPassword() {
    setForgetPassword(true);
  }

  function onClickLogin() {
    setForgetPassword(false);
  }

  function onResetPassword() {
    setForgetPassword(false);
  }

  return (
    <div className="server-room-login-page__container">
      {forgetPassword ? (
        <RecoveryPasswordCard
          onClickLogin={onClickLogin}
          onResetPassword={onResetPassword}
        />
      ) : (
        <LoginCard
          onClickForgotPassword={onClickForgotPassword}
          onAuthenticate={onAuthenticate}
          title={title}
        />
      )}
    </div>
  );
}
