import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
} from "@mui/material";
import { GRAY_BACKGROUND_COLOR } from "../../theme/style";
import BlackSelect from "../common/BlackSelect";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export function MultipleACSelect({
  selected,
  options,
  style = {},
  onChange = (d) => {},
}) {
  const getLabel = (s) => s.name;
  const convertToLabels = (selectedIds) => {
    const selected = options.filter((s) => selectedIds.includes(s.id));
    if (selected.length > 1) {
      return selected.map((s) => `${getLabel(s).substring(0, 4)}..`);
    }
    return selected.map((s) => getLabel(s));
  };

  const selectedIds = selected.map((s) => s.id);

  const handleChange = (e) => {
    const selectedIds = e.target.value;
    const selected = options.filter((s) => selectedIds.includes(s.id));

    onChange(selected);
  };

  return (
    <div
      style={{
        background: GRAY_BACKGROUND_COLOR,
        borderRadius: 32,
        padding: 10,
        ...style,
      }}
    >
      <FormControl sx={{ width: "100%" }}>
        <Select
          id="multiple-select"
          multiple
          value={selectedIds}
          onChange={handleChange}
          renderValue={(s) => convertToLabels(s).join(", ")}
          MenuProps={MenuProps}
          style={{
            height: 30,
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              <Checkbox checked={selectedIds.indexOf(option.id) > -1} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default function ACSelect({
  options,
  device,
  minWidth = 150,
  style = {},
  onDeviceSelected = (deviceId) => {},
}) {
  return (
    <BlackSelect
      variant="standard"
      style={{ backgroundColor: "black", ...style }}
      sx={{ bgcolor: "#535253", borderRadius: 16 }}
      value={device.id}
      options={options.map((d) => {
        return { value: d.id, label: d.name };
      })}
      onChange={(v) => onDeviceSelected(v)}
      disableUnderline={true}
      minWidth={minWidth}
      optional={true}
    ></BlackSelect>
  );
}
