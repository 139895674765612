import ACControl from "../ac/ACControl";
import ServerRoomStatus from "./ServerRoomStatus";

import "./RoomACSettings.css";
import RoomControl from "./RoomControl";
import { ServerRoomStore } from "../../stores/ServerRoomStore";

export default function RoomACSettings({
  device,
  onChange,
  devices,
  onEditRoomStatus,
  isEditServerRoom,
  onUpdate,
  onBackToMain,
}) {
  const serverRoom = ServerRoomStore.serverRoom;
  if (device != null) {
    return (
      <div className="room-ac-settings__container">
        <div className="room-ac-settings__server-room-status">
          <ServerRoomStatus
            serverRoom={serverRoom}
            style={{ width: "100%" }}
            onEditRoomStatus={onEditRoomStatus}
          />
        </div>
        <div className="room-ac-settings__ac-control">
          <ACControl
            device={device}
            devices={devices}
            onChange={onChange}
            onUpdate={onUpdate}
          />
        </div>
      </div>
    );
  }

  if (isEditServerRoom) {
    return (
      <div className="room-ac-settings__container">
        <div className="room-ac-settings__server-room-status">
          <ServerRoomStatus
            serverRoom={serverRoom}
            style={{ width: "100%" }}
            onEditRoomStatus={onEditRoomStatus}
          />
        </div>
        <div>
          <RoomControl
            serverRoom={serverRoom}
            onUpdate={onUpdate}
            onBackToMain={onBackToMain}
          />
        </div>
      </div>
    );
  }

  return (
    <ServerRoomStatus
      serverRoom={serverRoom}
      style={{ width: "80%" }}
      onEditRoomStatus={onEditRoomStatus}
    />
  );
}
