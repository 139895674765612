import { observer } from "mobx-react-lite";
import { InterlockStore, patchInterlock } from "../../../stores/InterlockStore";
import { patchSchedule } from "../../../stores/ScheduleStore";
import { ServerRoomStore } from "../../../stores/ServerRoomStore";
import {
  FLEX_ROW_CENTER_CENTER,
  GRAY_BACKGROUND_COLOR,
} from "../../../theme/style";
import ACSelect from "../../ac/ACSelect";
import DeleteButton from "../../common/DeleteButton";
import NumberedTextField from "../../common/NumberedTextField";
import SaveButton from "../../common/SaveButton";
import Text from "../../common/Text";
import InterlockACControlCard from "./InterlockACControlCard";

type InterlockACControlProps = {
  position: number;
  active: boolean;
  index: number;
};

export default observer(function InterlockACControl<InterlockACControlProps>({
  position,
  active,
  index,
}) {
  const action = InterlockStore.editingInterlock.action[index];
  const controlData = action.data;

  const onDeleteAction = (e) => {
    InterlockStore.setActiveActionIndex(0);
    InterlockStore.deleteAction(index);
    patchInterlock();

    e.stopPropagation();
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateAreas: `"a b c d e" "f f f f g"`,
        gridTemplateColumns: "1fr 3fr 3fr 3fr 0.5fr",
        marginLeft: 8,
        alignItems: "center",
        width: "100%",
        borderRadius: 32,
        padding: 8,
        backgroundColor: GRAY_BACKGROUND_COLOR,
      }}
      onClick={() => InterlockStore.setActiveActionIndex(index)}
    >
      <div style={{ gridArea: "a", alignSelf: "start", justifySelf: "center" }}>
        <Text style={{ textAlign: "center" }} fontSize="1rem ">
          Priority {position}
        </Text>
      </div>
      <div
        style={{
          gridArea: "b",
          paddingLeft: 8,
          paddingRight: 8,
          ...FLEX_ROW_CENTER_CENTER,
        }}
      >
        <ACSelect
          style={{ backgroundColor: GRAY_BACKGROUND_COLOR }}
          device={{ id: action.device_id }}
          options={ServerRoomStore.serverRoom.children}
          minWidth={120}
          onDeviceSelected={(id) => InterlockStore.setDeviceId(index, id)}
        />
      </div>
      <div
        style={{
          gridArea: "c",
          paddingLeft: 8,
          paddingRight: 8,
          ...FLEX_ROW_CENTER_CENTER,
          gap: 8,
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: "1.2rem",
            }}
          >
            Time
          </div>
          <div style={{ color: "#525353", fontSize: "1rem" }}>min</div>
        </div>
        <NumberedTextField
          value={action.time}
          onChange={(e) => InterlockStore.setActionTime(index, e)}
          size={1}
        />
      </div>
      <SaveButton
        style={{ gridArea: "e", justifySelf: "end" }}
        onClick={() => patchInterlock()}
      />
      {active && (
        <>
          <div style={{ gridArea: "f", width: "90%", justifySelf: "center" }}>
            <InterlockACControlCard
              key={action.device_id}
              index={index}
              device={{ id: action.device_id }}
              controlData={controlData}
            />
          </div>

          <DeleteButton
            style={{ gridArea: "g", justifySelf: "end" }}
            color="red"
            onClick={(e) => onDeleteAction(e)}
          />
        </>
      )}
    </div>
  );
});
