import React from "react";
import { SensorDataStruct } from "../sensor/SensorInterface";

interface VAMSensorBarProps extends SensorDataStruct {
  horizontal: boolean;
  title: string;
  description: string;
}

function VAMSensorBarItem({ icon, value, valueSubLabel, color }) {
  return (
    <div style={{ display: "flex", alignItems: "center", color }}>
      <div style={{ marginRight: 8 }}>
        <img src={icon}></img>
      </div>

      <div style={{ marginRight: 8, fontSize: "1.5rem" }}>{value}</div>

      <div>{valueSubLabel}</div>
    </div>
  );
}

export default function VAMSensorBar(props: VAMSensorBarProps) {
  return (
    <div>
      <div style={{marginBottom: 16}}>
        <div>{props.title}</div>
        <div>{props.description}</div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateRows: props.horizontal
            ? "auto auto"
            : "auto auto auto auto",
          gridTemplateColumns: props.horizontal ? "auto auto" : "auto",
          rowGap: 8,
          columnGap: 8,
        }}
      >
        <VAMSensorBarItem
          icon={"/img/sensor/temp-solid.svg"}
          value={props.temp || typeof props.temp === "number" ? props.temp.toFixed(0) + "℃" : "-"}
          valueSubLabel=""
          color="#5FE3A1"
        ></VAMSensorBarItem>

        <VAMSensorBarItem
          icon={"/img/sensor/aqi-solid.svg"}
          value={props.aqi || typeof props.aqi === "number" ? props.aqi.toFixed(0) : "-"}
          valueSubLabel="US AQI"
          color="#A3A1FB"
        ></VAMSensorBarItem>

        <VAMSensorBarItem
          icon={"/img/sensor/humidity-solid.svg"}
          value={props.humidity || typeof props.humidity === "number" ? props.humidity.toFixed(0) + "%" : "-"}
          valueSubLabel="Normal"
          color="#56D9FE"
        ></VAMSensorBarItem>

        <VAMSensorBarItem
          icon={"/img/sensor/co2-solid.svg"}
          value={props.co2 || typeof props.co2 === "number" ? props.co2.toFixed(0) : "-"}
          valueSubLabel="PPM"
          color="#FFA726"
        ></VAMSensorBarItem>
      </div>
    </div>
  );
}
