import { toLongDateString } from "../../../utils/datetime";
import Table from "../../common/Table";

function convertData(data) {
  return data.map((i) => {
    return {
      id: i.id,
      Interlock: i.name,
      Start: i.start_at ? i.start_at : "-",
      Status: i.status,
      Update: toLongDateString(i.updated_at),
    };
  });
}

export default function InterlockTable({ interlocks, onEdit }) {
  return (
    <Table
      headers={["Interlock", "Start", "Status", "Update"]}
      data={convertData(interlocks)}
      editable={true}
      onEdit={onEdit}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(50px, 1fr))",
      }}
    />
  );
}
