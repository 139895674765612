import { Tab, Tabs } from "@mui/material";
import TabPanel from "../common/TabPanel";
import InterlockTable from "./interlock/InterlockTable";
import ScheduleTable from "./schedule/ScheduleTable";

export default function ServerRoomDetails({
  tabFocusedValue,
  onTabFocused,
  room,
  onEditSchedule,
  onEditInterlock,
  schedules,
  interlocks,
}) {
  const handleChange = (_, newValue) => {
    onTabFocused(newValue);
  };

  const orientation =
    window.innerHeight < window.innerWidth ? "landscape" : "portrait";

  return (
    <div style={{ width: "100%" }}>
      <Tabs value={tabFocusedValue} onChange={handleChange}>
        <Tab label="Schedule" />
        <Tab label="Interlock" />
      </Tabs>
      <TabPanel
        value={tabFocusedValue}
        index={0}
        boxStyle={{
          height: orientation == "landscape" ? 420 : 800,
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <ScheduleTable onEdit={onEditSchedule} schedules={schedules} />
      </TabPanel>
      <TabPanel
        value={tabFocusedValue}
        index={1}
        boxStyle={{
          height: orientation == "landscape" ? 420 : 800,
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <InterlockTable onEdit={onEditInterlock} interlocks={interlocks} />
      </TabPanel>
    </div>
  );
}
