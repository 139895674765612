import { Button, Tab, Tabs } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  fetchSchedules,
  newSchedule,
  ScheduleStore,
} from "../../../stores/ScheduleStore";
import { ServerRoomStore } from "../../../stores/ServerRoomStore";
import {
  FLEX_COLUMN_START,
  FLEX_COLUMN_START_CENTER,
  FLEX_ROW_CENTER_CENTER,
} from "../../../theme/style";
import TabPanel from "../../common/TabPanel";
import Text from "../../common/Text";
import ScheduleForm from "./ScheduleForm";
import "./ScheduleFormPage.css";

type ScheduleFormPageProps = {
  onBackToDetail: () => {};
};

export default observer(function ScheduleFormPage<ScheduleFormPageProps>({
  onBackToDetail,
}) {
  const serverRoom = ServerRoomStore.serverRoom;
  useEffect(() => {
    fetchSchedules(serverRoom.id);
  }, [serverRoom.id]);

  const schedules = ScheduleStore.originalGroupedSchedules;

  const handleChange = (_, newValue) => {
    ScheduleStore.setActiveDeviceScheduleWrapperIndex(0);
    ScheduleStore.setActiveScheduleIndex(newValue);
    ScheduleStore.loadGroupedScheduleForEdit(newValue);
  };

  const addSchedule = () => {
    newSchedule();
  };

  const orientation =
    window.innerHeight < window.innerWidth ? "landscape" : "portrait";

  return (
    <div
      style={{
        ...FLEX_COLUMN_START,
        width: "100%",
        height: "100%",
      }}
    >
      <div className="schedule-form-page__schedule-title">
        <img
          src="/img/common/left-arrow-gray.svg"
          width={36}
          onClick={onBackToDetail}
        />
        <Text style={{ ...FLEX_ROW_CENTER_CENTER }}>
          {serverRoom.name} &gt; Schedule
        </Text>
      </div>
      <div className="schedule-form-page__container">
        <div
          style={{
            ...FLEX_COLUMN_START_CENTER,
            height: "100%",
            flexGrow: 0,
            flexBasis: "15%",
          }}
        >
          <Tabs
            value={ScheduleStore.activeScheduleIndex}
            onChange={handleChange}
            orientation="vertical"
          >
            {schedules.map((s, index) => {
              return <Tab value={index} key={index} label={s.name} />;
            })}
          </Tabs>
          <Button onClick={addSchedule}>
            <img src="/img/common/plus-white.svg" width={16} />
          </Button>
        </div>
        {schedules.map((s, index) => {
          return (
            <TabPanel
              value={ScheduleStore.activeScheduleIndex}
              index={index}
              key={index}
              boxStyle={{ height: orientation == "landscape" ? 600 : 1000 }}
            >
              <ScheduleForm key={s.id} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
});
