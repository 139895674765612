import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { DeviceStore } from "../../stores/DeviceStore"
import { SensorStore } from "../../stores/SensorStore"
import { BLACK_CARD, FLEX_ROW_CENTER } from "../../theme/style"
import { countDevices, deviceTypeId2controlEntity } from "../../utils/device"
import { baseScrollEvent } from "../../utils/scroll"
import HeaderCommon from "../common/HeaderCommon"
import SensorBar from "../sensor/SensorBar"
import { SensorDataStruct, SENSOR_EXAMPLE } from "../sensor/SensorInterface"
import VAMInner, { VAMInnerProps } from "./VAMInner"
import VAMItem from "./VAMItem"

const EXAMPLE_VAM = {
  name: 'Master-Bedroom',
  floorName: 'ABC',
  supplyFan: true,
  exhaustFan: false,
  mode: 'Positive',
  pressure: 'Normal',
  onClick: () => {},
  ...SENSOR_EXAMPLE
}

const VAM_ITEM_STYLE = {
  width: 550,
  flexBasis: 550,
  flexShrink: 0,
  marginRight: 12
}

/*
MODE MAP:
- Exhaust Fan
- Fresh Air
- Fresh Air + Dry
- Ventilation
*/
const VAM_MODE = [
  'Exhaust Fan',
  'Fresh Air',
  'Fresh Air + Dry',
  'Ventilation',
]

function formatVam(building, OA: SensorDataStruct, vam): VAMInnerProps {
  const controlData = vam[deviceTypeId2controlEntity(vam.device_type_id)];

  let fanLevel = 0;
  
  switch (controlData.device_fan_speed) {
    case '3': fanLevel = 1; break;
    case '4': fanLevel = 1; break;
    case '5': fanLevel = 2; break;
    case '6': fanLevel = 3; break;
    case '7': fanLevel = 3; break;
  }

  const RA: SensorDataStruct = {
    temp: vam.temp ?? OA.temp,
    humidity: vam.humidity ?? OA.humidity,
    aqi: vam.aqi ?? OA.aqi,
    co2: vam.co2 ?? OA.co2,
  }

  // console.log('VAM WOI', vam)

  let filterPref = 100 / (1 + vam.device_uptime_value / 1000 / 3600);
  let mode = VAM_MODE[controlData.device_vam_mode || 0];

  return {
    deviceId: vam.id,
    deviceTypeId: vam.device_type_id,
    name: vam.name,
    floorName: building.is_two_level ? 
      building.children.find(c => c.id == vam.room_id).name : 
      building.children.find(c => c.id == vam.floor_id).name,
    supplyFan: vam.status == 'on',
    exhaustFan: vam.status == 'on',
    supplyFanLevel: fanLevel,
    exhaustFanLevel: fanLevel,
    mode: mode,
    modeId: controlData.device_vam_mode || 0,
    pressure: filterPref >= 50 ? 'Normal' : 'Unhealthy',
    filterPerf: filterPref,
    OA,
    RA: RA,
    SA: RA,
  }
}

export default observer(function VAM() {
  const building = DeviceStore.building;
  const vams = DeviceStore.vams;
  const vamCount = countDevices(vams);
  const {
    weeklyEnergy,
    dailyTemperature,
    dailyHumidity,
    dailyPM2_5,
    dailyCO2,
    lastTemperature,
    lastHumidity,
    lastPM2_5,
    lastCO2,
  } = SensorStore.getSensorValue();

  const OA = {
    temp: lastTemperature,
    humidity: lastHumidity,
    aqi: lastPM2_5,
    co2: lastCO2,
  }

  const formattedVams = building ? vams.map(vam => formatVam(building, OA, vam)) : [];

  const [activeVam, setActiveVam] = useState<VAMInnerProps | null>(null);

  useEffect(() => {
    if (activeVam) {
      setActiveVam(formattedVams.find(c => c.deviceId == activeVam.deviceId));
    }
  }, [building])
  
  return (
    <div>
      <HeaderCommon title="VAM"></HeaderCommon>

      <div style={{...FLEX_ROW_CENTER, justifyContent: "space-between", padding: 24}}>
        <div style={{fontSize: '1.5rem'}}>คุณภาพอากาศภายใน</div>
        <div style={{...BLACK_CARD, padding: 0, ...FLEX_ROW_CENTER}}>
          <div style={{padding: 16, borderRight: "1px solid #707070"}}>
            VAM
          </div>

          <div style={{padding: 16, borderRight: "1px solid #707070"}}>
            ON: {vamCount.online}/{vamCount.total}
          </div>

          <div style={{padding: 16, borderRight: "1px solid #707070"}}>
            Error {vamCount.error || '-'}
          </div>

          <div style={{padding: 16}}>
            <img src="/img/common/checkmark-outlined.svg"></img>
          </div>
        </div>
      </div>

      <div style={{marginBottom: 12, padding: 24}}>
        <SensorBar
          mini={false}
          placeLabel="อาคาร"
          temp={lastTemperature}
          humidity={lastHumidity}
          aqi={lastPM2_5}
          co2={lastCO2}
        ></SensorBar>
      </div>

      <div style={{overflowX: 'scroll', paddingLeft: 24, ...FLEX_ROW_CENTER}} {...baseScrollEvent}>
        {formattedVams.map(vam =>
          <div style={VAM_ITEM_STYLE}>
            <VAMItem
              {...vam}
              key={vam.deviceId}
              active={activeVam && activeVam.deviceId == vam.deviceId}
              onClick={() => setActiveVam(vam)}
            ></VAMItem>
          </div>
        )}
      </div>

      {activeVam &&
        <div style={{padding: 24, marginTop: 32}}>
          <VAMInner
            {...activeVam}
          ></VAMInner>
        </div>
      }
    </div>
  )
})