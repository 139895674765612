
export interface SensorDataStruct {
  temp?: number;
  aqi?: number;
  humidity?: number;
  co2?: number;
}

export const SENSOR_EXAMPLE = {
  temp: 23,
  aqi: 20,
  humidity: 10,
  co2: 10,
}