import { default as MUITable } from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { default as MUITableCell } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function TableCell({ children }) {
  return (
    <MUITableCell style={{ borderBottom: "none" }}>{children}</MUITableCell>
  );
}

function HeaderCell({ children }) {
  return <MUITableCell>{children}</MUITableCell>;
}

function createRow(d, headers, style) {
  return (
    <TableRow key={d.id} style={{ ...style }}>
      {headers.map((header) => {
        return (
          <TableCell key={d[headers[0]] + "-" + header}>{d[header]}</TableCell>
        );
      })}
    </TableRow>
  );
}

export default function Table({
  headers,
  data = Array(),
  editable = false,
  onEdit = () => {},
  style = {},
}) {
  return (
    <TableContainer>
      {editable && (
        <div style={{ textAlign: "right" }}>
          <img src="/img/common/edit.svg" width={25} onClick={onEdit}></img>
        </div>
      )}
      <MUITable>
        <TableHead>
          <TableRow style={{ ...style }}>
            {headers.map((header) => (
              <HeaderCell key={header}>{header}</HeaderCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{data.map((d) => createRow(d, headers, style))}</TableBody>
      </MUITable>
    </TableContainer>
  );
}
