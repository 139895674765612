import { TextField } from "@mui/material"
import React, { useState } from "react"
import { BLACK_CARD, FLEX_ROW_CENTER_CENTER } from "../../theme/style"
import { api } from "../../utils/api";
import { globalLoadingFn } from "../../utils/loading";
import ServerRoomLoginPage from "../serverroom/login/ServerRoomLoginPage";

export function logout() {
  if (window.NativeLogout) {
    window.NativeLogout.postMessage("");
    return;
  }

  console.log("FLUTTER_LOGOUT")

  window.localStorage.removeItem('ACCESS_TOKEN');
  window.localStorage.removeItem('USER_ID');
  window.localStorage.removeItem('ACTIVE_BUILDING_ID');
  window.localStorage.removeItem('ACTIVE_ROOM_ID');

  window.location.href = '/';
}

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  async function performLogin() {
    let response = await globalLoadingFn(() => api.post("/login_legacy", {
      email, password
    }));

    window.localStorage.setItem('ACCESS_TOKEN', response.data.token);
    window.localStorage.setItem('USER_ID', response.data.user_id);
    window.localStorage.removeItem('ACTIVE_BUILDING_ID');
    window.localStorage.removeItem('ACTIVE_ROOM_ID');

    window.location.href = '/?page=app-settings'
  }

  async function performLoginPartial() {
    window.localStorage.removeItem('ACTIVE_BUILDING_ID');
    window.localStorage.removeItem('ACTIVE_ROOM_ID');

    window.location.href = '/?page=app-settings'
  }

  return (
    <ServerRoomLoginPage onAuthenticate={performLoginPartial} title={"Login"}></ServerRoomLoginPage>
  )

  return (
    <div style={{...FLEX_ROW_CENTER_CENTER, height: "100vh"}}>
      <div style={{...BLACK_CARD, width: 500}}>
        <div style={{...FLEX_ROW_CENTER_CENTER, fontSize: '2rem', marginBottom: 24}}>Login</div>

        <div style={{marginBottom: 16}}>
          <TextField
            id="email"
            label="Email"
            variant="standard"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            fullWidth
          />
        </div>

        <div style={{marginBottom: 48}}>
          <TextField
            id="password"
            label="Password"
            variant="standard"
            value={password}
            type="password"
            onChange={(event) => setPassword(event.target.value)}
            fullWidth
          />
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
          }}

          onClick={performLogin}
        >
          Login
        </div>
      </div>
    </div>
  )
}