const DAY = [
  "อาทิตย์",
  "จันทร์",
  "อังคาร",
  "พุธ",
  "พฤหัสบดี",
  "ศุกร์",
  "เสาร์",
]

const MONTH = [
  "มกราคม",
  "กุมภาพันธ์",
  "มีนาคม",
  "เมษายน",
  "พฤษภาคม",
  "มิถุนายน",
  "กรกฎาคม",
  "สิงหาคม",
  "กันยายน",
  "ตุลาคม",
  "พฤศจิกายน",
  "ธันวาคม",
]

export function formatTime(time) {
  let date = new Date(time);
  return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`
}

export function formatLongDate(date) {
  date = new Date(date);
  return `${DAY[date.getDay()]} ${date.getDate()} ${MONTH[date.getMonth()]} ${date.getFullYear() + 543}`
}

export function toLongDateString(dtString) {
  const dt = new Date(dtString);
  return (
    ("0" + dt.getDate()).slice(-2) +
    "-" +
    ("0" + (dt.getMonth() + 1)).slice(-2) +
    "-" +
    dt.getFullYear() +
    ", " +
    ("0" + dt.getHours()).slice(-2) +
    ":" +
    ("0" + dt.getMinutes()).slice(-2) +
    ":" +
    ("0" + dt.getSeconds()).slice(-2)
  );
}

export function time2date(time) {
  let date = new Date();
  let parts = time.split(":");

  date.setHours(parseInt(parts[0]))
  date.setMinutes(parseInt(parts[1]))

  if (parts.length > 2) {
    date.setSeconds(parseInt(parts[2]))
  }

  return date;
}