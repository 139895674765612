import React from "react";
import { FLEX_ROW_CENTER } from "../../theme/style";
import { SensorDataStruct } from "./SensorInterface";

export function SensorBarItem({
  icon,
  label,
  sublabel = "ภายในห้อง",
  value,
  valueSubLabel,
  color,
  grayscale = false,
  mini = false,
}) {
  const img_style = grayscale
    ? { marginRight: 8, filter: "grayscale(100%)" }
    : { marginRight: 8 };

  const text_color = grayscale ? "white" : color;

  return (
    <div
      style={{
        ...FLEX_ROW_CENTER,
        justifyContent: mini ? "flex-start" : "space-between",
        backgroundColor: mini ? "transparent" : "black",
        padding: mini ? 0 : 12,
        borderRadius: 12,
      }}
    >
      <div style={{ ...FLEX_ROW_CENTER }}>
        <img src={icon} style={img_style}></img>
        {!mini && (
          <div>
            <div>{label}</div>
            <div>{sublabel}</div>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: mini ? "row" : "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: text_color,
            fontSize: mini ? "1.5rem" : "1.75rem",
            lineHeight: !mini ? "1.75rem" : "",
            marginRight: mini ? 8 : 0,
          }}
        >
          {value}
        </div>
        <div style={{ color: mini ? text_color : "white" }}>
          {valueSubLabel}
        </div>
      </div>
    </div>
  );
}

export interface SensorBarProps extends SensorDataStruct {
  mini: boolean;
  placeLabel?: string;
  grayscale?: boolean;
}

export default function SensorBar({
  mini = false,
  placeLabel = "ห้อง",
  temp = 23,
  aqi = 20,
  humidity = 10,
  co2 = 10,
  grayscale = false,
}: SensorBarProps) {
  return (
    <div
      style={
        mini
          ? { display: "flex", justifyContent: "space-between" }
          : {
              display: "grid",
              gridTemplateColumns: "auto auto auto auto",
              columnGap: 12,
            }
      }
    >
      <SensorBarItem
        icon={"/img/sensor/temp-outlined-active.svg"}
        label="อุณหภูมิ"
        sublabel={"ภายใน" + placeLabel}
        value={temp || typeof temp === "number" ? temp.toFixed(0) + " ℃" : "-"}
        valueSubLabel=""
        color="#5FE3A1"
        mini={mini}
        grayscale={grayscale}
      ></SensorBarItem>

      <SensorBarItem
        icon={"/img/sensor/aqi-outlined-active.svg"}
        label="Air Quality"
        sublabel={"ภายใน" + placeLabel}
        value={aqi || typeof aqi === "number" ? aqi.toFixed(0) : "-"}
        valueSubLabel="US AQI"
        color="#A3A1FB"
        mini={mini}
        grayscale={grayscale}
      ></SensorBarItem>

      <SensorBarItem
        icon={"/img/sensor/humidity-outlined-active.svg"}
        label="Humidity"
        sublabel={"ภายใน" + placeLabel}
        value={
          humidity || typeof humidity === "number"
            ? humidity.toFixed(0) + "%"
            : "-"
        }
        valueSubLabel="Normal"
        color="#56D9FE"
        mini={mini}
        grayscale={grayscale}
      ></SensorBarItem>

      <SensorBarItem
        icon={"/img/sensor/co2-outlined-active.svg"}
        label="CO2"
        sublabel={"ภายใน" + placeLabel}
        value={co2 || typeof co2 === "number" ? co2.toFixed(0) : "-"}
        valueSubLabel="PPM"
        color="#FFA726"
        mini={mini}
        grayscale={grayscale}
      ></SensorBarItem>
    </div>
  );
}
