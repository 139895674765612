import ServerRoomListItem from "./ServerRoomListItem";

export default function ServerRoomList({ serverRooms, onSelectRoom }) {
  return (
    <div>
      {serverRooms.map((serverRoom) => (
        <div key={serverRoom.id}>
          <ServerRoomListItem
            serverRoom={serverRoom}
            onSelectRoom={() => onSelectRoom(serverRoom.id)}
          />
        </div>
      ))}
    </div>
  );
}
