import React, { useEffect, useState } from "react";
import { FLEX_COLUMN_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { api } from "../../utils/api";
import { deviceTypeId2controlEntity } from "../../utils/device";
import { globalLoadingFn } from "../../utils/loading";
import AirControlPanel from "./AirControlPanel";
import ControlButton from "./ControlButton";
import DoorCurtainControlPanel from "./DoorCurtainControlPanel";
import LightControlPanel from "./LightControlPanel";
import BreakerControlPanel from "./BreakerControlPanel";

interface ControlPanelProps {
  scopeType: string;
  scopeId: number;
  device: any;
  refreshData: () => any;
  showType: number;
  onControl?: (device, controlKey, controlValue) => any;
  overrideOnControl?: boolean;
}

export default function ControlPanel(props: ControlPanelProps) {
  const pass = {...props};

  pass.onControl = pass.onControl ?? ((device, controlKey, controlValue) => {});
  pass.overrideOnControl = pass.overrideOnControl ?? false;

  switch (props.device.device_type_id) {
    case 1:
      return <AirControlPanel {...pass}></AirControlPanel>

    case 3:
      return <LightControlPanel {...pass}></LightControlPanel>

    case 4:
    case 5:
      return <DoorCurtainControlPanel {...pass}></DoorCurtainControlPanel>

    case 6:
      return <BreakerControlPanel {...pass}></BreakerControlPanel>

    default:
      return <div></div>
  }
}