import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { DeviceStore } from "../../stores/DeviceStore";
import { RouterStore } from "../../stores/RouterStore";
import { BLACK_CARD, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { api } from "../../utils/api";
import { ACTIVE_BUILDING_ID, ACTIVE_ROOM_ID } from "../../utils/globalState";
import { globalLoadingFn, Loading } from "../../utils/loading";
import BlackSelect from "../common/BlackSelect";
import { logout } from "./LoginPage";

export default observer(function UpdatePinPage() {
  const [me, setMe] = useState<any>({});
  const [loading, setLoading] = useState(true);

  async function fetchMe() {
    let response = await globalLoadingFn(() => api.get("/me"));
    setMe(response.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchMe();
  }, []);

  if (loading) {
    return <Loading></Loading>
  }

  return (
    <div style={{ ...FLEX_ROW_CENTER_CENTER, height: "100%" }}>
      <div style={{ ...BLACK_CARD, width: 500 }}>
        <div style={{ textAlign: 'center', fontSize: '2rem', marginBottom: 16 }}>
          แก้ไข Pin
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            RouterStore.navigate('update-access-pin')
          }}
        >
          เปลี่ยน Access Pin
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            RouterStore.navigate('update-breaker-pin')
          }}
        >
          เปลี่ยน Breaker Pin
        </div>

        {/* <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            RouterStore.navigate('update-server-room-pin')
          }}
        >
          เปลี่ยน Server Room Pin
        </div> */}

        <hr />

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={async () => {
            if (window.confirm('ยืนยันการลบ Access Pin?')) {
              await globalLoadingFn(() => api.patch("/buildings/" + ACTIVE_BUILDING_ID, {
                access_pin: null,
              }));
            }
          }}
        >
          ลบ Access Pin
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={async () => {
            if (window.confirm('ยืนยันการลบ Breaker Pin?')) {
              await globalLoadingFn(() => api.patch("/buildings/" + ACTIVE_BUILDING_ID, {
                breaker_pin: null,
              }));
            }
          }}
        >
          ลบ Breaker Pin
        </div>

        {/* <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={async () => {
            if (window.confirm('ยืนยันการลบ Server Room Pin?')) {
              await globalLoadingFn(() => api.patch("/buildings/" + ACTIVE_BUILDING_ID, {
                server_room_pin: null,
              }));
            }
          }}
        >
          ลบ Server Room Pin
        </div> */}

        <hr />

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            RouterStore.navigate('account')
          }}
        >
          ย้อนกลับ
        </div>
      </div>
    </div>
  );
});
