import { GRAY_BACKGROUND_COLOR } from "../../theme/style";
import ControlButton from "../control/ControlButton";

export default function ACFanControlButton({
  scopeType,
  scopeId,
  device,
  refreshData,
  overrideOnControl,
  deviceFanSpeed,
  onFanSpeedChange,
  size,
  backgroundColor = GRAY_BACKGROUND_COLOR,
}) {
  const commonParameter = {
    scopeType,
    scopeId,
    device,
    refreshData,
    overrideOnControl,
  };

  function convertFanSpeed(speed) {
    switch (speed.toString()) {
      case "3":
        return "4";
      case "4":
        return "5";
      case "5":
        return "6";
      case "6":
        return "7";
      case "7":
        return "3";
      default:
        return "3";
    }
  }

  return (
    <ControlButton
      {...commonParameter}
      controlKey="device_fan_speed"
      controlValueFn={(_) => {
        return convertFanSpeed(deviceFanSpeed);
      }}
      image={"/img/control/air/mode-fan"}
      label={"Fan"}
      active={false}
      padding={16}
      size={size}
      width="100%"
      backgroundColor={backgroundColor}
      onControl={() => onFanSpeedChange(convertFanSpeed(deviceFanSpeed))}
    >
      <div style={{ marginLeft: 16 }}>
        <img
          src={`/img/control/air/fan-${parseInt(deviceFanSpeed) - 2 || 0}.svg`}
        ></img>
      </div>
    </ControlButton>
  );
}
