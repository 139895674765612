import React, { useState, useEffect, useCallback } from "react";
import { api } from "../../utils/api";
import { globalLoadingFn } from "../../utils/loading";
import { ACTIVE_BUILDING_ID } from "../../utils/globalState";
import ActiveInactiveText from "../common/ActiveInactiveText";
import { FLEX_ROW_CENTER_CENTER, FLEX_ROW_CENTER, BLACK_CARD, FLEX_COLUMN_CENTER_CENTER, FLEX_LR_CENTER } from "../../theme/style";
import DeviceListItem from "./DeviceListItem";
import DeviceList from "./DeviceList";
import AirControlPanel from "../control/AirControlPanel";
import DeviceTypeSelect from "./DeviceTypeSelect";
import ControlPanel from "../control/ControlPanel";
import DeviceCountPanel from "./DeviceCountPanel";
import { MapView } from "./MapView";
import DeviceListFloorSelect from "./DeviceListFloorSelect";
import TimeWithDate from "../common/TimeWithDate";
import { newScene, SceneStore, updateSceneData } from "../../stores/SceneStore";
import { observer } from "mobx-react-lite";
import SceneControl from "./SceneControl";
import Switch from "react-switch";
import { RouterStore } from "../../stores/RouterStore";
import { baseScrollEvent } from "../../utils/scroll";
import { DeviceStore, fetchBuilding } from "../../stores/DeviceStore";
import useInterval from "../../hooks/useInterval";
import { toggleDeviceOnOffAll } from "../../utils/device";

interface Props {
  floorId?: number;
}

const NAME_DEVICE_TYPE_ID = [
  ['light', 3],
  ['air', 1],
  ['curtain', 4],
  ['door', 5],
]

export default observer(function FloorDevices() {
  const scenes = SceneStore.scenes;
  const selectedScene = SceneStore.editingScene;
  // const [selectedScene, setSelectedScene] = useState<any>(null);

  const [building, setBuilding] = useState<any>()
  const [floorId, setFloorId] = useState<number | null>()
  const [floor, setFloor] = useState<any>({});
  const [rooms, setRooms] = useState<any[]>([]);
  const tabMode = RouterStore.controlPageTabMode;
  const setTabMode = useCallback((x) => RouterStore.setControlPageTabMode(x), []);
  const [activeDevice, setActiveDevice] = useState<any>(null);
  const [activeRoom, setActiveRoom] = useState<any>(null);
  const [activeDeviceType, setActiveDeviceType] = useState(1);

  const [selectRoomMode, setSelectRoomMode] = useState(false);

  const controlStyle = (tabMode == "device" ? 0 : (tabMode == "room" ? 1 : 2));

  // console.log("xxx");

  async function refreshBuilding() {
    // let buildingResponse = await globalLoadingFn(() =>
    //   api.get("/buildings/" + ACTIVE_BUILDING_ID)
    // );

    // setBuilding(buildingResponse.data);

    if (DeviceStore.building && DeviceStore.building.id) {
      setBuilding(DeviceStore.building);
    }
  }

  async function refreshData() {
    console.log("yyy");

    let floorResponse: any;
    
    if (!DeviceStore.buildingFirstTime) {
      floorResponse = floorId ? DeviceStore.building.children.find(x => x.id == floorId) : DeviceStore.building;
      floorResponse = {data: floorResponse}
    } else {
      return;
    }

    console.log(floorResponse.data)

    if (!floorId) {
      if (!floorResponse.data.is_two_level && floorResponse.data.children.length > 0) {
        setFloorId(floorResponse.data.children[0].id);
        return;
      }
    }

    setFloor(floorResponse.data);
    setRooms(floorResponse.data.children);

    // Refresh active device
    if (activeDevice) {
      setActiveDevice((await api.get("/devices/" + activeDevice.id)).data);
    }

    if (activeRoom) {
      setActiveRoom(floorResponse.data.children.find(x => x.id == activeRoom.id));
    }

    // console.log(floorResponse.data)
  }

  useEffect(() => {
    refreshBuilding();
  }, [DeviceStore.building])

  useEffect(() => {
    refreshData();
  }, [floorId]);

  useInterval(refreshData, 2000);

  useEffect(() => {
    setSelectRoomMode(false);
    if (tabMode == 'room') {
      if (floor.children.length > 0) {
        setActiveRoom(floor.children[0]);
      }
    } else {
      setActiveRoom(null);
    }
  }, [tabMode]);

  // console.log(floor);

  console.log(activeDeviceType)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >      
      <div style={{...FLEX_ROW_CENTER, justifyContent: "space-between", padding: 16}}>
        <div style={{marginBottom: 12}}>
          <TimeWithDate></TimeWithDate>
        </div>
        {building && !building.is_two_level &&
          <DeviceListFloorSelect 
            data={building} 
            selectedId={floorId} 
            onChange={(x) => setFloorId(x)}
            style={{
              padding: 16,
              paddingLeft: 28,
              paddingRight: 28,
              borderRadius: 100,
            }}
          ></DeviceListFloorSelect>
        }
      </div>
      
      
      <div>
        <DeviceCountPanel data={floor}></DeviceCountPanel>
      </div>

      <div style={{marginTop: 8, marginBottom: 8}}>
        <MapView 
          data={floor}
          onChangeDevice={async (device) => {
            setActiveDevice(device)

            if (device.device_type_id == 3) {
              if (device.status == 'off') {
                toggleDeviceOnOffAll('device', device.id, true);
              } else {
                toggleDeviceOnOffAll('device', device.id, false);
              }
              await fetchBuilding({ alwaysLoading: true });
              await globalLoadingFn(() => refreshData());
            }
          }}
          activeDeviceId={activeDevice?.id}
          activeRoomId={tabMode == "device" ? 0 : activeRoom?.id}
        ></MapView>
      </div>

      <div style={{ flexGrow: 1 }}>
        <div style={{ ...FLEX_ROW_CENTER_CENTER, marginBottom: 16 }}></div>

        <div style={{ ...FLEX_ROW_CENTER_CENTER, marginBottom: 16 }}>
          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <ActiveInactiveText
              text="Scene"
              active={tabMode == "scene"}
              size={2}
              onClick={() => {
                setTabMode("scene");
              }}
            />
          </div>

          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <ActiveInactiveText
              text="ห้อง"
              active={tabMode == "room"}
              size={2}
              onClick={() => {
                setTabMode("room");
              }}
            />
          </div>

          <div style={{ paddingLeft: 16, paddingRight: 16 }}>
            <ActiveInactiveText
              text="อุปกรณ์"
              active={tabMode == "device"}
              size={2}
              onClick={() => {
                setTabMode("device");
              }}
            />
          </div>
        </div>

        {tabMode == "scene" && <>
          <div style={{ display: "grid", gridTemplateColumns: "1fr auto", alignItems: "start", padding: 24 }}>
            <div style={{ flexGrow: 1, marginRight: 24, ...FLEX_COLUMN_CENTER_CENTER }}>
              {scenes.map(scene => (
                <div className="text-overflow-ellipsis">
                  <ActiveInactiveText
                    text={scene.name}
                    active={selectedScene && selectedScene.id == scene.id}
                    onClick={() => SceneStore.loadSceneForEdit(scene)}
                  />
                </div>
              ))}
            </div>

            {(selectedScene || true) && 
              <div style={{ ...BLACK_CARD, backgroundColor: "rgba(0,0,0,0.4)" }}>
                <div style={{display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", columnGap: 8}}>
                  {NAME_DEVICE_TYPE_ID.map(entity => (
                    <div style={{overflowY: "scroll", overflowX: "hidden", maxHeight: 500}} {...baseScrollEvent}>
                      {
                        selectedScene && [activeRoom ?? SceneStore.getSceneForDeviceList()].map((room) => (
                          <DeviceList
                            flexDirection="column"
                            roomId={room.id}
                            key={room.id}
                            roomData={selectedScene && activeRoom ? SceneStore.patchDevice(room) : room}
                            deviceType={entity[1] as number}
                            activeDevice={activeDevice}
                            activeDeviceType={selectRoomMode ? activeDeviceType : null}
                            setActiveDevice={(device: any) => {
                              setActiveDevice(device);
                            }}
                          ></DeviceList>
                        ))
                      }
                    </div>
                  ))}
                </div>
              </div>
            }

            <div style={{ flexBasis: 100, ...FLEX_ROW_CENTER_CENTER, marginTop: 24 }}>
              <div 
                style={{ width: 50, height: 50, textAlign: "center", fontSize: '2rem', backgroundColor: "black", borderRadius: "50%" }}
                onClick={() => {
                  newScene();
                }}
              >
                +
              </div>
            </div>

            <div style={{ ...FLEX_ROW_CENTER, alignItems: "flex-start", marginTop: 24 }}>
              <div style={{marginRight: 24, display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                <div style={{ ...BLACK_CARD }}>
                  <div>
                    <SceneControl></SceneControl>
                  </div>

                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <DeviceListFloorSelect data={{
                      children: [
                        {
                          id: 0,
                          name: "อุปกรณ์ใน Scene",
                        },
                        ...floor.children
                      ]}} 
                      selectedId={activeRoom?.id ?? 0} 
                      optional={true}
                      onChange={(x) => setActiveRoom(floor.children.find(c => c.id == x) ?? null)}
                    ></DeviceListFloorSelect>
                  </div>
                </div>
              </div>

              {activeDevice && selectedScene &&
                <div style={{ flexGrow: 1 }}>
                  {/* Switch on/off for each device */}
                  <div style={{ ...BLACK_CARD, marginBottom: 16 }}>
                    <div style={{ ...FLEX_LR_CENTER }} >
                      <div>ควบคุม</div>
                      <div>
                        <Switch
                          onChange={async (checked) => {
                            SceneStore.setControlActive(activeDevice, checked);
                            updateSceneData();
                          }}
                          checked={SceneStore.getControlData(activeDevice).active}
                          checkedIcon={false}
                          uncheckedIcon={false}
                          onColor="#FFA726"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Control section */}
                  {SceneStore.getControlData(activeDevice).active &&
                    <ControlPanel
                      scopeId={activeDevice.id}
                      scopeType="device"
                      device={SceneStore.patchDevice(activeDevice)}
                      refreshData={() => {}}
                      showType={controlStyle}
                      overrideOnControl={true}
                      onControl={(device, controlKey, controlValue) => {
                        SceneStore.setControlData(device, controlKey, controlValue);
                        updateSceneData();
                      }}
                    ></ControlPanel>
                  }
                </div>
              }
            </div>

          </div>

        </>}

        {tabMode != "scene" &&
          <div style={{ ...FLEX_ROW_CENTER, alignItems: "flex-start", overflowX: "scroll" }} {...baseScrollEvent}>
            {rooms.map((room) => (
              <div style={{ flexBasis: tabMode == 'device' ? 200 : undefined, flexShrink: 0, marginRight: tabMode == 'device' ? 0 : 12 }} key={room.id}>
                <div style={{ padding: 12, paddingLeft: 16, textAlign: tabMode == 'device' ? 'left' : 'center' }}>
                  <ActiveInactiveText
                    text={room.name}
                    active={
                      (tabMode != "device" || selectRoomMode) &&
                      activeRoom &&
                      activeRoom.id == room.id
                    }
                    size={1.5}
                    onClick={() => {
                      setActiveRoom(room);

                      if (tabMode == 'device') {
                        const targetDevice = room.children.find(x => x.device_type_id == activeDeviceType);

                        if (targetDevice) {
                          setSelectRoomMode(true);
                          setActiveDevice(targetDevice);
                        }
                      }
                    }}
                    activeStyle={tabMode == 'device' ? "textActiveYellowNoBorder" : "textActivePurple"}
                    inactiveStyle="text"
                  />
                </div>

                {tabMode == "device" &&
                  <div style={{overflowY: "scroll", overflowX: "hidden", maxHeight: 480}} {...baseScrollEvent}>
                    <DeviceList
                      flexDirection="column"
                      roomId={room.id}
                      roomData={room}
                      deviceType={activeDeviceType}
                      activeDevice={activeDevice}
                      activeDeviceType={null}
                      setActiveDevice={(device: any) => {
                        setSelectRoomMode(false);
                        setActiveDevice(device);
                        setActiveRoom(null);
                      }}
                      activeAll={selectRoomMode && activeRoom && activeRoom.id == room.id}
                    ></DeviceList>
                  </div>
                }

              </div>
            ))}
          </div>
        }
      
        {tabMode == "room" && activeRoom &&
          <div style={{
            backgroundColor: "rgba(0,0,0,0.4)",
            borderRadius: 16,
            padding: 16,
            paddingLeft: 32,
            margin: 16,
          }}>
            {NAME_DEVICE_TYPE_ID.map(entity => (<>
              <div style={{...FLEX_ROW_CENTER, height: 85}}>

                <div style={{marginRight: 16}} onClick={() => {
                  const targetDevice = activeRoom.children.find(x => x.device_type_id == entity[1]);

                  if (targetDevice) {
                    setSelectRoomMode(true);
                    setActiveDeviceType(entity[1] as number);
                    setActiveDevice(targetDevice);
                  }
                }}>
                  <img src={`/img/control/${entity[0]}-outlined-active.svg`}></img>
                </div>

                <div style={{overflowX: "scroll", width: "100%"}} {...baseScrollEvent}>
                  <DeviceList
                    flexDirection="row"
                    roomId={activeRoom.id}
                    roomData={activeRoom}
                    deviceType={entity[1] as number}
                    activeDevice={activeDevice}
                    activeDeviceType={selectRoomMode ? activeDeviceType : null}
                    setActiveDevice={(device: any) => {
                      setActiveDevice(device);
                      setSelectRoomMode(false);
                    }}
                    activeAll={selectRoomMode && activeDeviceType == entity[1]}
                  ></DeviceList>
                </div>
                
              </div>
            </>))}
          </div>
        }
      </div>

      {tabMode != "scene" &&
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: tabMode == 'device' ? "space-between" : "center",
              alignItems: "flex-end",
              padding: 24,
            }}
          >
            {tabMode == 'device' &&
              <div style={{flexBasis: 300}}>
                <DeviceTypeSelect
                  activeDeviceType={activeDeviceType}
                  setActiveDeviceType={(type) => {
                    setActiveDeviceType(type);
                    setActiveDevice(null);
                  }}
                  onBreakerSelect={() => {
                    setActiveDevice(DeviceStore.breaker);
                  }}
                ></DeviceTypeSelect>
              </div>
            }
            {activeDevice && (
              <ControlPanel
                scopeId={selectRoomMode ? activeRoom.id : activeDevice.id}
                scopeType={selectRoomMode ? "room" : "device"}
                device={activeDevice}
                refreshData={refreshData}
                showType={controlStyle}
              ></ControlPanel>
            )}
          </div>
        </div>
      }
    </div>
  );
})
