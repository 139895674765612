import { useEffect, useState } from "react";
import ServerRoomHome from "./ServerRoomHome";
import ServerRoomLoginPage from "./login/ServerRoomLoginPage";
import { DeviceStore, fetchBuilding } from "../../stores/DeviceStore";
import ServerRoomStatusPage from "./ServerRoomStatusPage";
import ServerRoomHeader from "./ServerRoomHeader";
import { ServerRoomStore } from "../../stores/ServerRoomStore";
import { observer } from "mobx-react-lite";

export default observer(function ServerRoomPage() {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [toggledRender, setToggledRender] = useState<boolean>(true);

  const serverRooms = DeviceStore.serverRooms;

  useEffect(() => {
    async function fetch() {
      fetchBuilding();
    }

    fetch();
  }, []);

  const authenticate = () => {
    setIsAuthenticated(true);
  };

  const roomSelectHandler = (serverRoomId) => {
    ServerRoomStore.setServerRoom(
      serverRooms.filter((s) => s.id == serverRoomId)[0]
    );
  };

  const navigateToHome = () => {
    ServerRoomStore.setServerRoom(null);
  };

  const clearState = () => {
    setIsAuthenticated(window.localStorage.getItem("ACCESS_TOKEN") != null);
    ServerRoomStore.setServerRoom(null);
  };

  const toggleRender = () => {
    setToggledRender((prev) => !prev);
  };

  const activeServerRoom = ServerRoomStore.serverRoom;

  return (
    <>
      {!isAuthenticated && (
        <div style={{ height: 768 }}>
          <ServerRoomHeader title="Server Room" />
          <ServerRoomLoginPage onAuthenticate={authenticate} />
        </div>
      )}
      {isAuthenticated && !activeServerRoom && (
        <div>
          <ServerRoomHeader title="Server Room" beforeNavigate={clearState} />
          <ServerRoomHome
            serverRooms={serverRooms}
            onSelectRoom={roomSelectHandler}
          />
        </div>
      )}
      {isAuthenticated && activeServerRoom && (
        <ServerRoomStatusPage
          onUpdate={toggleRender}
          clearState={clearState}
          navigateToHome={navigateToHome}
        />
      )}
    </>
  );
});
