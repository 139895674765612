import { FLEX_ROW_CENTER } from "../../theme/style";

export default function DetailButton({
  onClick = (e) => {},
  style = {},
  className = "",
}) {
  return (
    <div
      style={{
        ...FLEX_ROW_CENTER,
        gap: 4,
        ...style,
        textDecoration: "underline",
      }}
      className={className}
      onClick={onClick}
    >
      รายละเอียด
      <img src="/img/common/right-arrow-gray.svg" width={12} />
    </div>
  );
}
