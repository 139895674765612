import { useState } from "react";
import { Rnd } from "react-rnd";
import { FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../utils/api";

export default function ServerRoomPlan({ devices: initialDevices, picture }) {
  const [devices, setDevices] = useState(initialDevices);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [planDimension, setPlanDimension] = useState({ width: 0, height: 0 });

  const updatePosition = async (index, position) => {
    const device = devices[index];
    await globalLoadingFn(() =>
      api.patch(`/places/positions`, [
        {
          type: "device",
          id: device.id,
          position: position,
        },
      ])
    );
  };

  const dragHandler = async (index, left, top) => {
    if (isDragging && !isResizing) {
      setDevices((prev) => {
        const updatedDeviceState = [...prev];
        updatedDeviceState[index].position.left = left / planDimension.width;
        updatedDeviceState[index].position.top = top / planDimension.height;
        return updatedDeviceState;
      });

      updatePosition(index, {
        ...devices[index].position,
        left: left / planDimension.width,
        top: top / planDimension.height,
      });
    }
    setIsDragging(false);
    setIsResizing(false);
  };

  const resizeHandler = (index, s) => {
    setIsResizing(true);
    setDevices((prev) => {
      const newPositions = [...prev];
      newPositions[index].position.left = s.x / planDimension.width;
      newPositions[index].position.top = s.y / planDimension.height;
      newPositions[index].position.size = s.size;

      return newPositions;
    });
  };

  const onRoomPlanLoad = (img) => {
    setPlanDimension({
      width: img.target.width,
      height: img.target.height,
    });
  };

  return (
    <div style={{ ...FLEX_ROW_CENTER_CENTER, width: "100%", height: "100%" }}>
      <div
        style={{
          width: "80%",
          height: "100%",
          pointerEvents: "none",
        }}
      >
        {devices.map((device, index) => {
          return (
            <Rnd
              key={device.id}
              size={{
                width: device.position.size,
                height: device.position.size,
              }}
              position={{
                x: device.position.left * planDimension.width,
                y: device.position.top * planDimension.height,
              }}
              onDragStart={() => {
                if (!isResizing) {
                  setIsDragging(true);
                }
              }}
              onDragStop={(e, d) => {
                dragHandler(index, d.x, d.y);
              }}
              onResize={(e, direction, ref, delta, position) => {
                resizeHandler(index, {
                  size: ref.offsetWidth,
                  ...position,
                });
              }}
              onResizeStop={() => {
                updatePosition(index, devices[index].position);
              }}
              bounds=".serverroom-plan-image"
              minWidth={30}
            >
              <div
                style={{
                  margin: 0,
                }}
              >
                <img
                  style={{
                    width: "100%",
                    pointerEvents: "none",
                    justifySelf: "center",
                  }}
                  src={devices[index].status == 'on' ? "/img/control/air-solid.svg" : "/img/control/air-solid-grey.svg"}
                  className="App-logo"
                />
              </div>
            </Rnd>
          );
        })}

        <img
          className="serverroom-plan-image"
          style={{ width: "100%" }}
          src={picture || "/img/mock/serverroom-plan.jpg"}
          onLoad={onRoomPlanLoad}
        />
      </div>
    </div>
  );
}
