import {
  BLACK_CARD,
  FLEX_COLUMN,
  FLEX_COLUMN_START,
  FLEX_ROW_END,
  FLEX_ROW_START,
} from "../../theme/style";
import { fetchBuilding } from "../../stores/DeviceStore";
import Text from "../common/Text";

import { useState } from "react";
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../utils/api";
import NumberedTextField from "../common/NumberedTextField";

function ControlCard({ data, onChange, minValue, maxValue }) {
  const { title, img, unit } = data;
  const key = title.replace(" ", "").toLowerCase();

  const [startValue, setStartValue] = useState(minValue);
  const [endValue, setEndValue] = useState(maxValue);

  function startValueChangedHandler(value) {
    // if (value > endValue) {
    //   return;
    // }
    setStartValue(value);
    onChange(0, value);
  }

  function endValueChangedHandler(value) {
    // if (value < startValue) {
    //   return;
    // }
    setEndValue(value);
    onChange(1, value);
  }

  return (
    <div style={{ display: "grid", gridTemplateColumns: "8fr 3fr 2fr 3fr" }}>
      <div style={{ ...FLEX_ROW_START }}>
        <img src={img} style={{ height: "40px" }} />
        <div
          style={{
            ...FLEX_COLUMN,
            justifyContent: "center",
            height: "50%",
            marginLeft: 20,
          }}
        >
          {key == "co2" && (
            <>
              <Text>
                <div style={{ display: "flex", fontSize: "1.5rem" }}>
                  CO
                  <div
                    style={{
                      fontSize: "0.6rem",
                      lineHeight: 5,
                    }}
                  >
                    2
                  </div>
                </div>
              </Text>
              <Text fontSize="1rem">
                <div style={{ lineHeight: 0, color: "gray" }}>{unit}</div>
              </Text>
            </>
          )}
          {key != "co2" && (
            <>
              <Text fontSize="1.5rem">{title}</Text>
              <Text fontSize="1rem" color="gray">
                {unit}
              </Text>
            </>
          )}
        </div>
      </div>
      {key == "co2" ? (
        <Text
          fontSize="1rem"
          style={{ alignSelf: "center", textAlign: "center" }}
        >
          Less than
        </Text>
      ) : (
        <NumberedTextField
          value={startValue}
          onChange={startValueChangedHandler}
          size={1}
        />
      )}
      {key == "co2" ? (
        <div />
      ) : (
        <Text
          style={{
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          to
        </Text>
      )}
      <NumberedTextField
        value={endValue}
        onChange={endValueChangedHandler}
        size={1}
      />
    </div>
  );
}

const controlSettings = [
  {
    img: "/img/sensor/temp-outlined-active.svg",
    title: "Temperature",
    unit: "Degree Celsius",
    minKey: "tempMin",
    maxKey: "tempMax",
  },
  {
    img: "/img/sensor/aqi-outlined-active.svg",
    title: "Air Quality",
    unit: "US AQI",
    minKey: "aqiMin",
    maxKey: "aqiMax",
  },
  {
    img: "/img/sensor/humidity-outlined-active.svg",
    title: "Humidity",
    unit: "Rn",
    minKey: "humidityMin",
    maxKey: "humidityMax",
  },
  {
    img: "/img/sensor/co2-outlined-active.svg",
    title: "CO2",
    unit: "PPM",
    minKey: "",
    maxKey: "co2Max",
  },
];

export default function RoomControl({ serverRoom, onUpdate, onBackToMain }) {
  const sensorStandard = serverRoom.sensor_standard;
  const [temp, setTemp] = useState([
    sensorStandard.tempMin,
    sensorStandard.tempMax,
  ]);
  const [aqi, setAqi] = useState([
    sensorStandard.aqiMin,
    sensorStandard.aqiMax,
  ]);
  const [humidity, setHumidity] = useState([
    sensorStandard.humidityMin,
    sensorStandard.humidityMax,
  ]);
  const [co2, setCO2] = useState([-1, sensorStandard.co2Max]);

  const updateValueFunctions = {
    Temperature: updateTemp,
    "Air Quality": updateAqi,
    Humidity: updateHumidity,
    CO2: updateCO2,
  };

  function updateTemp(index, value) {
    setTemp((prevValue) => {
      const newValue = [...prevValue];
      newValue[index] = value;
      return newValue;
    });
  }

  function updateAqi(index, value) {
    setAqi((prevValue) => {
      const newValue = [...prevValue];
      newValue[index] = value;
      return newValue;
    });
  }

  function updateHumidity(index, value) {
    setHumidity((prevValue) => {
      const newValue = [...prevValue];
      newValue[index] = value;
      return newValue;
    });
  }

  function updateCO2(index, value) {
    setCO2((prevValue) => {
      const newValue = [...prevValue];
      newValue[index] = value;
      return newValue;
    });
  }

  function change(title) {
    return updateValueFunctions[title];
  }

  async function saveHandler() {
    const roomId = serverRoom.id;
    await globalLoadingFn(() =>
      api.put(`https://api.airin1.com/api/rooms/${roomId}/sensor_standard`, {
        tempMin: temp[0],
        tempMax: temp[1],
        aqiMin: aqi[0],
        aqiMax: aqi[1],
        humidityMin: humidity[0],
        humidityMax: humidity[1],
        co2Max: co2[1],
      })
    );

    await fetchBuilding();

    onUpdate();
  }

  return (
    <div
      style={{
        ...BLACK_CARD,
        ...FLEX_COLUMN_START,
        height: "100%",
        paddingTop: "5%",
        paddingLeft: "10%",
        paddingRight: "10%",
        gap: 36,
      }}
    >
      <div style={{ ...FLEX_ROW_END, gap: "8px" }}>
        <img
          src="/img/common/reload.svg"
          width="16px"
          onClick={fetchBuilding}
        ></img>
        <img
          src="/img/common/save.svg"
          width="16px"
          onClick={saveHandler}
        ></img>
      </div>

      <div
        style={{
          ...FLEX_COLUMN_START,
          gap: "48px",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <div className="" style={{ ...FLEX_ROW_START, width: "100%", gap: 8 }}>
          <img
            src="/img/common/left-arrow-gray.svg"
            width="32px"
            onClick={onBackToMain}
          />
          <Text>ค่ามาตรฐาน</Text>
        </div>

        {controlSettings.map((controlSetting) => (
          <ControlCard
            key={controlSetting.title}
            data={controlSetting}
            onChange={change(controlSetting.title)}
            minValue={serverRoom.sensor_standard[controlSetting.minKey]}
            maxValue={serverRoom.sensor_standard[controlSetting.maxKey]}
          />
        ))}
      </div>
    </div>
  );
}
