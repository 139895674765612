import React, { useState } from "react";
import useInterval from "../../hooks/useInterval";
import { RouterStore } from "../../stores/RouterStore";
import { FLEX_ROW_CENTER } from "../../theme/style";
import { formatTime } from "../../utils/datetime";
import ActiveInactiveText from "./ActiveInactiveText";

interface Props {
  showTime?: boolean;
  showHome?: boolean;
  compact?: boolean;
  title?: string;
  children?: any;
  onHomeClick?: () => void;
  beforeNavigate?: () => void;
}

export default function HeaderCommon({
  compact = false,
  title = "",
  showTime = true,
  showHome = true,
  children,
  onHomeClick,
  beforeNavigate = () => {},
}: Props) {
  const [time, setTime] = useState(Date.now());

  useInterval(() => setTime(Date.now()), 1000);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: 24,
        fontSize: "2rem",
        alignItems: "flex-start",
      }}
    >
      {showTime && (
        <div style={{ ...FLEX_ROW_CENTER, flexBasis: 200 }}>
          {showHome && (
            <div
              style={{ marginRight: 16 }}
              onClick={() => {
                beforeNavigate();
                if (onHomeClick) {
                  onHomeClick();
                } else {
                  RouterStore.home();
                }
              }}
            >
              <img src="/img/common/home-btn-outlined.svg"></img>
            </div>
          )}

          <div>{formatTime(new Date())}</div>
        </div>
      )}

      {children || (
        <div
          style={{
            paddingTop: compact ? 0 : 64,
            paddingBottom: compact ? 0 : 24,
          }}
        >
          <div>
            <ActiveInactiveText text={title} size={3}></ActiveInactiveText>
          </div>
        </div>
      )}

      <div style={{ ...FLEX_ROW_CENTER, flexBasis: 200 }}>
        {/* <div style={{marginRight: 16}}>
          <img src="/img/common/alarm-btn-solid.svg"></img>
        </div>

        <div style={{}}>
          <img src="/img/common/tel-btn-solid.svg"></img>
        </div> */}
      </div>
    </div>
  );
}
