import { FLEX_ROW_CENTER, GRAY_BACKGROUND_COLOR } from "../../theme/style";
import Text from "../common/Text";
import ControlButton from "../control/ControlButton";

export default function ModeChangeControlButton({
  scopeType,
  scopeId,
  device,
  refreshData,
  overrideOnControl,
  deviceStatus,
  deviceMode,
  onModeChange,
  onStatusChange,
  size,
  style = {},
}) {
  const commonParameter = {
    scopeType,
    scopeId,
    device,
    refreshData,
    overrideOnControl,
  };

  const off = !parseInt(deviceStatus);

  return (
    <div style={{ ...FLEX_ROW_CENTER, ...style }}>
      <div
        className={off ? "common-inactive" : ""}
        style={{ ...FLEX_ROW_CENTER, flexGrow: 1, gap: 10 }}
      >
        <Text fontSize={`${1 + size * 0.5}rem`}>Mode</Text>

        <ControlButton
          {...commonParameter}
          controlKey="device_mode"
          controlValueFn={(_) => "1"}
          image={"/img/control/air/mode-cool"}
          active={deviceStatus == 1 && deviceMode == "1"}
          size={size}
          backgroundColor={GRAY_BACKGROUND_COLOR}
          onControl={() => onModeChange("1")}
        ></ControlButton>

        <ControlButton
          {...commonParameter}
          controlKey="device_mode"
          controlValueFn={(_) => "0"}
          image={"/img/control/air/mode-fan"}
          active={deviceStatus == 1 && deviceMode == "0"}
          size={size}
          backgroundColor={GRAY_BACKGROUND_COLOR}
          onControl={() => onModeChange("0")}
        ></ControlButton>

        <ControlButton
          {...commonParameter}
          controlKey="device_mode"
          controlValueFn={(_) => "2"}
          image={"/img/control/air/mode-dry"}
          active={deviceStatus == 1 && deviceMode == "2"}
          size={size}
          backgroundColor={GRAY_BACKGROUND_COLOR}
          onControl={() => onModeChange("2")}
        ></ControlButton>
      </div>

      <div style={{ paddingRight: 12 }}>
        <ControlButton
          {...commonParameter}
          controlKey="device_status"
          controlValueFn={(_) => (deviceStatus == 1 ? 0 : 1)}
          image={`/img/control/air/power`}
          noBorder
          padding={2}
          active={deviceStatus == 1}
          size={size}
          onControl={() => onStatusChange(deviceStatus == 1 ? 0 : 1)}
        ></ControlButton>
      </div>
    </div>
  );
}
