import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { InterlockStore } from "../../stores/InterlockStore";
import { fetchSchedules, ScheduleStore } from "../../stores/ScheduleStore";
import { ServerRoomStore } from "../../stores/ServerRoomStore";
import ACStatusList from "../ac/ACStatusList";
import InterlockFormPage from "./interlock/InterlockFormPage";
import ScheduleFormPage from "./schedule/ScheduleFormPage";
import ServerRoomDetails from "./ServerRoomDetails";
import ServerRoomHeader from "./ServerRoomHeader";
import ServerRoomPlan from "./ServerRoomPlan";
import "./ServerRoomStatusDetailsPage.css";

type ServerRoomStatusDetailsPageProps = {
  navigateToServerRoomStatus: () => {};
};

export default observer(function ServerRoomStatusDetailsPage<
  ServerRoomStatusDetailsPageProps
>({ navigateToServerRoomStatus }) {
  const serverRoom = ServerRoomStore.serverRoom;
  const devices = serverRoom.children;
  const [scheduleFocused, setScheduleFocused] = useState<boolean>(false);
  const [interlockFocused, setInterlockFocused] = useState<boolean>(false);
  const [detailTabFocusedValue, setDetailTabFocusedValue] = useState<number>(0);

  const scheduleOrInterlockfocused = scheduleFocused || interlockFocused;

  const editScheduleHandler = () => {
    setScheduleFocused(true);
  };

  const editInterlockHandler = () => {
    setInterlockFocused(true);
  };

  const onBackToDetailFromScheduleForm = () => {
    setScheduleFocused(false);
    setDetailTabFocusedValue(0);
  };

  const onBackToDetailFromInterlockForm = () => {
    setInterlockFocused(false);
    setDetailTabFocusedValue(1);
  };

  const onDetailTabFocusedChange = (v) => {
    setDetailTabFocusedValue(v);
  };

  const orientation =
    window.innerHeight < window.innerWidth ? "landscape" : "portrait";
  const onClickBack =
    orientation == "portrait"
      ? navigateToServerRoomStatus
      : scheduleFocused
      ? onBackToDetailFromScheduleForm
      : interlockFocused
      ? onBackToDetailFromInterlockForm
      : navigateToServerRoomStatus;

  useEffect(() => {
    fetchSchedules(ServerRoomStore.serverRoom.id);
  }, [ServerRoomStore.serverRoom.id]);

  const title =
    orientation == "portrait"
      ? "Server Room > Details"
      : scheduleFocused
      ? "Server Room > Schedule"
      : interlockFocused
      ? "Server Room > Interlock"
      : "Server Room > Details";

  if (scheduleFocused) {
    return (
      <div style={{ height: "100%" }}>
        <ServerRoomHeader
          title={title}
          onClickBack={onClickBack}
          onHomeClick={onClickBack}
        />
        <div className="server-room-status-details__form-container">
          <div className={`server-room-status-details__img-container`}>
            <ServerRoomPlan devices={devices} picture={serverRoom.picture} />
          </div>
          <div className="server-room-status-details__form">
            <ScheduleFormPage onBackToDetail={onBackToDetailFromScheduleForm} />
          </div>
        </div>
      </div>
    );
  } else if (interlockFocused) {
    return (
      <div style={{ height: "100%" }}>
        <ServerRoomHeader
          title={title}
          onClickBack={onClickBack}
          onHomeClick={onClickBack}
        />
        <div className="server-room-status-details__form-container">
          <div className={`server-room-status-details__img-container`}>
            <ServerRoomPlan devices={devices} picture={serverRoom.picture} />
          </div>
          <div className="server-room-status-details__form">
            <InterlockFormPage
              onBackToDetail={onBackToDetailFromInterlockForm}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <ServerRoomHeader
          title={title}
          onClickBack={onClickBack}
          onHomeClick={onClickBack}
        />

        <div className="server-room-status-details__container">
          <div className={`server-room-status-details__img-container`}>
            <ServerRoomPlan devices={devices} picture={serverRoom.picture} />
          </div>
          {!scheduleOrInterlockfocused && (
            <>
              <div className={`server-room-status-details__ac-status-list`}>
                <ACStatusList
                  devices={devices}
                  onClick={() => {}}
                  focus={devices.reduce(function (map, obj) {
                    map[obj.id.toString()] = true;
                    return map;
                  }, {})}
                />
              </div>
              <div className="server-room-status-details__room-details">
                <ServerRoomDetails
                  schedules={ScheduleStore.schedules}
                  interlocks={InterlockStore.interlocks}
                  tabFocusedValue={detailTabFocusedValue}
                  onTabFocused={onDetailTabFocusedChange}
                  room={serverRoom}
                  onEditSchedule={editScheduleHandler}
                  onEditInterlock={editInterlockHandler}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
});
