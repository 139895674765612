import React from "react";

const style = {
  textActiveYellow: {
    color: "white",
    borderBottom: "3px solid #FFA726",
  },
  textActivePurple: {
    color: "#A3A1FB",
    borderBottom: "3px solid #A3A1FB",
  },
  textActiveYellowNoBorder: {
    color: "#FFA726",
  },
  textInactive: {
    color: "grey",
  },
  text: {},
};

export default function ActiveInactiveText({
  text,
  active = true,
  onClick = () => {},
  size = 1.5,
  activeStyle = "textActiveYellow",
  inactiveStyle = "textInactive",
}) {
  return (
    <div onClick={onClick}>
      <div
        style={{
          ...style[active ? activeStyle : inactiveStyle],
          fontSize: size + "rem",
        }}
      >
        {text}
      </div>
    </div>
  );
}
