import { useState, useEffect } from "react"
import { api } from "../../utils/api"
import DeviceListItem from "./DeviceListItem"
import { globalLoadingFn } from "../../utils/loading"

interface Props {
  roomData?: any
  roomId: number
  flexDirection: "row" | "column" | "row-reverse" | "column-reverse"
  activeDevice: any
  activeDeviceType?: number | null
  deviceType?: number | null
  activeAll?: boolean
  setActiveDevice: (device: any) => any
}

export default function DeviceList({ roomData, roomId, flexDirection = "row", activeDevice, activeDeviceType, deviceType, activeAll = false, setActiveDevice }: Props) {
  const [room, setRoom] = useState(roomData ?? null)
  const [devices, setDevices] = useState<any[]>([])

  function storeDevices(data) {
    if (deviceType) {
      setDevices(data.children.filter(c => c.device_type_id == deviceType));
    } else {
      setDevices(data.children)
    }
  }

  async function refreshData() {
    if (!roomData) {
      let roomResponse: any = await globalLoadingFn(() => api.get("/rooms/" + roomId))
      setRoom(roomResponse.data)
      storeDevices(roomResponse.data)
    } else {
      storeDevices(roomData)
    }
  }

  useEffect(() => {
    refreshData()
  }, [roomId])

  useEffect(() => {
    if (roomData) {
      setRoom(roomData)
      storeDevices(roomData)
    }
  }, [roomData, deviceType])

  return (
    <div style={{ flex: 1, display: "flex", flexDirection }}>
      {devices.filter(device => !device.is_vam).map((device) => (
        <div style={{margin: 12, width: "100%", ...(flexDirection == 'row' ? {flexBasis: 180} : {}), flexShrink: 0}} key={device.id}>
          <DeviceListItem
            device={device}
            refreshData={refreshData}
            setActiveDevice={setActiveDevice}
            active={activeAll || (activeDeviceType != 0 && device.device_type_id == activeDeviceType) || (activeDevice && device.id == activeDevice.id)}
          ></DeviceListItem>
        </div>
      ))}
    </div>
  )
}
