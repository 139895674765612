import React, { useEffect, useMemo, useState } from "react";
import { ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid, Line, Area, ResponsiveContainer, Bar } from "recharts";
import { BLACK_CARD, FLEX_COLUMN, FLEX_LR_CENTER, FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import SensorBar from "../sensor/SensorBar";
import { SENSOR_EXAMPLE } from "../sensor/SensorInterface";
import DashboardHeader from "./DashboardHeader";
import { v4 as uuidv4 } from "uuid"
import { api } from "../../utils/api";
import { ACTIVE_BUILDING_ID } from "../../utils/globalState";
import { globalLoadingFn } from "../../utils/loading";
import { EMPTY_CHART_DATA, weeklyBarData } from "../../utils/chart";
import { observer } from "mobx-react-lite";
import { SensorStore } from "../../stores/SensorStore";
import { DeviceStore, fetchBuilding } from "../../stores/DeviceStore";
import { countDevices, toggleDeviceOnOffAll, ZERO_DEVICE_COUNT } from "../../utils/device";
import { fetchScenes, SceneStore } from "../../stores/SceneStore";
import { Carousel } from "react-responsive-carousel";
import { RouterStore } from "../../stores/RouterStore";
import { baseScrollEvent } from "../../utils/scroll";
import { logout } from "../settings/LoginPage";

const MOCK_DATA = [
  {
    x: 0,
    y: 1,
  },
  {
    x: 1,
    y: 3,
  },
  {
    x: 2,
    y: 2,
  },
  {
    x: 3,
    y: 5,
  }
]

const MOCK_DATA2 = [
  {
    x: 0,
    download: 1,
    upload: 0.3,
  },
  {
    x: 1,
    download: 1.2,
    upload: 0.4,
  },
  {
    x: 2,
    download: 1.1,
    upload: 0.25,
  },
  {
    x: 3,
    download: 1,
    upload: 0.35,
  }
]

function DeviceCountCard({ label, amount, logo, color, unit }) {
  return (
    <div style={{ ...BLACK_CARD }}>
      <div>{label}</div>
      <div style={{ ...FLEX_ROW_CENTER, marginTop: 8 }}>
        <img src={logo}></img>
        <div
          style={{ fontSize: "2.25rem", color, marginLeft: 16, marginRight: 8 }}
        >
          {amount}
        </div>
        <div>{unit}</div>
      </div>
    </div>
  );
}

function InternetBandwidthCard({ speed, download, upload }) {
  return <div></div>

  return (
    <div style={{ ...BLACK_CARD, padding: 0 }}>
      <div style={{ textAlign: "right" }}>
        <div style={{}}>
          <div className="speedtest-container">
            <iframe className="speedtest-iframe" src="//openspeedtest.com/Get-widget.php?Auto=10"></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}

function InternetBandwidthCard2({ speed, download, upload }) {
  const id = useMemo(() => uuidv4(), []);
  const id2 = useMemo(() => uuidv4(), []);

  const formattedData = MOCK_DATA2;

  return (
    <div style={{ ...BLACK_CARD }}>
      <div style={{ ...FLEX_LR_CENTER }}>
        <div>Internet Bandwidth</div>
        <div><span style={{ fontSize: '1.5rem' }}>150.43</span> Mbps</div>
      </div>

      <div style={{
        width: "calc(100% + 40px)",
        marginLeft: -16,
        marginRight: -24,
      }}>
        <ResponsiveContainer width="100%" height={105}>
          <ComposedChart data={formattedData} margin={{ top: 25, right: 30, left: 20, bottom: 5 }}>
            <defs>
              <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
                <stop offset="80%" stopColor="#66BB6A" stopOpacity={0.2} />
                <stop offset="20%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
            </defs>

            <defs>
              <linearGradient id={id2} x1="0" y1="0" x2="0" y2="1">
                <stop offset="80%" stopColor="#EEAC2A" stopOpacity={0.2} />
                <stop offset="20%" stopColor="#FFFFFF" stopOpacity={0.1} />
              </linearGradient>
            </defs>
            {/* <XAxis dataKey="time" tickFormatter={convertDate} />
            <YAxis tickFormatter={convertValue}/> */}
            <Tooltip />
            <CartesianGrid vertical={true} horizontal={false} stroke="#777" />

            <Line type="monotone" unit="M" strokeLinecap="round" strokeWidth={2}
              style={{ strokeDasharray: `40% 60%` }}
              dataKey="download"
              stroke="#66BB6A"
              dot={false}
              legendType="none"
            />
            <Area type="monotone" dataKey="download" stroke="#66BB6A" strokeWidth={2} fillOpacity={1} fill={`url(#${id})`} />

            <Line type="monotone" unit="M" strokeLinecap="round" strokeWidth={2}
              style={{ strokeDasharray: `40% 60%` }}
              dataKey="upload"
              stroke="#EEAC2A"
              dot={false}
              legendType="none"
            />
            <Area type="monotone" dataKey="upload" stroke="#EEAC2A" strokeWidth={2} fillOpacity={1} fill={`url(#${id})`} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>

      <div style={{ ...FLEX_LR_CENTER }}>
        <div style={{ color: '#5FE3A1' }}>Download</div>
        <div style={{ color: '#FFA726' }}>Upload</div>
      </div>
    </div>
  )
}

const renderEnergyHistoryChartShape = (key, pixel = 10) => ({ height, width, fill, x, y, ...rest }) => {
  const xpercent = Math.trunc((pixel * 100) / Math.trunc(height || 1));
  return (
    <svg x={x} y={y} fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={key} x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor={fill} stopOpacity={1} />
          <stop offset="100%" stopColor={fill} stopOpacity={0.3} />
        </linearGradient>
      </defs>
      <rect fill={`url(#${key})`} width={width} height={height} rx={16} />
    </svg>
  );
}

function EnergyHistoryCard({ kwh, data }) {
  const id = useMemo(() => uuidv4(), []);

  const color = "#5FE3A1"

  const formattedData = data.x.map((x, i) => ({
    x,
    y: data.y[i]
  }));

  return (
    <div style={{ ...BLACK_CARD }}>
      <div style={{ ...FLEX_LR_CENTER }}>
        <div style={{ fontSize: '1.2rem' }}>Last 7 days energy consumption</div>
        <div><span style={{ fontSize: '2rem' }}>{kwh}</span> kWh</div>
      </div>

      <ResponsiveContainer width="100%" height={290}>
        <ComposedChart data={formattedData} margin={{ top: 25, right: 30, left: 20, bottom: 5 }}>
          <XAxis dataKey="x" />
          <YAxis tickFormatter={tick => tick + 'kW'} />
          <Tooltip content={
            ({ active, payload, label }) => (
              payload && payload[0] ?
                <div className="chart-tooltip">{parseFloat(payload[0]?.value?.toString() || "0").toFixed(2) + " kW"}</div>
                : null
            )} />
          <CartesianGrid vertical={true} horizontal={false} stroke="#777" />

          <Bar
            dataKey="y"
            fill={color}
            legendType="none"
            stackId="1"
            shape={renderEnergyHistoryChartShape(id)}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}

function MiniChartCard({ topic, value, color, unit, data, domain = ['0', 'auto'] }) {
  const id = useMemo(() => uuidv4(), []);

  const formattedData = data.x.map((x, i) => ({
    x: new Date(x).getTime(),
    y: data.y[i]
  }));

  // const latestData = data.y[data.y.length - 1];

  return (
    <div style={{ ...BLACK_CARD }}>
      <div style={{ ...FLEX_LR_CENTER }}>
        <div>{topic}</div>
        <div>{unit}</div>
      </div>

      <div style={{ position: "relative" }}>
        <div style={{
          width: "calc(100% + 40px)",
          marginLeft: -16,
          marginRight: -24,
        }}>
          <ResponsiveContainer width="100%" height={100}>
            <ComposedChart data={formattedData} margin={{ top: 25, right: 30, left: 20, bottom: 5 }}>
              <defs>
                <linearGradient id={id} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="80%" stopColor={color} stopOpacity={0.2} />
                  <stop offset="20%" stopColor="#FFFFFF" stopOpacity={0.1} />
                </linearGradient>
              </defs>
              {/* <XAxis dataKey="time" tickFormatter={convertDate} /> */}
              <YAxis domain={domain} axisLine={false} tickLine={false} tick={false} width={0} />
              {/* <Tooltip content={
                ({active, payload, label}) => (
                  payload && payload[0] ? 
                  <div className="chart-tooltip">{parseFloat(payload[0]?.value?.toString() || "0").toFixed(2)}</div> 
                  : null
                )}/> */}
              <CartesianGrid vertical={true} horizontal={false} stroke="#777" />

              <Line type="monotone" unit="M" strokeLinecap="round" strokeWidth={2}
                style={{ strokeDasharray: `40% 60%` }}
                dataKey="y"
                stroke={color}
                dot={false}
                legendType="none"
              />
              <Area type="monotone" dataKey="y" stroke={color} strokeWidth={2} fillOpacity={1} fill={`url(#${id})`} />
            </ComposedChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-value">
          {value}
        </div>
      </div>
    </div>
  )
}

const VAM_SERVER_BUTTON_STYLE: object = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 16,
  height: 150,
}

const GALLERY_BUTTON_STYLE: object = {
  ...FLEX_ROW_CENTER_CENTER,
  borderRadius: '50%',
  width: 48,
  height: 48,
  background: 'black',
  marginBottom: 16,
}

function GalleryButton({ image, active, onClick, size = 28 }) {
  return (
    <div style={{ ...GALLERY_BUTTON_STYLE, ...(active ? { border: '1px solid white' } : {}) }} onClick={onClick}>
      <img src={image} width={size}></img>
    </div>
  )
}

export default observer(function DashboardPage() {
  const [buildingFirstTime, setBuildingFirstTime] = useState(true);
  const [activeGallery, setActiveGallery] = useState('video');

  const favoriteScenes = SceneStore.getFavoriteScenes();
  const scenes = SceneStore.getFavoriteScenes();

  const energyData = SensorStore.sensorData;
  const building = DeviceStore.building;


  const gallery: any = building.properties?.gallery || {};

  useEffect(() => {
    if (buildingFirstTime && building.properties) {
      if (!gallery.video) {
        setActiveGallery('images');
      }
      setBuildingFirstTime(false);
    }

  }, [building]);


  const lightCount = building.children ? countDevices(building, 3) : ZERO_DEVICE_COUNT;
  const airCount = building.children ? countDevices(building, 1) : ZERO_DEVICE_COUNT;

  const {
    weeklyEnergy,
    dailyTemperature,
    dailyHumidity,
    dailyPM2_5,
    dailyCO2,
    lastTemperature,
    lastHumidity,
    lastPM2_5,
    lastCO2,
  } = SensorStore.getSensorValue();

  let totalKWH = 0;

  if (weeklyEnergy) {
    for (let i = 0; i < weeklyEnergy.length; i++) {
      totalKWH += weeklyEnergy.y[i] * 24;
    }

    totalKWH += weeklyEnergy.y[6] * new Date().getHours();
  }

  // console.log(weeklyEnergy);
  // console.log(energyData?.weekly?.data.find(c => c.name == 'Energy'))

  return (
    <div style={{ padding: 32 }}>
      <div style={{ marginBottom: 16 }}>
        <DashboardHeader
          aqi={100}
          temp={Math.round(building?.weather_outsides?.[0]?.temperature) || '??'}
          icon={building?.weather_outsides?.[0]?.raw?.weather?.[0]?.icon || '01d'}
        ></DashboardHeader>
      </div>

      <div style={{ marginBottom: 16 }}>
        <SensorBar
          temp={lastTemperature}
          humidity={lastHumidity}
          aqi={lastPM2_5}
          co2={lastCO2}
          mini={false}
          placeLabel="อาคาร"
        ></SensorBar>
      </div>

      <div style={{ display: 'flex', alignItems: 'stretch', marginBottom: 16 }}>
        <div style={{ marginRight: 16, ...FLEX_COLUMN, justifyContent: 'space-between', height: "100%" }}>
          <div>
            {gallery.video &&
              <GalleryButton
                image={'/img/dashboard/video-btn.svg'}
                active={activeGallery == 'video'}
                onClick={() => setActiveGallery('video')}
              ></GalleryButton>
            }

            <GalleryButton
              image={'/img/dashboard/image-btn.svg'}
              active={activeGallery == 'images'}
              onClick={() => setActiveGallery('images')}
            ></GalleryButton>

            <GalleryButton
              image={'/img/dashboard/text-btn.svg'}
              active={activeGallery == 'texts'}
              onClick={() => setActiveGallery('texts')}
            ></GalleryButton>

            <GalleryButton
              image={'/img/dashboard/account-btn.svg'}
              active={activeGallery == 'account'}
              onClick={() => RouterStore.navigate('account')}
              size={20}
            ></GalleryButton>

            <GalleryButton
              image={'/img/dashboard/logout-btn.svg'}
              active={activeGallery == 'logout'}
              onClick={() => logout()}
              size={20}
            ></GalleryButton>
          </div>

          <div>

            {/* <GalleryButton
              image={'/img/dashboard/tel-btn.svg'}
              active={activeGallery == 'tel'}
              onClick={() => setActiveGallery('tel')}
            ></GalleryButton> */}
          </div>
        </div>

        <div style={{ flexGrow: 1 }}>
          {activeGallery == 'video' &&
            <iframe
              src={gallery.video}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              style={{ width: "100%", height: 600, border: 0 }}
            ></iframe>
          }

          {activeGallery == 'images' &&
            <div className="real-carousel" {...baseScrollEvent}>
              {/* <div className="center-y" style={{ left: "-100%", position: "absolute", zIndex: "5", color: gallery.texts.color, fontSize: gallery.texts.fontSize, animation: `slide-text ${gallery.texts.duration}s linear 1` }}>{gallery.texts.text}</div> */}
              <Carousel infiniteLoop={true} autoPlay={true} interval={12000}>
                {(gallery.images || []).map(image => (
                  <div>
                    <div style={{
                      width: "100%",
                      height: 600,
                      backgroundImage: "url(" + image + ")",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat"
                    }}></div>
                  </div>
                ))}
              </Carousel>
            </div>
          }

          {activeGallery == 'texts' && gallery.texts &&
            <div style={{ position: "relative", width: "100%", height: "100%", overflow: "hidden" }}>
              <div className="center-y" style={{ "--textLength": (Number(gallery.texts.text.length) * Number(gallery.texts.fontSize)), left: -(Number(gallery.texts.text.length) * Number(gallery.texts.fontSize)), whiteSpace: "nowrap", position: "absolute", zIndex: "5", color: gallery.texts.color, fontSize: gallery.texts.fontSize, animation: `slide-text ${gallery.texts.duration}s linear infinite` } as React.CSSProperties}>{gallery.texts.text}</div>
            </div>
          }
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "45% auto",
          columnGap: 16,
          marginBottom: 16,
        }}
      >
        <div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              columnGap: 16,
              marginBottom: 16,
            }}
          >
            <DeviceCountCard
              label="จำนวนหลอดไฟที่เปิด"
              amount={lightCount.online}
              logo="/img/control/light/light-active-circle.svg"
              color="#FFA726"
              unit="ดวง"
            ></DeviceCountCard>

            <DeviceCountCard
              label="จำนวนแอร์ที่เปิด"
              amount={airCount.online}
              logo="/img/control/air/air-active-circle.svg"
              color="#56D9FE"
              unit="เครื่อง"
            ></DeviceCountCard>
          </div>

          <div style={{ marginBottom: 16 }}>
            <InternetBandwidthCard speed={150.43} download={[]} upload={[]}></InternetBandwidthCard>
          </div>

          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              columnGap: 16,
              marginBottom: 16,
            }}
          >
            <div style={{ ...VAM_SERVER_BUTTON_STYLE, backgroundColor: '#A3A1FB77' }} onClick={() => RouterStore.navigate('vam')}>
              <img src="/img/dashboard/vam-btn.svg"></img>
              <div style={{ marginTop: 12, fontSize: '1.4rem' }}>VAM</div>
            </div>

            <div style={{ ...VAM_SERVER_BUTTON_STYLE, backgroundColor: '#56D9FE77' }} onClick={() => RouterStore.navigate('server-room')}>
              <img src="/img/dashboard/server-btn.svg"></img>
              <div style={{ marginTop: 12, fontSize: '1.4rem' }}>Server Room</div>
            </div>
          </div>
        </div>

        <div>
          <div style={{ marginBottom: 16 }}>
            <EnergyHistoryCard kwh={totalKWH.toFixed(0)} data={weeklyEnergy || EMPTY_CHART_DATA}></EnergyHistoryCard>
          </div>

          <div style={{
            display: "grid",
            gridTemplateColumns: "31% 31% 31%",
            justifyContent: "center",
            columnGap: 16,
          }}>
            <MiniChartCard topic="Temperature" value={lastTemperature ? lastTemperature.toFixed(0) + "℃" : "-"} unit="℃" color="#FFA726" data={dailyTemperature || EMPTY_CHART_DATA} domain={['dataMin - 3', 'dataMax + 3']}></MiniChartCard>
            <MiniChartCard topic="Humidity" value={lastHumidity ? lastHumidity.toFixed(0) + "%" : "-"} unit="%" color="#56D9FE" data={dailyHumidity || EMPTY_CHART_DATA}></MiniChartCard>
            <MiniChartCard topic="Air Quality" value={lastPM2_5 ? lastPM2_5.toFixed(0) : "-"} unit="US AQI" color="#A3A1FB" data={dailyPM2_5 || EMPTY_CHART_DATA}></MiniChartCard>
          </div>
        </div>
      </div>

      <div style={{ ...FLEX_LR_CENTER, marginBottom: 16, fontSize: '1.3rem' }} {...baseScrollEvent}>
        <div><b>Favorite Scene</b></div>
        <div style={{ ...FLEX_ROW_CENTER }}>
          <div onClick={() => {
            RouterStore.navigate('schedule');
          }} style={{ marginRight: 24 }}><u>Schedule</u></div>

          <div onClick={() => {
            RouterStore.setControlPageTabMode('scene');
            RouterStore.navigate('control');
          }}><u>More Scene</u></div>
        </div>
      </div>

      <div style={{ ...FLEX_ROW_CENTER, overflowX: 'auto', marginBottom: 24 }} {...baseScrollEvent}>
        <div
          style={{
            ...BLACK_CARD,
            ...FLEX_ROW_CENTER,
            ...(lightCount.online + airCount.online == 0 ? {
              border: '3px solid white',
            } : {}),
            paddingTop: 28,
            paddingBottom: 28,
            paddingLeft: 36,
            paddingRight: 36,
            marginRight: 16,
            minWidth: 240,
          }}

          onClick={async () => {
            await globalLoadingFn(() => toggleDeviceOnOffAll('building', ACTIVE_BUILDING_ID, false));
            fetchBuilding({ alwaysLoading: true });
          }}
        >
          <div style={{ marginRight: 16 }}>
            <img src="/img/control/air/power.svg" style={{ width: 24 }}></img>
          </div>

          <div>
            ปิดทั้งอาคาร
          </div>
        </div>

        <div
          style={{
            ...BLACK_CARD,
            ...FLEX_ROW_CENTER,
            ...(lightCount.offline + airCount.offline == 0 ? {
              border: '3px solid white',
            } : {}),
            paddingTop: 28,
            paddingBottom: 28,
            paddingLeft: 36,
            paddingRight: 36,
            marginRight: 16,
            minWidth: 240,
          }}

          onClick={async () => {
            await globalLoadingFn(() => toggleDeviceOnOffAll('building', ACTIVE_BUILDING_ID, true));
            fetchBuilding({ alwaysLoading: true });
          }}
        >
          <div style={{ marginRight: 16 }}>
            <img src="/img/control/air/power-active.svg" style={{ width: 24 }}></img>
          </div>

          <div>
            เปิดทั้งอาคาร
          </div>
        </div>

        {favoriteScenes.map(scene =>
          <div
            style={{
              ...BLACK_CARD,
              ...FLEX_ROW_CENTER,
              ...(scene.running ? {
                border: '3px solid white',
              } : {}),
              paddingTop: 28,
              paddingBottom: 28,
              paddingLeft: 36,
              paddingRight: 36,
              marginRight: 16,
              minWidth: 240,
            }}

            onClick={async () => {
              await globalLoadingFn(() => api.post('/schedules/scene/' + scene.id + '/apply', { inverse: scene.running }));
              await fetchBuilding({ alwaysLoading: true })
              await fetchScenes(true);
            }}
          >
            <div style={{ marginRight: 16 }}>
              <img src={scene.icon}></img>
            </div>

            <div>
              {scene.name}
            </div>
          </div>
        )}
      </div>
    </div>
  );
})
