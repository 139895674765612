import { api } from "./api";

export function deviceTypeId2controlEntity(id: number | string): string {
  switch (parseInt(id.toString())) {
    case 1: return "device_control_air";
    case 2: return "device_control_sensor";
    case 3: return "device_control_light";
    case 4: return "device_control_curtain";
    case 5: return "device_control_door";
    case 6: return "device_control_breaker";
  }
  return "";
}

export function deviceTypeId2shortName(id: number | string): string {
  switch (parseInt(id.toString())) {
    case 1: return "air";
    case 2: return "sensor";
    case 3: return "light";
    case 4: return "curtain";
    case 5: return "door";
    case 6: return "breaker";
  }
  return "";
}

export function flattenDevices(places: any): any[] {
  if (places.children) return flattenDevices(places.children);
  if (!Array.isArray(places)) return [places];

  let result: any[] = [];

  for (let place of places) {
    result = result.concat(flattenDevices(place));
  }

  return result;
}

export function applyDevicesFn(data: any, fn: (x: any) => any): any {
  if (data.children) return {...data, children: applyDevicesFn(data.children, fn)};
  if (!Array.isArray(data)) return fn(data);

  let children: any[] = [];

  for (let place of data) {
    let result = applyDevicesFn(place, fn);
    if (result) {
      children.push(result);
    }
  }

  return children;
}

interface DeviceCountStruct {
  online: number;
  offline: number;
  error: number;
  total: number;
}

export const ZERO_DEVICE_COUNT = {
  online: 0,
  offline: 0,
  error: 0,
  total: 0,
}

export function countDevices(places: any, deviceTypeId?: number): DeviceCountStruct {
  let devices = flattenDevices(places);

  if (deviceTypeId) {
    devices = devices.filter(c => c.device_type_id == deviceTypeId);
  }

  let online = 0;
  let offline = 0;
  let error = 0;
  let total = 0;

  for (let device of devices) {
    total++;

    if (device.status == 'on') {
      online++;
    } else {
      offline++;
    }

    if (device.is_error) {
      error++;
    }
  }

  return {
    online,
    offline,
    error,
    total,
  }
}

const ONOFF_CONTROL_ENTITY = [
  "device_control_air",
  "device_control_light",
]

export async function toggleDeviceOnOffAll(scopeType, scopeId, isOn) {
  for (let controlEntity of ONOFF_CONTROL_ENTITY) {
    await api.post('/devices/' + controlEntity + '/turn_' + (isOn ? 'on' : 'off') + '?scope_type=' + scopeType + '&scope_id=' + scopeId);
  }
}