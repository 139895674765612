import { TextField } from "@mui/material";
import "./NumberedTextField.css";

export default function NumberedTextField({ value, onChange, size = 2 }) {
  return (
    <TextField
      className="common-numbered-text-field"
      type="number"
      value={parseFloat(value).toString()}
      InputLabelProps={{
        shrink: false,
      }}
      InputProps={{
        inputProps: {
          style: {
            textAlign: "center",
            fontSize: `${size * 1.2}rem`,
            margin: 0,
          },
        },
        endAdornment: (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img
              src="/img/common/arrow-up.svg"
              width={`${size * 8}px`}
              onClick={() => onChange(value + 1)}
            />
            <img
              src="/img/common/arrow-down.svg"
              width={`${size * 8}px`}
              onClick={() => onChange(value > 0 ? value - 1 : 0)}
            />
          </div>
        ),
      }}
      onChange={e => onChange(parseFloat(e.target.value || "0") >= 0 ? parseFloat(e.target.value || "0") : 0)}
    />
  );
}
