import { useEffect, useState } from "react";
import { fetchBuilding } from "../../stores/DeviceStore";
import {
  BLACK_CARD,
  FLEX_COLUMN_START,
  FLEX_ROW_START,
  GRAY_BACKGROUND_COLOR
} from "../../theme/style";
import { api } from "../../utils/api";
import { deviceTypeId2controlEntity } from "../../utils/device";
import { globalLoadingFn } from "../../utils/loading";
import BlackSelect from "../common/BlackSelect";
import Text from "../common/Text";
import ACControlCard from "./ACControlCard";

export default function ACControl({ device, devices, onChange, onUpdate }) {
  var [deviceControl, setDeviceControl] = useState(device.device_control_air);

  useEffect(() => {
    setDeviceControl(device.device_control_air);
  }, [device.device_control_air]);

  async function saveSettings() {
    const scopeId = device.id;
    const scopeType = "device";
    let onoff = deviceControl.device_status == 1 ? "on" : "off";

    await globalLoadingFn(() =>
      api.post(
        `/devices/${deviceTypeId2controlEntity(
          device.device_type_id
        )}/turn_${onoff}?scope_type=${scopeType}&scope_id=${scopeId}`
      )
    );

    const body = {
      device_temp: deviceControl.device_temp,
      device_mode: deviceControl.device_mode,
      device_swing_mode: deviceControl.device_swing_mode,
      device_fan_speed: deviceControl.device_fan_speed,
    };

    await globalLoadingFn(() =>
      api.patch(
        `/devices/${deviceTypeId2controlEntity(
          device.device_type_id
        )}?scope_type=${scopeType}&scope_id=${scopeId}`,
        body
      )
    );

    await fetchBuilding();

    onUpdate();
  }

  function deviceControlChangeHandler(d) {
    setDeviceControl(d);
  }

  const orientation =
    window.innerHeight < window.innerWidth ? "landscape" : "portrait";

  return (
    <div
      style={{
        ...BLACK_CARD,
        ...FLEX_COLUMN_START,
        height: "100%",
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 40,
        gap: 36,
      }}
    >
      {/* <div
        style={{ ...FLEX_ROW_START, justifyContent: "flex-end", gap: "8px" }}
      >
        <img
          src="/img/common/reload.svg"
          width="16px"
          onClick={fetchBuilding}
        ></img>
        <img
          src="/img/common/save.svg"
          width="16px"
          onClick={saveSettings}
        ></img>
      </div> */}
      <div
        style={{
          ...FLEX_COLUMN_START,
          height: "100%",
          paddingTop: "5%",
          paddingLeft: "5%",
          paddingRight: "5%",
          gap: 36,
        }}
      >
        <div style={{ ...FLEX_ROW_START, gap: 10 }}>
          <Text fontSize={orientation == "portrait" ? "2rem" : "1.5rem"}>
            Device
          </Text>
          <BlackSelect
            variant="standard"
            style={{ borderColor: "black" }}
            sx={{ bgcolor: GRAY_BACKGROUND_COLOR, borderRadius: 16 }}
            value={device.id}
            options={devices.map((d) => {
              return { value: d.id, label: d.name };
            })}
            onChange={onChange}
            disableUnderline={true}
          ></BlackSelect>
        </div>
        <ACControlCard
          scopeId={device.id}
          scopeType="device"
          device={device}
          deviceControl={deviceControl}
          refreshData={() => {
            fetchBuilding();
          }}
          // overrideOnControl={true}
          // onChange={(d) => deviceControlChangeHandler(d)}
          size={orientation == "portrait" ? 2 : 1}
        />
      </div>
    </div>
  );
}
