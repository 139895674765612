import React, { useEffect, useState } from "react";
import {
  FLEX_COLUMN_CENTER_CENTER,
  FLEX_ROW_CENTER,
  FLEX_ROW_CENTER_CENTER,
} from "../../theme/style";
import { api } from "../../utils/api";
import { deviceTypeId2controlEntity } from "../../utils/device";
import { globalLoadingFn } from "../../utils/loading";
import ControlButton from "./ControlButton";
import Slider, { Range } from "rc-slider";
import Switch from "react-switch";

export default function LightControlPanel({
  scopeType,
  scopeId,
  device,
  refreshData,
  showType = 0,
  onControl = (device, controlKey, controlValue) => {},
  overrideOnControl = false,
  ...props
}) {
  const commonParameter = {
    scopeType,
    scopeId,
    device,
    refreshData,
  };

  return (
    <div>
      <div>
        {/* POWER PANEL */}
        <div
          style={{
            marginBottom: 4,
            backgroundColor: "black",
            borderRadius: 12,
            padding: 24,
            position: "relative",

            ...FLEX_ROW_CENTER,
          }}
        >
          <div
            style={{
              ...(showType == 0
                ? FLEX_COLUMN_CENTER_CENTER
                : FLEX_ROW_CENTER_CENTER),
              marginRight: 24,
            }}
          >
            <ControlButton
              {...commonParameter}
              controlKey="device_status"
              controlValueFn={(device) =>
                device.device_control_light.device_status == 1 ? 0 : 1
              }
              image={`/img/control/light/light`}
              noBorder
              padding={2}
              size={2}
              active={device.device_control_light.device_status == 1}
            ></ControlButton>

            <div style={{ fontSize: "1.5rem" }}>
              {device.device_control_light.device_status == 1 ? "ON" : "OFF"}
            </div>
          </div>

          <Switch
            onChange={async () => {
              let onoff =
                device.device_control_light.device_status == 0 ? "on" : "off";

              if (!overrideOnControl) {
                await globalLoadingFn(() =>
                  api.post(
                    `/devices/${deviceTypeId2controlEntity(
                      device.device_type_id
                    )}/turn_${onoff}?scope_type=${scopeType}&scope_id=${scopeId}`
                  )
                );
              }

              onControl(device, 'device_status', onoff == 'on' ? 1 : 0);

              refreshData();
            }}
            checked={device.device_control_light.device_status == 1}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#FFA726"
          />
        </div>
      </div>
    </div>
  );
}
