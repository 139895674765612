import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { DeviceStore } from "../../stores/DeviceStore";
import { FLEX_ROW_CENTER } from "../../theme/style";
import { api } from "../../utils/api";
import { globalLoadingFn } from "../../utils/loading";
import { alert_success } from "../../utils/toast";
import FilePicker from "../common/FilePicker";
import HeaderCommon from "../common/HeaderCommon";

export default observer(function GallerySettingPage() {
  const building = DeviceStore.building;
  const [video, setVideo] = useState("");
  const [images, setImages] = useState<string[]>([]);
  const [texts, setTexts] = useState<{ text: string; fontSize: number; duration: number, color: string }>({
    text: "",
    fontSize: 24,
    duration: 1,
    color: "#ff0000"
  });
  const [firstTime, setFirstTime] = useState(true);

  useEffect(() => {
    if (firstTime && building.properties && building.properties.gallery) {
      setVideo(building.properties.gallery.video);
      setImages(building.properties.gallery.images);
      setTexts(building.properties.gallery.texts);
      setFirstTime(false);
    }
  }, [building]);

  async function updateBuilding() {
    let properties = building.properties || {};
    if (!properties.gallery) properties.gallery = {};

    properties.gallery = {
      ...properties.gallery,
      video,
      images,
      texts,
    };

    await globalLoadingFn(() =>
      api.patch("/buildings/" + building.id, {
        ...building,
        properties,
      })
    );

    alert_success('บันทึกสำเร็จ')
  }

  return (
    <div>
      <HeaderCommon title="ตั้งค่าภาพหน้าแรก" compact={true}></HeaderCommon>

      <div style={{ padding: 24 }}>
        <div style={{ marginBottom: 8 }}>Video</div>

        <div style={{ marginBottom: 48 }}>
          <TextField
            id="youtube-link"
            label="Youtube Link"
            variant="standard"
            value={video}
            onChange={(event) => setVideo(event.target.value)}
            fullWidth
          />
        </div>

        <div style={{ marginBottom: 16 }}>รูปที่แสดง</div>

        <div style={{ ...FLEX_ROW_CENTER, flexWrap: "wrap", marginBottom: 24 }}>
          {images.map((image, index) => (
            <div style={{ marginRight: 16, marginBottom: 16 }} key={index}>
              <div style={{ marginBottom: 16 }}>
                <img alt="" src={image} width={400} style={{ aspectRatio: "16/9" }} />
              </div>

              <div
                style={{
                  backgroundColor: "darkred",
                  color: "white",
                  textAlign: "center",
                  padding: 16,
                  borderRadius: 16,
                  width: '100%',
                }}

                onClick={() => {
                  let index = images.indexOf(image);
                  let newImages = [...images];
                  newImages.splice(index, 1);
                  setImages(newImages);
                }}
              >
                ลบ
              </div>
            </div>
          ))}
        </div>

        <FilePicker
          onChange={(image) => {
            setImages([...images, image]);
          }}

          style={{
            marginBottom: 48,
          }}
        >
          <div
            style={{
              backgroundColor: "darkgreen",
              color: "white",
              textAlign: "center",
              padding: 16,
              borderRadius: 16,
              paddingLeft: 48,
              paddingRight: 48,
            }}
          >
            เพิ่มรูปภาพ
          </div>
        </FilePicker>

        <div style={{ marginBottom: 8 }}>text วิ่ง</div>

        <div style={{ marginBottom: 16 }}>
          <TextField
            id="textArea"
            label="ข้อความ"
            variant="standard"
            type="text"
            multiline
            fullWidth
            value={texts.text}
            onChange={(event) => setTexts({ ...texts, text: event.target.value })}
          />
        </div>

        <div style={{ marginBottom: 16, display: "flex" }}>
          <TextField
            id="textArea"
            label="font-size"
            variant="standard"
            type="number"
            value={texts.fontSize}
            onChange={(event) => setTexts({ ...texts, fontSize: Number(event.target.value) })}
          />
          <p style={{ marginLeft: "10px" }}>pt</p>
        </div>

        <div style={{ marginBottom: 16, display: "flex" }}>
          <TextField
            id="interation"
            label="เวลาในการวิ่ง 1 รอบ"
            variant="standard"
            type="number"
            value={texts.duration}
            onChange={(event) => setTexts({ ...texts, duration: Number(event.target.value) })}
          />
          <p style={{ marginLeft: "10px" }}>วินาที</p>
        </div>

        <div style={{ marginBottom: 8 }}>สีข้อความ</div>
        <div style={{ marginBottom: 16 }}>
          <input
            id="color"
            type="color"
            value={texts.color}
            onChange={(event) => setTexts({ ...texts, color: event.target.value })}
          />
        </div>

        <div
          style={{
            backgroundColor: "white",
            color: "black",
            textAlign: "center",
            padding: 16,
            borderRadius: 16,
            paddingLeft: 48,
            paddingRight: 48,
            marginBottom: 48
          }}

          onClick={() => updateBuilding()}
        >
          บันทึก
        </div>
      </div>
    </div>
  );
});