import { useEffect, useState } from "react";
import {
  FLEX_COLUMN,
  FLEX_COLUMN_START_CENTER,
  FLEX_ROW_CENTER,
} from "../../theme/style";
import Text from "../common/Text";

import "./RoomStatus.css";

function DeviceStatus({ data }) {
  const { title, unit, set, defaultset, now } = data;
  const key = title.replace(" ", "").toLowerCase();

  const status = now < (set[0] || defaultset[0]) ? "Low" : now > (set[1] || defaultset[1]) ? "High" : "Normal";

  return (
    <>
      <div style={{ ...FLEX_ROW_CENTER }}>
        <img src={data.img} style={{ height: "40px" }} />
        <div
          style={{
            ...FLEX_COLUMN,
            justifyContent: "center",
            height: "50%",
            marginLeft: 20,
          }}
        >
          {key == "co2" && (
            <>
              <Text className="room-status__title">
                <div style={{ display: "flex", fontSize: "1.5rem" }}>
                  CO<div style={{ fontSize: "0.6rem", lineHeight: 5 }}>2</div>
                </div>
              </Text>
              <Text className="room-status__measurement-unit">
                <div style={{ lineHeight: 0, color: "gray" }}>{unit}</div>
              </Text>
            </>
          )}
          {key != "co2" && (
            <>
              <Text className="room-status__title">{title}</Text>
              <Text className="room-status__measurement-unit">{unit}</Text>
            </>
          )}
        </div>
      </div>
      <Text className="room-status__set-value">
        {`${set[0] == -1 ? "< " : (set[0] ?? defaultset[0]) + "-"}${set[1] ?? defaultset[1]}`}
        {key == "temperature" ? " \u00b0C" : ""}
      </Text>
      <div
        style={{
          display: "grid",
          margin: "0 auto",
          gridTemplateColumns: "3fr 1fr",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ width: "100%", justifySelf: "end" }}>
          <Text className="room-status__current-value">
            {now}
            {key == "temperature" ? "\u00b0C" : ""}
          </Text>
          <Text className="room-status__current-value">{status}</Text>
        </div>
        <div style={{ justifySelf: "end" }}>
          <img src="/img/common/green-circle.svg" width="12px" />
        </div>
      </div>
    </>
  );
}

function DeviceHeader() {
  return (
    <>
      <div></div>
      <Text color="gray" style={{ margin: "0 auto" }}>
        Set
      </Text>
      <Text color="gray" style={{ margin: "0 auto" }}>
        Now
      </Text>
    </>
  );
}

const average = (args) => args.length == 0 ? 0 : args.reduce((a, b) => a + b, 0) / args.length;

const calculate_temp = (devices) => {
  // console.log(devices, devices.filter((x) => x.device_control_air && x.device_control_air.room_temp), devices.filter((x) => x.device_control_air && x.device_control_air.room_temp).map((x) => parseFloat(x.device_control_air.room_temp || '0')));
  return average(devices.filter((x) => x.device_control_air && x.device_control_air.room_temp).map((x) => parseFloat(x.device_control_air.room_temp || '0'))).toFixed(1);
};

const calculate_aqi = (devices) => {
  return average(devices.filter((x) => x.aqi).map((x) => x.aqi)).toFixed(1);
};

const calculate_humidity = (devices) => {
  return average(
    devices.filter((x) => x.humidity).map((x) => x.humidity)
  ).toFixed(1);
};

const calculate_co2 = (devices) => {
  return average(devices.filter((x) => x.humidity).map((x) => x.co2)).toFixed(
    1
  );
};

export default function RoomStatus({ sensorStandard, style, devices }) {
  const [temp, setTemp] = useState([
    sensorStandard.tempMin,
    sensorStandard.tempMax,
  ]);
  const [aqi, setAqi] = useState([
    sensorStandard.aqiMin,
    sensorStandard.aqiMax,
  ]);
  const [humidity, setHumidity] = useState([
    sensorStandard.humidityMin,
    sensorStandard.humidityMax,
  ]);
  const [co2, setCO2] = useState([-1, sensorStandard.co2Max]);

  const avg_temp = calculate_temp(devices);
  const avg_aqi = calculate_aqi(devices);
  const avg_humidity = calculate_humidity(devices);
  const avg_co2 = calculate_co2(devices);

  useEffect(() => {
    setTemp([sensorStandard.tempMin, sensorStandard.tempMax]);

    setAqi([sensorStandard.aqiMin, sensorStandard.aqiMax]);

    setHumidity([sensorStandard.humidityMin, sensorStandard.humidityMax]);

    setCO2([-1, sensorStandard.co2Max]);
  }, [sensorStandard]);

  const info = [
    {
      img: "/img/sensor/temp-outlined-active.svg",
      title: "Temperature",
      unit: "Degree Celsius",
      set: temp,
      defaultset: [20, 22],
      now: avg_temp,
    },
    {
      img: "/img/sensor/aqi-outlined-active.svg",
      title: "Air Quality",
      unit: "US AQI",
      set: aqi,
      defaultset: [20, 50],
      now: avg_aqi,
    },
    {
      img: "/img/sensor/humidity-outlined-active.svg",
      title: "Humidity",
      unit: "Rn",
      set: humidity,
      defaultset: [45, 50],
      now: avg_humidity,
    },
    {
      img: "/img/sensor/co2-outlined-active.svg",
      title: "CO2",
      unit: "PPM",
      set: co2,
      defaultset: [-1, 1000],
      now: avg_co2,
    },
  ];
  return (
    <div style={{ ...style }}>
      <Text fontSize="1.75rem">Room Status</Text>
      <div className="room-status__sensor-data">
        <DeviceHeader />
        {info.map((data) => (
          <DeviceStatus data={data} key={data.title} />
        ))}
      </div>
    </div>
  );
}
