import SensorBar from "../sensor/SensorBar";
import {
  BLACK_CARD,
  FLEX_COLUMN,
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER,
} from "../../theme/style";
import AirConditionerStatusList from "../ac/AirConditionerStatusList";
import { Divider } from "@mui/material";
import Text from "../common/Text";
import { useState } from "react";
import DetailButton from "../common/DetailButton";

export default function ServerRoomListItem({ serverRoom, onSelectRoom }) {
  const {
    name,
    floor_id: floorId,
    status,
    temp,
    aqi,
    humidity,
    co2,
    children,
  } = serverRoom;
  const [showDetail, setShowDetail] = useState(false);

  const showDetailHandler = () => {
    setShowDetail(true);
  };

  const serverRoomSelectHandler = (e) => {
    onSelectRoom();
  };

  // Special temp calculation
  const roomTemp = children.length > 0 ? children.map(x => parseFloat(x.device_control_air?.room_temp) || 0).reduce((acc, x) => acc + x, 0) / children.length : 0;

  return (
    <div
      style={{
        ...FLEX_COLUMN,
        ...BLACK_CARD,
        marginBottom: "20px",
        width: "100%",
      }}
    >
      <div
        style={{
          ...FLEX_LR_CENTER,
          width: "100%",
          marginBottom: 16,
        }}
      >
        <Text fontSize="1.2rem" onClick={serverRoomSelectHandler}>
          Server Room {name}
        </Text>
        {/* <Text fontSize="1rem">Floor : {floorId}</Text> */}
        <div style={{ ...FLEX_ROW_CENTER, gap: 16 }}>
          {status}{" "}
          <DetailButton
            onClick={showDetailHandler}
            style={{ display: showDetail ? "none" : "flex" }}
          />
        </div>
      </div>
      <Divider style={{ background: "gray", marginBottom: 10 }} />
      <div style={{ margin: 16 }}>
        <SensorBar
          mini={true}
          grayscale={showDetail}
          temp={roomTemp ?? null}
          aqi={aqi ?? null}
          humidity={humidity ?? null}
          co2={co2 ?? null}
        />
      </div>
      <Divider
        style={{
          background: "gray",
          marginBottom: 10,
          display: showDetail ? "block" : "none",
        }}
      />

      <AirConditionerStatusList
        devices={serverRoom.children}
        style={{ display: showDetail ? "flex" : "none" }}
        onSelectRoom={serverRoomSelectHandler}
      />
    </div>
  );
}
