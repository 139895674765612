import React from "react"
import { api } from "../../utils/api"
import { FLEX_ROW_CENTER } from "../../theme/style"
import { deviceTypeId2controlEntity, deviceTypeId2shortName } from "../../utils/device";
import { getDeviceColor } from "../../theme/deviceTheme";

function getIconImageURL(device): string {
  return `/img/control/${deviceTypeId2shortName(device.device_type_id)}-solid${device.status == 'on' ? '' : '-grey'}.svg`;
}

export default function DeviceListItem({ device, refreshData, setActiveDevice, active = false }) {
  return (
    <div
      style={{ 
        ...FLEX_ROW_CENTER,
        border: `2px solid ${getDeviceColor(device.device_type_id)}${device.status == 'on' ? '' : '99'}`,
        borderRadius: 100,
        paddingLeft: 24,
        paddingRight: 16,
        backgroundColor: device.is_error ? "rgba(255,0,0,0.3)" : (active ? "rgba(255,255,255,0.2)" : "black"),
        height: 74,
        width: 180,
      }}
      onClick={async () => {
        setActiveDevice(device)
        // let onoff = device.status == "on" ? "off" : "on"
        // await api.post(
        //   `/devices/${deviceTypeId2controlEntity(device.device_type_id)}/turn_${onoff}?scope_type=device&scope_id=${device.id}`,
        // )
        refreshData()
      }}
      className="hover-pointer"
    >
      <img src={getIconImageURL(device)} style={{marginRight: 20}}></img>
      <div>
        <div>{device.name}</div>
        {device.device_type_id == 1 && device.device_control_air.device_status == 0 ? <div><small>ปิดใช้งาน</small></div> : <></>}
        {device.device_type_id == 1 && device.device_control_air.device_status != 0 ? <div><small>อุณหภูมิ {device.device_control_air.device_temp}℃</small></div> : <></>}
      </div>
    </div>
  )
}
