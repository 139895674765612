import { TimePicker } from "@mui/lab";
import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import { DeviceStore } from "../../../stores/DeviceStore";
import {
  deleteScheduleWrapper,
  patchSchedule,
  ScheduleStore,
} from "../../../stores/ScheduleStore";
import { ServerRoomStore } from "../../../stores/ServerRoomStore";
import {
  FLEX_ROW_CENTER,
  FLEX_ROW_CENTER_CENTER,
  GRAY_BACKGROUND_COLOR,
} from "../../../theme/style";
import { formatTime, time2date } from "../../../utils/datetime";
import { flattenDevices } from "../../../utils/device";
import { MultipleACSelect } from "../../ac/ACSelect";
import DeleteButton from "../../common/DeleteButton";
import Text from "../../common/Text";
import ScheduleACControlCard from "./ScheduleACControlCard";

type ScheduleACControlType = {
  index: number;
  key: number;
  active: boolean;
  onClick: () => {};
};

const SwitchButton = ({ onClick, active }) => {
  const image = "/img/control/air/power";
  return (
    <div
      style={{
        ...FLEX_ROW_CENTER_CENTER,
        padding: 6,
        width: "auto",
        margin: 4,
      }}
      onClick={onClick}
    >
      {image && (
        <img
          src={image + (active ? "-active" : "") + ".svg"}
          width={24}
          height={24}
        ></img>
      )}
    </div>
  );
};

export default observer(function ScheduleACControl<ScheduleACControlType>({
  index,
  active,
  onClick,
  includeGroup = false,
}) {
  const serverRoom = ServerRoomStore.serverRoom;
  const activeDeviceScheduleWrapperIndex =
    ScheduleStore.activeDeviceScheduleWrapperIndex;
  const wrapper =
    ScheduleStore.editingGroupedSchedule.device_schedule_wrappers[index];

  const openDeviceSchedule = wrapper.controls.common_on;
  const openTime =
    openDeviceSchedule && openDeviceSchedule.every_time
      ? time2date(openDeviceSchedule.every_time)
      : "";

  const closeDeviceSchedule = wrapper.controls.common_off;

  const closeTime =
    closeDeviceSchedule && closeDeviceSchedule.every_time
      ? time2date(closeDeviceSchedule.every_time)
      : "";

  const selectedDevices = wrapper.device_scopes;

  const onACSelected = (scopes) => {
    console.log(scopes)

    ScheduleStore.setACForDeviceSchedule(
      activeDeviceScheduleWrapperIndex,
      scopes
    );
  };

  let devices = flattenDevices(includeGroup ? DeviceStore.building : serverRoom).filter(x => x.device_type_id == 1);

  if (includeGroup && !DeviceStore.buildingFirstTime) {
    devices = [
      DeviceStore.building,
      ...(DeviceStore.building.is_two_level ? {} : DeviceStore.building.children),
      ...DeviceStore.rooms,
      ...devices,
    ]
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 3fr 6fr 1fr",
        gridTemplateAreas: active ? `"a b c d" "e e e f"` : `"a b c d"`,
        gap: 16,
        alignItems: "center",
        width: "100%",
        borderRadius: 32,
        padding: 16,
        backgroundColor: active ? GRAY_BACKGROUND_COLOR : "black",
      }}
      onClick={onClick}
    >
      <div style={{ gridArea: "a", justifySelf: "center" }}>
        <Text>{index + 1}</Text>
      </div>
      <div style={{ gridArea: "b", paddingLeft: 16, paddingRight: 16 }}>
        <MultipleACSelect
          style={{ backgroundColor: active ? "#535253" : "black" }}
          selected={selectedDevices}
          options={devices}
          onChange={onACSelected}
        />
      </div>
      <div
        style={{
          gridArea: "c",
          ...FLEX_ROW_CENTER,
          justifyContent: "space-evenly",
          gap: 16,
          width: "80%",
          justifySelf: "center",
        }}
      >
        <TimePicker
          value={openTime}
          ampm={false}
          onChange={(t) => {
            const timeStr = t ? formatTime(t) : "";
            ScheduleStore.setOpenTime(
              activeDeviceScheduleWrapperIndex,
              timeStr,
              includeGroup,
            );
          }}
          renderInput={(params) => (
            <TextField
              style={{
                backgroundColor: "#535253",
                borderRadius: 32,
              }}
              {...params}
              InputProps={{
                endAdornment: (
                  <img src="/img/common/arrow-down.svg" width={10} />
                ),
              }}
            />
          )}
          clearable={true}
        />
        <Text>to</Text>
        <TimePicker
          value={closeTime}
          ampm={false}
          onChange={(t) => {
            const timeStr = t ? formatTime(t) : "";
            ScheduleStore.setCloseTime(
              activeDeviceScheduleWrapperIndex,
              timeStr
            );
          }}
          renderInput={(params) => (
            <TextField
              style={{
                backgroundColor: "#535253",
                borderRadius: 32,
              }}
              {...params}
              InputProps={{
                endAdornment: (
                  <img src="/img/common/arrow-down.svg" width={10} />
                ),
              }}
            />
          )}
          clearable={true}
        />

        <SwitchButton
          onClick={(x) => ScheduleStore.toggleWrapperStatus(index)}
          active={wrapper.status == "active"}
        />
        {/* <img
          src="/img/control/air/power.svg"
          onClick={() => ScheduleStore.setWrapperStatus()}
        ></img> */}
      </div>
      <div style={{ gridArea: "d", justifySelf: "end", marginRight: 16 }}>
        {active &&
          <img
            src="/img/common/save.svg"
            width="16px"
            onClick={() => patchSchedule()}
          ></img>
        }
      </div>
      {active && (
        <>
          <div style={{ width: "80%", gridArea: "e", justifySelf: "end" }}>
            <ScheduleACControlCard key={wrapper.id} includeGroup={includeGroup} />
          </div>
          <DeleteButton
            style={{ gridArea: "f", justifySelf: "end", marginRight: 16 }}
            color="red"
            onClick={() => deleteScheduleWrapper()}
          />
        </>
      )}
    </div>
  );
});
