import { makeAutoObservable } from "mobx";

export class ServerRoomStoreClass {
  serverRoom: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  setServerRoom(serverRoom: any) {
    this.serverRoom = serverRoom
  }
}

export const ServerRoomStore = new ServerRoomStoreClass();
