import Slider from "rc-slider";
import {
  FLEX_COLUMN_CENTER_CENTER,
  FLEX_ROW_CENTER_CENTER,
  GRAY_BACKGROUND_COLOR,
} from "../../theme/style";
import ControlButton from "../control/ControlButton";
import { api } from "../../utils/api";
import { deviceTypeId2controlEntity } from "../../utils/device";
import { globalLoadingFn } from "../../utils/loading";

export default function ACTempControlButton({
  scopeType,
  scopeId,
  device,
  refreshData,
  overrideOnControl,
  deviceStatus,
  deviceTemp,
  deviceControl,
  onTempChange,
  size,
  style = {},
}) {
  const commonParameter = {
    scopeType,
    scopeId,
    device,
    refreshData,
    overrideOnControl,
  };
  const off = !parseInt(deviceStatus);

  const currentTempFontSize = size * 24;
  const fontSize = size * 12;

  return (
    <div
      style={{
        ...FLEX_COLUMN_CENTER_CENTER,
        marginBottom: 4,
        backgroundColor: GRAY_BACKGROUND_COLOR,
        borderRadius: 12,
        padding: 16,
        paddingTop: 8,
        position: "relative",
        ...style,
      }}
    >
      <div
        className={off ? "common-inactive" : ""}
        style={{ ...FLEX_ROW_CENTER_CENTER }}
      >
        <ControlButton
          {...commonParameter}
          controlKey="device_temp"
          controlValueFn={(_) => Math.max(16, deviceTemp - 1)}
          image={"/img/control/air/temp-minus"}
          noBorder
          padding={2}
          active={false}
          onControl={() => onTempChange(deviceTemp - 1)}
        ></ControlButton>

        <div
          style={{
            marginLeft: 8,
            marginRight: 8,
            fontSize: currentTempFontSize,
          }}
        >
          {deviceTemp} {"\u00b0C"}
        </div>

        <ControlButton
          {...commonParameter}
          controlKey="device_temp"
          controlValueFn={(_) => Math.min(33, deviceTemp + 1)}
          image={"/img/control/air/temp-plus"}
          noBorder
          padding={2}
          active={false}
          onControl={() => onTempChange(deviceTemp + 1)}
        ></ControlButton>
      </div>

      <div
        className={off ? "common-inactive" : ""}
        style={{ ...FLEX_ROW_CENTER_CENTER, width: "100%" }}
      >
        <div
          style={{
            flexShrink: 1,
            textAlign: "center",
            fontSize: fontSize,
            width: "20%",
          }}
        >
          16{"\u00b0C"}
        </div>

        <Slider
          min={16}
          max={33}
          value={deviceTemp}
          trackStyle={{
            background: "#57d9ff",
            height: "10px",
            width: "55%",
          }}
          railStyle={{
            background: "gray",
            height: "10px",
          }}
          handleStyle={{
            height: "20px",
            width: "20px",
          }}
          onChange={(value) => onTempChange(value)}
          onAfterChange={async (value) => {
            if (!overrideOnControl) {
              await globalLoadingFn(() =>
                api.patch(
                  `/devices/${deviceTypeId2controlEntity(
                    deviceControl.device_type_id
                  )}?scope_type=${scopeType}&scope_id=${scopeId}`,
                  { device_temp: value }
                )
              );
            }

            refreshData();
          }}
        />

        <div
          style={{
            flexShrink: 1,
            textAlign: "center",
            fontSize: fontSize,
            width: "20%",
          }}
        >
          33{"\u00b0C"}
        </div>
      </div>
    </div>
  );
}
