import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { DeviceStore } from "../../stores/DeviceStore";
import { BLACK_CARD, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { api } from "../../utils/api";
import { ACTIVE_BUILDING_ID, ACTIVE_ROOM_ID } from "../../utils/globalState";
import { globalLoadingFn } from "../../utils/loading";
import BlackSelect from "../common/BlackSelect";

export default observer(function AppSettingPage() {
  const [buildings, setBuildings] = useState<any[]>([]);

  async function fetchBuildings() {
    let response = await globalLoadingFn(() => api.get("/buildings"));
    setBuildings(response.data);
  }

  useEffect(() => {
    fetchBuildings();
  }, []);

  return (
    <div style={{ ...FLEX_ROW_CENTER_CENTER, height: "100%" }}>
      <div style={{ ...BLACK_CARD, width: 500 }}>
        <div
          style={{
            ...FLEX_ROW_CENTER_CENTER,
            fontSize: "2rem",
            marginBottom: 24,
          }}
        >
          เลือกอาคาร และห้อง
        </div>

        <div style={{ marginBottom: 16 }}>
          <div>
            <BlackSelect
              onChange={(buildingId) => {
                window.localStorage.setItem("ACTIVE_BUILDING_ID", buildingId);
                window.localStorage.removeItem("ACTIVE_ROOM_ID");
                window.location.reload();
              }}
              value={ACTIVE_BUILDING_ID}
              options={buildings.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              optional={true}
              sx={{ width: "calc(100% - 16px)" }}
            ></BlackSelect>
          </div>

          <div>
            <BlackSelect
              onChange={(roomId) => {
                window.localStorage.setItem("ACTIVE_ROOM_ID", roomId);
                window.location.reload();
              }}
              value={ACTIVE_ROOM_ID}
              options={DeviceStore.rooms.map((x) => ({
                value: x.id,
                label: x.name,
              }))}
              optional={true}
              sx={{ width: "calc(100% - 16px)" }}
            ></BlackSelect>
          </div>
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
          }}

          onClick={() => {
            window.location.href = "/";
          }}
        >
          Next
        </div>
      </div>
    </div>
  );
});
