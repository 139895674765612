import { observer } from "mobx-react-lite";
import { cloneDeep } from "lodash";
import { DeviceStore, fetchBuilding } from "../../../stores/DeviceStore";
import { ScheduleStore } from "../../../stores/ScheduleStore";
import { ServerRoomStore } from "../../../stores/ServerRoomStore";
import { FLEX_ROW_CENTER } from "../../../theme/style";
import ACFanControlButton from "../../ac/ACFanControlButton";
import ACModeControlButton from "../../ac/ACModeControlButton";
import ACSwingControlButton from "../../ac/ACSwingControlButton";
import ACTempControlButton from "../../ac/ACTempControlButton";

export default observer<any>(function ScheduleACControlCard({ includeGroup }) {
  const wrapperIndex = ScheduleStore.activeDeviceScheduleWrapperIndex;
  const deviceScheduleWrapper =
    ScheduleStore.editingGroupedSchedule.device_schedule_wrappers[wrapperIndex];
  const openDeviceSchedule = deviceScheduleWrapper.controls.common_on;

  const controlData = openDeviceSchedule
    ? openDeviceSchedule.control_data
    : {
        ...cloneDeep(includeGroup ? DeviceStore.devices.find(x => x.device_control_air) : ServerRoomStore.serverRoom.children[0].device_control_air),
        device_status: "0",
      };
  const deviceId = controlData.device_id;

  return (
    <div
      style={{
        display: "grid",
        gridTemplateAreas: `"a b" "a c"`,
        gridTemplateColumns: "1fr 1fr",
        columnGap: 16,
      }}
    >
      <ACTempControlButton
        scopeType="device"
        scopeId={deviceId}
        device={deviceScheduleWrapper}
        refreshData={() => {
          fetchBuilding();
        }}
        overrideOnControl={true}
        deviceStatus={controlData.device_status}
        deviceTemp={controlData.device_temp || 25}
        deviceControl={controlData}
        onTempChange={(t) => ScheduleStore.setACTemp(wrapperIndex, t)}
        style={{ backgroundColor: "#3c3d3c", borderRadius: 32, gridArea: "a" }}
        size={1}
      />
      <ACModeControlButton
        scopeType="device"
        scopeId={deviceId}
        device={deviceScheduleWrapper}
        refreshData={() => {
          fetchBuilding();
        }}
        overrideOnControl={true}
        deviceStatus={controlData.device_status}
        deviceMode={controlData.device_mode || "1"}
        onModeChange={(t) => ScheduleStore.setACMode(wrapperIndex, t)}
        onStatusChange={(t) => {
          ScheduleStore.setACStatus(wrapperIndex, t);
        }}
        style={{ gridArea: "b" }}
        size={1.2}
      />
      <div style={{ ...FLEX_ROW_CENTER, gridArea: "c" }}>
        <ACSwingControlButton
          scopeType="device"
          scopeId={deviceId}
          device={deviceScheduleWrapper}
          refreshData={() => {
            fetchBuilding();
          }}
          overrideOnControl={true}
          deviceStatus={controlData.device_status}
          deviceSwingMode={controlData.device_swing_mode || 1}
          onSwingModeChange={(t) =>
            ScheduleStore.setACSwingMode(wrapperIndex, t)
          }
          backgroundColor="#3c3d3c"
          size={1}
        />

        <ACFanControlButton
          scopeType="device"
          scopeId={deviceId}
          device={deviceScheduleWrapper}
          refreshData={() => {
            fetchBuilding();
          }}
          overrideOnControl={true}
          deviceFanSpeed={controlData.device_fan_speed || "3"}
          onFanSpeedChange={(t) => ScheduleStore.setACFanSpeed(wrapperIndex, t)}
          backgroundColor="#3c3d3c"
          size={1}
        />
      </div>
    </div>
  );
});
