import { Button, Tab, Tabs } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  fetchSchedules,
  newSchedule,
  ScheduleStore,
} from "../../stores/ScheduleStore";
import { ServerRoomStore } from "../../stores/ServerRoomStore";
import {
  FLEX_COLUMN_START,
  FLEX_COLUMN_START_CENTER,
  FLEX_ROW_CENTER_CENTER,
} from "../../theme/style";
import TabPanel from "../common/TabPanel";
import Text from "../common/Text";
import { DeviceStore } from "../../stores/DeviceStore";
import { ACTIVE_BUILDING_ID } from "../../utils/globalState";
import "./ScheduleBuildingPage.css";
import ScheduleForm from "../serverroom/schedule/ScheduleForm";
import { RouterStore } from "../../stores/RouterStore";
import HeaderCommon from "../common/HeaderCommon";

type ScheduleFormPageProps = {
  onBackToDetail: () => {};
};

export default observer(function ScheduleBuildingPage() {
  useEffect(() => {
    fetchSchedules(ACTIVE_BUILDING_ID, 'building');
  }, []);

  const schedules = ScheduleStore.originalGroupedSchedules;

  const handleChange = (_, newValue) => {
    ScheduleStore.setActiveDeviceScheduleWrapperIndex(0);
    ScheduleStore.setActiveScheduleIndex(newValue);
    ScheduleStore.loadGroupedScheduleForEdit(newValue);
  };

  const addSchedule = () => {
    newSchedule();
  };

  const orientation =
    window.innerHeight < window.innerWidth ? "landscape" : "portrait";

  return (
    <div
      style={{
        ...FLEX_COLUMN_START,
        width: "100%",
        height: "100%",
      }}
    >
      <HeaderCommon title="Schedule"></HeaderCommon>

      <div style={{ padding: 24 }}>
        <div className="schedule-form-page__container">
          <div
            style={{
              ...FLEX_COLUMN_START_CENTER,
              height: "100%",
              flexGrow: 0,
              flexBasis: "15%",
            }}
          >
            <Tabs
              value={ScheduleStore.activeScheduleIndex}
              onChange={handleChange}
              orientation="vertical"
            >
              {schedules.map((s, index) => {
                return <Tab value={index} key={index} label={s.name} />;
              })}
            </Tabs>
            <Button onClick={addSchedule}>
              <img src="/img/common/plus-white.svg" width={16} />
            </Button>
          </div>
          {schedules.map((s, index) => {
            return (
              <TabPanel
                value={ScheduleStore.activeScheduleIndex}
                index={index}
                key={index}
                boxStyle={{ height: orientation == "landscape" ? 600 : 1000 }}
              >
                <ScheduleForm key={s.id} includeGroup={true} />
              </TabPanel>
            );
          })}
        </div>
      </div>
    </div>
  );
});
