import React, { useEffect, useState } from "react";
import { BLACK_CARD, FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { api } from "../../utils/api";
import { deviceTypeId2controlEntity } from "../../utils/device";
import { globalLoadingFn } from "../../utils/loading";
import ControlButton from "./ControlButton";
import Slider, { Range } from "rc-slider";
import useInterval from "../../hooks/useInterval";

function getTimerText(timer_schedule_due) {
  let seconds = Math.floor(timer_schedule_due / 1000);

  if (seconds < 0) return "00 : 00"

  let hours = Math.floor(seconds/3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds/60);
  seconds %= 60;

  let hoursStr = hours.toString().padStart(2, '0')
  let minutesStr = minutes.toString().padStart(2, '0')
  let secondsStr = seconds.toString().padStart(2, '0')

  if (!hours) return minutesStr + " : " + secondsStr;

  return hoursStr + " : " + minutesStr + " : " + secondsStr;
}

export default function AirControlPanel({
  scopeType,
  scopeId,
  device,
  refreshData,
  showType = 0,
  onControl = (device, controlKey, controlValue) => {},
  overrideOnControl = false,
  ...props
}) {
  const commonParameter = {
    scopeType,
    scopeId,
    device,
    refreshData,
    onControl,
    overrideOnControl,
  };

  const [deviceTemp, setDeviceTemp] = useState(
    device.device_control_air.device_temp
  );

  useEffect(() => {
    setDeviceTemp(device.device_control_air.device_temp);
  }, [device.device_control_air.device_temp]);

  const off = !parseInt(device.device_control_air.device_status);

  const [timerScheduleDue, setTimerScheduleDue] = useState(new Date(device.device_control_air.timer_schedule_due).getTime() - Date.now());
  const [timerText, setTimerText] = useState('');

  useInterval(() => {
    const due = new Date(device.device_control_air.timer_schedule_due).getTime() - Date.now();
    if (due > 0) {
      setTimerText(getTimerText(due));
    }
    setTimerScheduleDue(due);
  }, 1000);

  return (
    <div style={{ display: "flex" }}>
      {timerScheduleDue > 0 &&
        <div style={{ ...BLACK_CARD, flexDirection: "column", alignItems: "center" }}>
          <div>{timerText} นาที</div>
          <div
            style={{ backgroundColor: '', borderRadius: 30, padding: '24px 8px' }}
            onClick={async () => {
              let new_duration: any = 0;
              if (new_duration !== null) {
                new_duration = parseFloat(new_duration) * 60;
                await globalLoadingFn(() => api.post("/devices/device_control_air/timer?scope_type=" + scopeType + "&scope_id=" + scopeId, {
                  new_duration
                }))
              }
              refreshData();
              setTimerScheduleDue(0);
            }}
          >
            Stop
          </div>
        </div>
      }

      <div>
        {/* TEMP PANEL */}
        <div
          style={{
            marginBottom: 4,
            backgroundColor: "black",
            borderRadius: 12,
            padding: 12,
            paddingTop: 8,
            position: "relative",
          }}
        >
          <div
            className={off ? "common-inactive" : ""}
            style={{ ...FLEX_ROW_CENTER_CENTER }}
          >
            <ControlButton
              {...commonParameter}
              controlKey="device_temp"
              controlValueFn={(device) =>
                Math.max(16, device.device_control_air.device_temp - 1)
              }
              image={"/img/control/air/temp-minus"}
              noBorder
              padding={2}
              active={false}
            ></ControlButton>

            <div style={{ marginLeft: 8, marginRight: 8, fontSize: 28 }}>
              {device.device_control_air.device_temp} ℃
            </div>

            <ControlButton
              {...commonParameter}
              controlKey="device_temp"
              controlValueFn={(device) =>
                Math.min(33, device.device_control_air.device_temp + 1)
              }
              image={"/img/control/air/temp-plus"}
              noBorder
              padding={2}
              active={false}
            ></ControlButton>
          </div>

          <div
            className={off ? "common-inactive" : ""}
            style={{ ...FLEX_ROW_CENTER }}
          >
            <div
              style={{
                flexBasis: 45,
                flexShrink: 0,
                textAlign: "center",
                marginRight: 8,
              }}
            >
              16 ℃
            </div>

            <Slider
              min={16}
              max={33}
              value={deviceTemp}
              onChange={(value) => setDeviceTemp(value)}
              onAfterChange={async (value) => {
                console.log(value);

                if (!overrideOnControl) {
                  await globalLoadingFn(() =>
                    api.patch(
                      `/devices/${deviceTypeId2controlEntity(
                        device.device_type_id
                      )}?scope_type=${scopeType}&scope_id=${scopeId}`,
                      { device_temp: value }
                    )
                  );
                }

                onControl(device, "device_temp", value);

                refreshData();
              }}
            />

            <div
              style={{
                flexBasis: 45,
                flexShrink: 0,
                textAlign: "center",
                marginLeft: 8,
              }}
            >
              33 ℃
            </div>
          </div>
        </div>

        {/* MODE PANEL */}
        <div style={{ ...FLEX_ROW_CENTER }}>
          <div
            className={off ? "common-inactive" : ""}
            style={{ ...FLEX_ROW_CENTER, flexGrow: 1 }}
          >
            <div style={{ marginRight: 8, marginLeft: 8 }}>Mode</div>

            <ControlButton
              {...commonParameter}
              controlKey="device_mode"
              controlValueFn={(device) => "1"}
              image={"/img/control/air/mode-cool"}
              active={device.device_control_air.device_mode == "1"}
            ></ControlButton>

            <ControlButton
              {...commonParameter}
              controlKey="device_mode"
              controlValueFn={(device) => "0"}
              image={"/img/control/air/mode-fan"}
              active={device.device_control_air.device_mode == "0"}
            ></ControlButton>

            <ControlButton
              {...commonParameter}
              controlKey="device_mode"
              controlValueFn={(device) => "2"}
              image={"/img/control/air/mode-dry"}
              active={device.device_control_air.device_mode == "2"}
            ></ControlButton>

            <ControlButton
              {...commonParameter}
              controlKey="timer_schedule_wrapper_id"
              controlValueFn={(device) => "0"}
              overrideOnControl={true}
              onControl={async () => {
                let new_duration: any = prompt("กรุณาใส่จำนวนนาที (ใส่ 0 เพื่อยกเลิกการตั้งเวลา)");
                if (new_duration !== null) {
                  new_duration = parseFloat(new_duration) * 60;
                  await globalLoadingFn(() => api.post("/devices/device_control_air/timer?scope_type=" + scopeType + "&scope_id=" + scopeId, {
                    new_duration
                  }))
                }
                refreshData();
                setTimerScheduleDue(new_duration * 1000);
              }}
              image={"/img/control/air/timer"}
              active={timerScheduleDue > 0}
            ></ControlButton>
          </div>

          <div style={{ paddingRight: 12 }}>
            <ControlButton
              {...commonParameter}
              controlKey="device_status"
              controlValueFn={(device) =>
                device.device_control_air.device_status == 1 ? 0 : 1
              }
              image={`/img/control/air/power`}
              noBorder
              padding={2}
              active={device.device_control_air.device_status == 1}
            ></ControlButton>
          </div>
        </div>

        {/* BOTTOM PANEL */}
        <div
          className={off ? "common-inactive" : ""}
          style={{ ...FLEX_ROW_CENTER }}
        >
          <ControlButton
            {...commonParameter}
            controlKey="device_swing_mode"
            controlValueFn={(device) =>
              parseInt(device.device_control_air.device_swing_mode) ? "0" : "1"
            }
            image={"/img/control/air/swing"}
            label={"Swing"}
            active={device.device_control_air.device_swing_mode == "1"}
            padding={12}
          ></ControlButton>

          <ControlButton
            {...commonParameter}
            controlKey="device_fan_speed"
            controlValueFn={(device) => {
              switch (device.device_control_air.device_fan_speed.toString()) {
                case "3":
                  return "4";
                case "4":
                  return "5";
                case "5":
                  return "6";
                case "6":
                  return "7";
                case "7":
                  return "3";
                default:
                  return "3";
              }
            }}
            image={"/img/control/air/mode-fan"}
            label={"Fan"}
            active={false}
            padding={12}
          >
            <div style={{ marginLeft: 16 }}>
              <img
                src={`/img/control/air/fan-${
                  parseInt(device.device_control_air.device_fan_speed) - 2 || 0
                }.svg`}
              ></img>
            </div>
          </ControlButton>
        </div>
      </div>
    </div>
  );
}
