import React from "react";
import { FLEX_LR_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import Modal from "../Modal";

const BORDER_RADIUS = 20;

export function DialogTitle({ hasClose, onClose, children }) {
  return (
    <div
      style={{
        ...FLEX_LR_CENTER,
        paddingTop: 12,
        paddingLeft: 20,
        borderTopLeftRadius: BORDER_RADIUS,
        borderTopRightRadius: BORDER_RADIUS,
        backgroundColor: "black",
        // border: "1px solid white",
      }}
    >
      <div style={{ padding: 12 }}>
        {typeof children === "string" ? (
          <div style={{ fontWeight: "bold" }}>{children}</div>
        ) : (
          { children }
        )}
      </div>

      {hasClose ? (
        <div
          style={{ ...FLEX_ROW_CENTER_CENTER, padding: 12, paddingRight: 20 }}
          onClick={onClose}
        >
          <div>X</div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default function Dialog({
  show,
  title,
  hasClose = false,
  onClose = () => {},
  shouldCloseOnOverlayClick = false,
  ...props
}) {
  return (
    <Modal
      show={show}
      {...props}
      style={{ width: undefined, borderRadius: 8, ...(props.style || {}) }}
      onBackdropClick={() => {
        if (shouldCloseOnOverlayClick) {
          onClose();
        }
      }}
    >
      <DialogTitle hasClose={hasClose} onClose={onClose}>
        {title}
      </DialogTitle>

      <div className="dialog-content">
        <div
          style={{
            backgroundColor: "black",
            borderBottomLeftRadius: BORDER_RADIUS,
            borderBottomRightRadius: BORDER_RADIUS,
          }}
        >
          {props.children}
        </div>
      </div>
    </Modal>
  );
}
