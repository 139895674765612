import { SensorStore } from "../../stores/SensorStore";
import { MAIN_TEXT_COLOR } from "../../theme/style";
import { countDevices } from "../../utils/device";
import BlackSelect from "../common/BlackSelect";
import Text from "../common/Text";
import DeviceSummary from "../device-list/DeviceSummary";
import ServerRoomSensorBar from "../serverroom/ServerRoomSensorBar";
import "./ServerRoomHome.css";
import ServerRoomList from "./ServerRoomList";

export default function ServerRoomHome({ serverRooms, onSelectRoom }) {
  const { lastTemperature, lastHumidity, lastPM2_5, lastCO2 } =
    SensorStore.getSensorValue();

  const devices = countDevices(serverRooms, 1);

  return (
    <div className="server-room-home__container">
      <div className="server-room-home__settings">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "3fr 2fr",
            alignItems: "center",
            gap: 16,
          }}
        >
          <Text className="server-room-home__title" color={MAIN_TEXT_COLOR}>
            คุณภาพอากาศภายใน
          </Text>
          {/* <BlackSelect
            options={[
              { value: 1, label: "ทั้งหมด" },
              { value: 2, label: "ชั้น1" },
              { value: 3, label: "ชั้น2" },
            ]}
            value={1}
            onChange={(x) => {}}
            style={{
              paddingTop: 0,
              paddingBottom: 16,
              paddingLeft: 16,
              paddingRight: 16,
              borderRadius: 16,
              marginLeft: "auto",
            }}
            disableUnderline={true}
            minWidth={100}
          ></BlackSelect> */}
        </div>
      </div>
      <div className="server-room-home__device-summary">
        <DeviceSummary devices={devices} />
      </div>
      <div className="server-room-home__sensor">
        <ServerRoomSensorBar
          temp={lastTemperature}
          humidity={lastHumidity}
          aqi={lastPM2_5}
          co2={lastCO2}
          placeLabel="อาคาร"
          grayscale={false}
        ></ServerRoomSensorBar>
      </div>
      <div className="server-room-home__server-room-list">
        <ServerRoomList serverRooms={serverRooms} onSelectRoom={onSelectRoom} />
      </div>
    </div>
  );
}
