import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import FloorDevices from './components/device-list/FloorDevices';
import logo from './logo.svg';
import RootRouter from './RootRouter';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import "./carousel.css"
// import './App.css';

function App() {
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: 'dark',
        },
      }),
    [],
  );

  return (
    <LocalizationProvider dateAdapter={DateAdapter}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <RootRouter></RootRouter>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

export default App;
