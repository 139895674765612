import { GRAY_BACKGROUND_COLOR } from "../../theme/style";
import ControlButton from "../control/ControlButton";

export default function ACSwingControlButton({
  scopeType,
  scopeId,
  device,
  refreshData,
  overrideOnControl,
  deviceStatus,
  deviceSwingMode,
  onSwingModeChange,
  size,
  backgroundColor = GRAY_BACKGROUND_COLOR,
}) {
  const commonParameter = {
    scopeType,
    scopeId,
    device,
    refreshData,
    overrideOnControl,
  };

  return (
    <ControlButton
      {...commonParameter}
      controlKey="device_swing_mode"
      controlValueFn={(_) => (parseInt(deviceSwingMode) ? "0" : "1")}
      image={"/img/control/air/swing"}
      label={"Swing"}
      active={deviceStatus == 1 && deviceSwingMode == "1"}
      padding={16}
      size={size}
      width="100%"
      backgroundColor={backgroundColor}
      onControl={() => onSwingModeChange(parseInt(deviceSwingMode) ? "0" : "1")}
    ></ControlButton>
  );
}
