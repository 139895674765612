import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { DeviceStore } from "../../stores/DeviceStore";
import { RouterStore } from "../../stores/RouterStore";
import { BLACK_CARD, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { api } from "../../utils/api";
import { ACTIVE_BUILDING_ID, ACTIVE_ROOM_ID } from "../../utils/globalState";
import { globalLoadingFn, Loading } from "../../utils/loading";
import BlackSelect from "../common/BlackSelect";
import { logout } from "./LoginPage";

export default observer(function AccountPage() {
  const [me, setMe] = useState<any>({});
  const [loading, setLoading] = useState(true);

  async function fetchMe() {
    let response = await globalLoadingFn(() => api.get("/me"));
    setMe(response.data);
    setLoading(false);
  }

  useEffect(() => {
    fetchMe();
  }, []);

  if (loading) {
    return <Loading></Loading>
  }

  return (
    <div style={{ ...FLEX_ROW_CENTER_CENTER, height: "100%" }}>
      <div style={{ ...BLACK_CARD, width: 500 }}>
        <div style={{ textAlign: 'center', marginBottom: 16 }}>
          <img src={me.profile.avatar} width={200} style={{ borderRadius: '50%' }} ></img>
        </div>

        <div style={{ textAlign: 'center', fontSize: '1.7rem' }}>
          {me.username}
        </div>

        <div style={{ textAlign: 'center' }}>
          {me.email}
        </div>

        {!DeviceStore.buildingFirstTime && 
          <div style={{ marginTop: 16 }}>
            <div style={{ textAlign: 'center', fontSize: '1.5rem' }}>
              {DeviceStore.building.name}
            </div>

            <div style={{ textAlign: 'center' }}>
              {DeviceStore.building.permission_level >= 100 ? 'Advance' : (DeviceStore.building.permission_level >= 80 ? 'Admin' : 'User')}
            </div>
          </div>
        }

        <hr />

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            RouterStore.navigate('dashboard')
          }}
        >
          กลับสู่หน้าแรก
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            RouterStore.navigate('app-settings')
          }}
        >
          เลือกอาคาร
        </div>

        {!DeviceStore.buildingFirstTime && DeviceStore.building.permission_level >= 100 &&
          <div
            style={{
              borderRadius: 16,
              backgroundColor: 'white',
              fontSize: '1.5rem',
              color: 'black',
              width: '100%',
              padding: 12,
              textAlign: 'center',
              marginBottom: 16,
            }}

            onClick={() => {
              RouterStore.navigate('login-update-pin')
            }}
          >
            ตั้งค่า Pin
          </div>
        }

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            RouterStore.navigate('gallery-settings')
          }}
        >
          ตั้งค่าภาพหน้าแรก
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
            marginBottom: 16,
          }}

          onClick={() => {
            const width = window.prompt('ใส่ Width (ใส่ 0 เพื่อยกเลิกการตั้ง)');

            if (width) {
              if (parseFloat(width)) {
                window.localStorage.setItem("viewport_width", width);
              } else {
                window.localStorage.removeItem("viewport_width");
              }
            }
          }}
        >
          ตั้งค่า Viewport Width
        </div>

        <div
          style={{
            borderRadius: 16,
            backgroundColor: 'white',
            fontSize: '1.5rem',
            color: 'black',
            width: '100%',
            padding: 12,
            textAlign: 'center',
          }}

          onClick={() => {
            logout();
          }}
        >
          ออกจากระบบ
        </div>
      </div>
    </div>
  );
});
