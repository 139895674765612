import { observer } from "mobx-react-lite";
import { fetchBuilding } from "../../../stores/DeviceStore";
import { FLEX_ROW_CENTER } from "../../../theme/style";
import ACFanControlButton from "../../ac/ACFanControlButton";
import ACModeControlButton from "../../ac/ACModeControlButton";
import ACSwingControlButton from "../../ac/ACSwingControlButton";
import ACTempControlButton from "../../ac/ACTempControlButton";
import { InterlockStore } from "../../../stores/InterlockStore";

type InterlockACControlCardProps = {
  index: number;
  device: any;
  controlData: any;
};

export default observer(function InterlockACControlCard<
  InterlockACControlCardProps
>({ index, device, controlData }) {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateAreas: `"a b" "a c"`,
        gridTemplateColumns: "1fr 1fr",
        columnGap: 16,
      }}
    >
      <ACTempControlButton
        scopeType="device"
        scopeId={device.id}
        device={device}
        refreshData={() => {
          fetchBuilding();
        }}
        overrideOnControl={true}
        deviceStatus={controlData.device_status}
        deviceTemp={controlData.device_temp}
        deviceControl={controlData}
        onTempChange={(t) => InterlockStore.setACTemp(index, t)}
        style={{ backgroundColor: "#3c3d3c", borderRadius: 32, gridArea: "a" }}
        size={1}
      />
      <ACModeControlButton
        scopeType="device"
        scopeId={device.id}
        device={device}
        refreshData={() => {
          fetchBuilding();
        }}
        overrideOnControl={true}
        deviceStatus={controlData.device_status}
        deviceMode={controlData.device_mode}
        onModeChange={(m) => InterlockStore.setACMode(index, m)}
        onStatusChange={(s) => InterlockStore.setACStatus(index, s)}
        style={{ gridArea: "b" }}
        size={1.2}
      />
      <div style={{ ...FLEX_ROW_CENTER, gridArea: "c" }}>
        <ACSwingControlButton
          scopeType="device"
          scopeId={device.id}
          device={device}
          refreshData={() => {
            fetchBuilding();
          }}
          overrideOnControl={true}
          deviceStatus={controlData.device_status}
          deviceSwingMode={controlData.device_swing_mode}
          onSwingModeChange={(s) => InterlockStore.setACSwingMode(index, s)}
          backgroundColor="#3c3d3c"
          size={1}
        />

        <ACFanControlButton
          scopeType="device"
          scopeId={device.id}
          device={device}
          refreshData={() => {
            fetchBuilding();
          }}
          overrideOnControl={true}
          deviceFanSpeed={controlData.device_fan_speed}
          onFanSpeedChange={(s) => InterlockStore.setACFanSpeed(index, s)}
          backgroundColor="#3c3d3c"
          size={1}
        />
      </div>
    </div>
  );
});
