import React, { useEffect, useState } from "react"
import { FLEX_COLUMN_CENTER_CENTER, FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style"
import { api } from "../../utils/api"
import { deviceTypeId2controlEntity } from "../../utils/device"
import { globalLoadingFn } from "../../utils/loading"
import ControlButton from "./ControlButton"
import Slider, { Range } from 'rc-slider';
import useInterval from "../../hooks/useInterval"

export default function DoorCurtainControlPanel({
  scopeType,
  scopeId,
  device,
  refreshData,
  showType = 0,
  onControl = (device, controlKey, controlValue) => {},
  overrideOnControl = false,
  ...props
}) {
  console.log(device)

  const curtainOrDoor = device.device_type_id == 4 ? 'curtain' : 'door';

  const commonParameter = {
    scopeType, scopeId, device, refreshData, onControl, overrideOnControl
  }

  const [percent, setPercent] = useState(
    device[deviceTypeId2controlEntity(device.device_type_id)][props.isRight ? 'device_read_percent_right' : 'device_read_percent']
  );

  const [hintText, setHintText] = useState('')
  const [previousPercent, setPreviousPercent] = useState(percent);

  useEffect(() => {
    if (props.isRight) {
      setPercent(device[deviceTypeId2controlEntity(device.device_type_id)][props.isRight ? 'device_read_percent_right' : 'device_read_percent'])
    }
  }, [
    device[deviceTypeId2controlEntity(device.device_type_id)].device_percent,
    device[deviceTypeId2controlEntity(device.device_type_id)].device_percent_right,
  ])

  function generateOpeningText() {
    if (percent > previousPercent) {
      setHintText((percent * 100).toFixed(0) + '% Opening...')
    } else if (percent == previousPercent) {
      if (percent == 0) {
        setHintText('Closed')
      } else if (percent == 1) {
        setHintText('Opened')
      }
    } else {
      setHintText(((1-percent) * 100).toFixed(0) + '% Closing...')
    }

    setPreviousPercent(percent)
  }

  useInterval(generateOpeningText, 3000);
  useEffect(generateOpeningText, []);

  function setOpening(targetPercent) {
    if (targetPercent > percent) {
      setHintText((percent * 100).toFixed(0) + '% Opening...')
    } else {
      setHintText(((1-percent) * 100).toFixed(0) + '% Closing...')
    }
    
    return targetPercent;
  }

  return (
    <div>
      <div>
        <div
          style={{
            marginBottom: 4,
            backgroundColor: "black",
            borderRadius: 12,
            padding: 24,
            position: "relative",

            ...FLEX_COLUMN_CENTER_CENTER,
          }}
        >
          <div style={{...FLEX_ROW_CENTER_CENTER, fontSize: '1.5rem', marginBottom: '1.5rem'}}>
            <div>{hintText}</div>
          </div>

          <div
            style={{
              ...(showType == 0
                ? FLEX_COLUMN_CENTER_CENTER
                : FLEX_ROW_CENTER_CENTER),
              flexDirection: showType == 0 ? 'column' : 'row-reverse',
            }}
          >
            <div style={{...FLEX_ROW_CENTER_CENTER, marginBottom: showType == 0 ? '1rem' : 0}}>
              <ControlButton
                {...commonParameter}
                controlKey={props.isRight ? 'device_percent_right' : 'device_percent'}
                controlValueFn={(device) => setOpening(1)}
                image={`/img/control/${curtainOrDoor}/open-solid`}
                noBorder
                padding={2}
                size={2}
                active={false}
              ></ControlButton>

              <ControlButton
                {...commonParameter}
                controlKey={props.isRight ? 'device_percent_right' : 'device_percent'}
                controlValueFn={(device) => setOpening(0)}
                image={`/img/control/pause`}
                noBorder
                padding={2}
                size={2}
                active={false}
              ></ControlButton>

              <ControlButton
                {...commonParameter}
                controlKey={props.isRight ? 'device_percent_right' : 'device_percent'}
                controlValueFn={(device) => setOpening(0)}
                image={`/img/control/${curtainOrDoor}/close-solid`}
                noBorder
                padding={2}
                size={2}
                active={false}
              ></ControlButton>
            </div>

            <div style={{...FLEX_ROW_CENTER_CENTER, marginRight: showType != 0 ? '1rem' : 0}}>
              {[0, 0.25, 0.75, 1].map(p =>
                <ControlButton
                  {...commonParameter}
                  controlKey={props.isRight ? 'device_percent_right' : 'device_percent'}
                  controlValueFn={(device) => setOpening(p)}
                  noBorder
                  padding={2}
                  size={2}
                  active={false}
                >
                  <div style={{
                    borderRadius: '50%',
                    border: '2px solid white',
                    ...FLEX_ROW_CENTER_CENTER,
                    width: 48,
                    height: 48,
                    backgroundColor: "rgba(255, 255, 255, 0.2)",
                  }}>
                    {p * 100}%
                  </div>
                </ControlButton>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
