import { useState } from "react";
import { ServerRoomStore } from "../../stores/ServerRoomStore";
import ACStatusList from "../ac/ACStatusList";
import RoomACSettings from "./RoomACSettings";
import DetailButton from "../common/DetailButton";
import ServerRoomHeader from "./ServerRoomHeader";
import ServerRoomPlan from "./ServerRoomPlan";
import ServerRoomStatusDetailsPage from "./ServerRoomStatusDetailsPage";
import "./ServerRoomStatusPage.css";

export default function ServerRoomStatusPage({
  onUpdate,
  clearState,
  navigateToHome,
}) {
  const serverRoom = ServerRoomStore.serverRoom;
  const devices = serverRoom.children;

  const [acId, setAcId] = useState<string | null>();
  const [scheduleAndInterlockDetailed, setScheduleAndInterlockDetailed] =
    useState<boolean>(false);
  const [editRoomStatus, setEditRoomStatus] = useState<boolean>(false);

  let allACFocused = devices.reduce(function (map, obj) {
    map[obj.id.toString()] = true;
    return map;
  }, {});
  let allACUnfocused = devices.reduce(function (map, obj) {
    map[obj.id.toString()] = false;
    return map;
  }, {});

  const acChangedHandler = (id) => {
    if (scheduleAndInterlockDetailed) return;
    if (id == acId) {
      setAcId(null);
    } else {
      setAcId(id);
    }
    setEditRoomStatus(false);
  };

  const onDetailClickedHandler = () => {
    setScheduleAndInterlockDetailed(true);
    setAcId(null);
  };

  const editRoomStatusHandler = () => {
    setEditRoomStatus((prevValue) => !prevValue);
    setAcId(null);
  };

  const onBackToMain = () => {
    setEditRoomStatus(false);
  };

  const navigateToServerRoomStatus = () => {
    setScheduleAndInterlockDetailed(false);
  };

  var acFocus: Record<number, boolean> = {};
  if (acId) {
    acFocus = { ...allACUnfocused };
    acFocus[acId] = true;
  } else {
    acFocus = { ...allACFocused };
  }

  const device = devices.filter((d) => d.id.toString() == acId)[0];

  if (!scheduleAndInterlockDetailed) {
    return (
      <div>
        <ServerRoomHeader
          title="Server Room"
          beforeNavigate={clearState}
          onClickBack={navigateToHome}
          onHomeClick={navigateToHome}
        />
        <div className="server-room-status__container">
          <div className={`server-room-status__img-container`}>
            <ServerRoomPlan devices={devices} picture={serverRoom.picture} />
          </div>

          <DetailButton
            className="server-room-status__detail-button"
            onClick={onDetailClickedHandler}
          />

          <div className="server-room-status__ac-status-list">
            <ACStatusList
              devices={devices}
              onClick={acChangedHandler}
              focus={acFocus}
            />
          </div>
          <div className="server-room-status__room-ac-settings">
            <RoomACSettings
              onChange={acChangedHandler}
              device={device}
              devices={devices}
              onEditRoomStatus={editRoomStatusHandler}
              isEditServerRoom={editRoomStatus}
              onUpdate={onUpdate}
              onBackToMain={onBackToMain}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <ServerRoomStatusDetailsPage
        navigateToServerRoomStatus={navigateToServerRoomStatus}
      />
    );
  }
}
