import { observer } from "mobx-react-lite"
import React, { useState } from "react"
import { deleteScene, getScene, SceneStore, updateSceneData, updateSceneMetadata, updateSceneSchedule } from "../../stores/SceneStore"
import { BLACK_CARD, FLEX_COLUMN, FLEX_ROW_CENTER } from "../../theme/style"
import Switch from "react-switch";
import Dialog from "../common/Dialog";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/lab";
import { formatTime, time2date } from "../../utils/datetime";
import TimePickerBruno from "../common/TimePickerBruno";
import DayPicker from "../common/DayPicker";
import IconPickerCarousel from "../common/IconPickerCarousel";

const ICONS = [
  '/img/scene/preset-world.svg',
  '/img/scene/preset-eco.svg',
  '/img/scene/preset-sunny.svg',
  '/img/scene/preset-building.svg',
  '/img/scene/preset-umbrella.svg',
  '/img/scene/preset-smile-ear.svg',
  '/img/scene/preset-moon.svg',
  '/img/scene/preset-smile.svg',
  '/img/scene/preset-cool.svg',
  '/img/scene/preset-work.svg',
  '/img/scene/preset-heat.svg',
]

function SceneRow({ left, right }) {
  return (
    <div style={{ ...FLEX_ROW_CENTER, marginBottom: 12 }}>
      <div style={{ flexBasis: 90 }}>
        {left}
      </div>

      <div>
        {right}
      </div>
    </div>
  )
}

export default observer(function SceneControl() {
  const scene = SceneStore.editingScene;
  const schedule = SceneStore.editingSchedule;

  const [showEditModal, setShowEditModal] = useState(false);

  if (!scene) {
    return <div></div>
  }

  let scheduleTime = schedule ? schedule.controls.scene.every_time : '';
  const scheduleTimePart = scheduleTime.split(':').slice(0, 2);
  scheduleTime = scheduleTimePart.join(':');

  let scheduleTimeEnd = scene.schedule_end ?? '';
  const scheduleTimeEndPart = scheduleTimeEnd.split(':').slice(0, 2);
  scheduleTimeEnd = scheduleTimeEndPart.join(':');

  return (
    <div style={{ ...BLACK_CARD, width: 500 }}>
      <Dialog
        show={showEditModal}
        title={"แก้ไข Scene"}
        hasClose
        onClose={() => setShowEditModal(false)}
        width={600}
      >
        <div style={{padding: 24}}>
          <div style={{ ...FLEX_ROW_CENTER }}>
            <div style={{marginRight: 48}}>
              <IconPickerCarousel
                icons={ICONS}
                value={scene.icon}
                onChange={icon => SceneStore.setIcon(icon)}
              ></IconPickerCarousel>
            </div>
            
            <div>
              <div style={{ ...FLEX_ROW_CENTER, marginBottom: 16 }}>
                <div style={{ flexBasis: 100 }}>
                  ชื่อ
                </div>
    
                <div>
                  <TextField id="scene-name" variant="standard" value={scene.name} onChange={(e) => SceneStore.setSceneName(e.target.value)} />
                </div>
              </div>
    
              <div style={{ ...FLEX_ROW_CENTER, marginBottom: 16 }}>
                <div style={{ marginRight: 16 }}>
                  <Switch
                    onChange={async (checked) => {
                      SceneStore.setFavorite(checked);
                    }}
                    checked={scene.favorite}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#FFA726"
                  />
                </div>
    
                <div>
                  Favorite
                </div>
              </div>
            </div>
          </div>

          <div style={{ ...FLEX_ROW_CENTER, marginBottom: 16, marginTop: 12 }}>
            <div style={{ marginRight: 16 }}>
              <Switch
                onChange={async (checked) => {
                  SceneStore.setScheduleEnabled(checked);
                }}
                checked={SceneStore.editingScheduleEnabled}
                checkedIcon={false}
                uncheckedIcon={false}
                onColor="#FFA726"
              />
            </div>

            <div>
              ใช้งานเป็นช่วงเวลา
            </div>
          </div>

          {SceneStore.editingScheduleEnabled && <>
            <div style={{ ...FLEX_ROW_CENTER, marginBottom: 16 }}>
              <div style={{ flexBasis: 100 }}>
                ช่วงเวลา
              </div>

              <div>
                <TimePickerBruno
                  value={scheduleTime}
                  onChange={(time) => {
                    SceneStore.setSceneTime(time)
                  }}
                />
              </div>

              <div style={{marginLeft: 12, marginRight: 12}}>-</div>

              <div>
                <TimePickerBruno
                  value={scheduleTimeEnd}
                  onChange={(time) => {
                    SceneStore.setSceneTimeEnd(time)
                  }}
                />
              </div>
            </div>

            <div style={{marginBottom: 36}}>
              <div style={{marginBottom: 12}}>วันที่ใช้งาน</div>
              <div>
                <DayPicker value={schedule.repeat_day} onToggle={day => SceneStore.toggleDay('repeat_day', day)}></DayPicker>
              </div>
            </div>
          </>}

          <div style={{...FLEX_ROW_CENTER, justifyContent: "flex-end"}}>
            <div
              style={{
                border: "1px solid white",
                borderRadius: 16,
                padding: 12,
                width: 150,
                textAlign: "center",
                marginRight: 16.
              }}
              onClick={async () => {
                SceneStore.loadSceneForEdit(await getScene(scene.id));
                setShowEditModal(false);
              }}
            >
              Cancel
            </div>
            <div
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: 16,
                padding: 12,
                width: 150,
                textAlign: "center",
              }}
              onClick={async () => {
                await updateSceneMetadata(scene.id, {
                  name: scene.name,
                  active: scene.active,
                  favorite: scene.favorite,
                  icon: scene.icon,
                  schedule_end: scene.schedule_end,
                  refreshData: false,
                });
                await updateSceneSchedule(scene.id);
                setShowEditModal(false);
              }}
            >
              Save
            </div>
          </div>
        </div>
      </Dialog>

      <div style={{ ...FLEX_ROW_CENTER, alignItems: "stretch", width: "100%" }}>
        <div style={{ marginRight: 36 }}>
          <img src={scene.icon} width={48}></img>
        </div>

        <div style={{ width: "100%" }}>
          <SceneRow left={<div>Scene</div>} right={<div>{scene.name}</div>}></SceneRow>
          <SceneRow left={<div>ช่วงเวลา</div>} right={<div>{SceneStore.editingScheduleEnabled ? `${scheduleTime} - ${scheduleTimeEnd}` : '-'}</div>}></SceneRow>
          <SceneRow left={
            <Switch
              onChange={async (checked) => {
                await updateSceneMetadata(scene.id, {
                  name: scene.name,
                  active: checked,
                  favorite: scene.favorite,
                  icon: scene.icon,
                  schedule_end: scene.schedule_end,
                })
              }}
              checked={scene.active}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#FFA726"
            />
          } right={<div>เปิดใช้งาน</div>}></SceneRow>
        </div>

        <div style={{ ...FLEX_COLUMN, justifyContent: "space-between", alignItems: "flex-end", height: "auto" }}>
          <div style={{ ...FLEX_ROW_CENTER }}>
            <div onClick={() => {
              if (window.confirm('ยืนยันการลบ Scene ' + scene.name)) {
                deleteScene(scene.id)
              }
            }} style={{marginRight: 8}}>
              <img src={"/img/scene/delete.svg"} style={{width: 24, height: 24}}></img>
            </div>

            <div onClick={() => setShowEditModal(true)}>
              <img src={"/img/scene/edit.svg"} style={{width: 24, height: 24}}></img>
            </div>
          </div>

          <div onClick={async () => {
            SceneStore.setFavorite(!scene.favorite)
            await updateSceneMetadata(scene.id, {
              name: scene.name,
              active: scene.active,
              favorite: scene.favorite,
              icon: scene.icon,
              schedule_end: scene.schedule_end,
            });
          }}>
            <img src="/img/common/star.svg" style={{width: 24, height: 24, opacity: scene.favorite ? 1 : 0.4}}></img>
          </div>
        </div>
      </div>
    </div>
  )
})