import { makeAutoObservable } from "mobx";
import { api } from "../utils/api";
import { ACCESS_TOKEN, ACTIVE_BUILDING_ID, IS_TABLET } from "../utils/globalState";
import { globalLoadingFn } from "../utils/loading";
import { DeviceStore } from "./DeviceStore";

export const PAGE_SECTION = {
  'dashboard': 0,
  'gallery-settings': 0,
  'app-settings': 0,
  'login': 0,
  'vertical': 0,
  'schedule': 0,
  'account': 0,
  'login-pin': 0,
  'login-update-pin': 0,
  'update-pin': 0,
  'update-breaker-pin': 0,
  'update-access-pin': 0,
  'update-server-room-pin': 0,

  'control': 1,
  'control-tablet': 1,

  'vam': 2,
  
  'server-room': 3,
  'interlock': 3,

  'energy-page': 4,
}

export const PAGE_HOME = [
  'dashboard', 
  'control', 
  'vam', 
  'server-room',
  'energy-page'
]

export class RouterStoreClass {
  page: string[] = [...PAGE_HOME];
  section: number = 0;
  enableChangeSectionVariable: boolean = true;
  awaitingPinPage: boolean = false;

  lastPage: string = 'dashboard';

  controlPageTabMode: string = 'device';

  constructor() {
    makeAutoObservable(this);
  }

  enableChangeSection() {
    this.enableChangeSectionVariable = true;
  }

  disableChangeSection() {
    this.enableChangeSectionVariable = false;
  }

  changeSection(section: number) {
    this.section = section;
  }

  navigate(page: string) {
    this.section = PAGE_SECTION[page] ?? this.section;
    this.page[this.section] = page;
  }

  home() {
    if (this.page[this.section] == PAGE_HOME[this.section]) {
      this.navigate(PAGE_HOME[0]);
    } else {
      this.navigate(PAGE_HOME[this.section]);
    }
  }

  setControlPageTabMode(tabMode) {
    this.controlPageTabMode = tabMode;
  }

  setLastPage(lastPage) {
    this.lastPage = lastPage;
  }

  setLastPageToCurrent() {
    let lastPage = this.page[this.section];
    if (lastPage != 'login-pin') {
      this.lastPage = lastPage;
    }
  }

  navigateToLastPage() {
    this.page[0] = 'dashboard';
    this.navigate(this.lastPage);
  }

  isSwipeable(page: string): boolean {
    return !page.endsWith('pin');
  }

  setAwaitingPinPage(awaitingPinPage: boolean) {
    this.awaitingPinPage = awaitingPinPage;
  }
}

export const RouterStore = new RouterStoreClass();

let defaultPage = new URLSearchParams(window.location.search).get('page');
// if (defaultPage) {
//   RouterStore.navigate(defaultPage);
// }

// Check if not logged in then redirect
if (!ACCESS_TOKEN) {
  RouterStore.navigate('login');
} else {
  if (defaultPage == 'app-settings') {
    RouterStore.navigate(defaultPage);
  }

  if (defaultPage) {
    RouterStore.setLastPage(defaultPage);
  }

  let pinTimeout = Date.now();

  const resetLoginPinTimeout = function() {
    pinTimeout = Date.now();
  }

  document.addEventListener('mouseenter', resetLoginPinTimeout);
  document.addEventListener('mouseleave', resetLoginPinTimeout);
  document.addEventListener('mousemove', resetLoginPinTimeout);
  document.addEventListener('touchstart', resetLoginPinTimeout);
  document.addEventListener('touchmove', resetLoginPinTimeout);
  document.addEventListener('touchend', resetLoginPinTimeout);

  setInterval(() => {
    if (Date.now() >= pinTimeout + 30 * 1000) {
      if (DeviceStore.hasAccessPin && !RouterStore.awaitingPinPage) {
        RouterStore.setLastPageToCurrent();
        // RouterStore.navigate('login-pin');
        RouterStore.setAwaitingPinPage(true);
      }
    }
  }, 1000);

  const showPinPage = function() {
    if (RouterStore.awaitingPinPage) {
      RouterStore.navigate('login-pin');
    }
  }

  document.addEventListener('mousedown', showPinPage);
  document.addEventListener('touchstart', showPinPage);
}
