import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { GRAY_BACKGROUND_COLOR } from "../../theme/style";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function DateSelect({
  className,
  options,
  selected,
  onChange,
  style = {},
}) {
  const getLabel = (s) => options[s].label;
  const convertToLabels = (selected) => {
    if (selected.length == 7) {
      return ["ทุกวัน"];
    }

    const start = selected[0];
    const end = selected[selected.length - 1];
    if (selected.length > 1 && selected.length == end - start + 1) {
      return [`${getLabel(start)} - ${getLabel(end)}`];
    }

    return selected.map((s) => getLabel(s));
  };

  const handleChange = (event) => {
    const value = event.target.value;
    const sorted = [...value];
    sorted.sort();

    onChange(sorted);
  };

  return (
    <div
      style={{
        ...style,
        background: GRAY_BACKGROUND_COLOR,
        borderRadius: 32,
        padding: 10,
      }}
      className={className}
    >
      <FormControl sx={{ width: "100%" }}>
        <Select
          id="multiple-select"
          multiple
          value={selected}
          onChange={handleChange}
          renderValue={(s) => convertToLabels(s).join(", ")}
          MenuProps={MenuProps}
          style={{
            height: 30,
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              <Checkbox checked={selected.indexOf(option.value) > -1} />
              <ListItemText primary={option.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
