import { makeAutoObservable } from "mobx";
import { api } from "../utils/api";
import { applyDevicesFn, flattenDevices } from "../utils/device";
import { ACCESS_TOKEN, ACTIVE_BUILDING_ID, ACTIVE_ROOM_ID } from "../utils/globalState";
import { globalLoadingFn } from "../utils/loading";
import { alertErrorComplete } from "../utils/toast";
import { RouterStore } from "./RouterStore";
import { SceneStore } from "./SceneStore";
import { cloneDeep } from "lodash";

function formatServerRoom(serverRooms) {
  serverRooms = cloneDeep(serverRooms);
  
  for (let serverRoom of serverRooms) {
    if (serverRoom.name.startsWith('[SERVERROOM]')) {
      serverRoom.name = serverRoom.name.substring('[SERVERROOM]'.length);
      serverRoom.children = serverRoom.children.filter(x => x.device_type_id == 1);
    }
  }

  return serverRooms;
}

function formatDevices(device) {
  device.device_uptime_value = device.device_uptime ? device.device_uptime.uptime - device.device_uptime.maintenance_reset : 0;
  return device;
}

export class DeviceStoreClass {
  buildingRaw: any = {};
  building: any = {};
  buildingFirstTime: boolean = true;
  breaker: any;
  breakerStatus: boolean = false;

  breakerPin: string = "123456";
  accessPin: string = "123456";
  serverRoomPin: string = "123456";

  hasAccessPin: boolean = false;

  activeRoom: any = {};
  rooms: any[] = [];
  serverRooms: any[] = [];

  vams: any = [];
  devices: any = [];

  constructor() {
    makeAutoObservable(this);
  }

  setBuilding(building: any) {
    building = applyDevicesFn(building, formatDevices);
    this.buildingRaw = building;
    this.building = applyDevicesFn(building, (device) => device.is_vam ? null : device);
    this.devices = flattenDevices(building);
    this.vams = this.devices.filter(device => device.is_vam);
    this.breaker = this.devices.find(c => c.device_type_id == 6);
    if (this.breaker) {
      this.breakerStatus = this.breaker.status == 'on';
    }
    this.hasAccessPin = !!this.building.access_pin;
    this.breakerPin = this.building.breaker_pin || "123456";
    this.accessPin = this.building.access_pin || "123456";
    this.serverRoomPin = this.building.server_room_pin || "123456";

    this.serverRooms = [];
    if (this.building.is_two_level) {
      this.rooms = this.building.children;
    } else {
      this.rooms = [];
      for (let floor of this.building.children) {
        this.rooms = this.rooms.concat(floor.children);
      }
    }

    this.serverRooms = formatServerRoom(this.rooms.filter(c => c.name.startsWith('[SERVERROOM]')));

    console.log(this.serverRooms);

    if (ACTIVE_ROOM_ID) {
      this.activeRoom = this.rooms.find(x => x.id == ACTIVE_ROOM_ID);
    }

    SceneStore.updateSceneActive();

    // Pin page system
    if (this.buildingFirstTime) {
      let defaultPage = new URLSearchParams(window.location.search).get('page');

      if (defaultPage != 'app-settings') {
        if (DeviceStore.hasAccessPin) {
          RouterStore.navigate('login-pin');
        }
      }
    }

    this.buildingFirstTime = false;
  }
}

export const DeviceStore = new DeviceStoreClass();

export async function fetchBuilding({alwaysLoading = false}: any = {}) {
  if (ACCESS_TOKEN && ACTIVE_BUILDING_ID) {
    let response = await globalLoadingFn(() => api.get("/buildings/" + ACTIVE_BUILDING_ID + "?deep=1"), {firstTime: alwaysLoading || DeviceStore.buildingFirstTime});
    DeviceStore.setBuilding(response.data);

    console.log(response.data)
  }
}

fetchBuilding();

export async function toggleBreaker(pin, breaker) {
  if (pin != DeviceStore.breakerPin) {
    alertErrorComplete({message: "Pin ที่คุณกรอกไม่ถูกต้อง"});
    return;
  }

  let onoff = breaker.device_control_breaker.device_status == 0 ? "on" : "off";

  await globalLoadingFn(() =>
    api.post(
      `/devices/device_control_breaker/turn_${onoff}?scope_type=device&scope_id=${breaker.id}`
    )
  );

  fetchBuilding();

  RouterStore.home();
}

setInterval(() => {
  fetchBuilding();
}, 10000);
