import { useState } from "react";
import {
  BLACK_CARD,
  FLEX_COLUMN,
  FLEX_ROW_CENTER_CENTER,
} from "../../../theme/style";
import { api } from "../../../utils/api";
import { globalLoadingFn } from "../../../utils/loading";
import TextField from "../../common/TextField";

export default function LoginCard({ onClickForgotPassword, onAuthenticate, title = "Server Room" }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  async function performLogin() {
    let response = await globalLoadingFn(() =>
      api.post("/login_legacy", {
        email: email,
        password: password,
      })
    );

    window.localStorage.setItem("ACCESS_TOKEN", response.data.token);
    window.localStorage.setItem("USER_ID", response.data.user_id);

    onAuthenticate();
  }

  return (
    <div
      style={{
        ...BLACK_CARD,
        ...FLEX_COLUMN,
        alignItems: "center",
        justifyContent: "space-around",
        width: 500,
        height: 500,
      }}
    >
      <div
        style={{
          ...FLEX_ROW_CENTER_CENTER,
          fontSize: "2rem",
          marginBottom: 24,
        }}
      >
        {title}
      </div>

      <div style={{ width: "80%" }}>
        <TextField
          id="email"
          label="Email"
          value={email}
          onChange={setEmail}
          fullWidth
          style={{ borderRadius: 16 }}
        />
      </div>

      <div style={{ width: "80%" }}>
        <TextField
          id="password"
          label="Password"
          value={password}
          type="password"
          onChange={setPassword}
          fullWidth
          style={{ borderRadius: 16 }}
        />
      </div>

      <div
        style={{ textDecoration: "underline", textAlign: "center" }}
        onClick={onClickForgotPassword}
      >
        Forgot password?
      </div>

      <div
        style={{
          borderRadius: 16,
          backgroundColor: "#56D9FE",
          fontSize: "1.5rem",
          color: "black",
          width: "40%",
          padding: 12,
          textAlign: "center",
        }}
        onClick={performLogin}
      >
        Login
      </div>
    </div>
  );
}
