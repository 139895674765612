export default function Text(props) {
  const {
    color = "white",
    fontSize = "1.5rem",
    style = {},
    className = "",
    onClick = () => {},
  } = props;
  const s =
    className == "" ? { color: color, fontSize: fontSize, ...style } : {};
  return (
    <div style={s} onClick={onClick} className={className}>
      {props.children}
    </div>
  );
}
