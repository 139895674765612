import { TimePicker } from "@mui/lab"
import { TextField } from "@mui/material"
import React from "react"
import { formatTime, time2date } from "../../utils/datetime"

export default function TimePickerBruno({value, onChange, ...props}) {
    return (
        <TimePicker
            value={time2date(value)}
            onChange={(date) => {
                onChange(formatTime(date))
            }}
            ampm={false}
            renderInput={(params) => <TextField {...params} variant="standard" />}
            {...props}
        />
    )
}