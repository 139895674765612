import { Divider, Switch } from "@mui/material";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import {
  deleteInterlock,
  InterlockStore,
  patchInterlock,
} from "../../../stores/InterlockStore";
import { ServerRoomStore } from "../../../stores/ServerRoomStore";
import {
  FLEX_COLUMN_START_CENTER,
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER_CENTER,
  GRAY_BACKGROUND_COLOR,
} from "../../../theme/style";
import ACSelect from "../../ac/ACSelect";
import AddButton from "../../common/AddButton";
import BlackSelect from "../../common/BlackSelect";
import DeleteButton from "../../common/DeleteButton";
import NumberedTextField from "../../common/NumberedTextField";
import SaveButton from "../../common/SaveButton";
import Text from "../../common/Text";
import TextField from "../../common/TextField";
import InterlockACControl from "./InterlockACControl";

const CONDITION_OPTION = [
  { value: "STOP_WORKING", label: "หยุดทำงาน", valueRequired: false },
  { value: "START_WORKING", label: "เริ่มทำงาน", valueRequired: false },
  { value: "TEMP_GT", label: "อุณหภูมิสูงกว่า", valueRequired: true },
  { value: "TEMP_LT", label: "อุณหภูมิต่ำกว่า", valueRequired: true },
  { value: "HUMIDITY_GT", label: "ความชื้นสูงกว่า", valueRequired: true },
  { value: "HUMIDITY_LT", label: "ความชื้นต่ำกว่า", valueRequired: true },
  { value: "CO2_GT", label: "CO2 สูงกว่า", valueRequired: true },
  { value: "CO2_LT", label: "CO2 ต่ำกว่า", valueRequired: true },
  { value: "PM2_5_GT", label: "PM2.5 สูงกว่า", valueRequired: true },
  { value: "PM2_5_LT", label: "PM2.5 ต่ำกว่า", valueRequired: true },
];

const operationOptions = [
  { id: 1, name: "และ" },
  { id: 2, name: "หรือ" },
];

type SwitchProps = {
  checked: boolean;
};

const CustomSwitch = styled(Switch)<SwitchProps>(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#ffa627",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const isNumberRequired = (value) => {
  return CONDITION_OPTION.find((x) => x.value == value)?.valueRequired;
};

export default observer(function InterlockForm() {
  const { innerWidth: width, innerHeight: height } = window;
  const serverRoom = ServerRoomStore.serverRoom;
  const interlock = InterlockStore.editingInterlock;

  const onActionAdd = () => {
    InterlockStore.addAction();
    InterlockStore.setActiveActionIndex(interlock.action.length - 1);
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "3fr 8fr",
        gridTemplateRows: "min-content",
        gap: 8,
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <TextField
        id={interlock.id.toString()}
        value={interlock.name}
        onChange={(v) => InterlockStore.setName(v)}
        style={{ borderRadius: 32 }}
        fontSize="1.2rem"
      ></TextField>
      <div
        style={{
          ...FLEX_ROW_CENTER_CENTER,
          gap: 16,
          justifySelf: "end",
          marginRight: 4,
        }}
      >
        <CustomSwitch
          checked={interlock.is_active}
          onChange={(e) => InterlockStore.setActive(e.target.checked)}
        />
        <SaveButton onClick={() => patchInterlock()} />
        <DeleteButton
          onClick={() => deleteInterlock(InterlockStore.editingInterlock.id)}
        />
      </div>
      <div
        style={{
          ...FLEX_COLUMN_START_CENTER,
          gap: 16,
          width: "100%",
          borderRadius: 32,
          padding: 16,
          backgroundColor: GRAY_BACKGROUND_COLOR,
        }}
      >
        <div
          style={{
            ...FLEX_LR_CENTER,
            width: "100%",
          }}
        >
          <Text fontSize="1.2rem">ค่าที่กำหนด</Text>
          <SaveButton onClick={() => patchInterlock()} />
        </div>
        {interlock.condition.map((con, index) => {
          console.log("condition id", con);
          return (
            <div key={`condition-${index}`} style={{}}>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 5fr",
                  justifyItems: "start",
                }}
              >
                {index != 0 && (
                  <BlackSelect
                    variant="standard"
                    style={{
                      backgroundColor: GRAY_BACKGROUND_COLOR,
                    }}
                    sx={{ bgcolor: "#535253", borderRadius: 16 }}
                    value={operationOptions[0].id}
                    options={operationOptions.map((d) => {
                      return { value: d.id, label: d.name };
                    })}
                    onChange={() => {}}
                    disableUnderline={true}
                    minWidth={60}
                  />
                )}
                <ACSelect
                  device={{ id: con.device_id }}
                  options={serverRoom.children}
                  style={{
                    backgroundColor: GRAY_BACKGROUND_COLOR,
                  }}
                  minWidth={100}
                  onDeviceSelected={(value) =>
                    InterlockStore.setCondition(index, "device_id", value)
                  }
                />
              </div>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "4fr 2fr 1fr",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}
              >
                <BlackSelect
                  variant="standard"
                  style={{ backgroundColor: GRAY_BACKGROUND_COLOR }}
                  sx={{ bgcolor: "#535253", borderRadius: 16 }}
                  value={con.op}
                  options={CONDITION_OPTION}
                  onChange={(value) =>
                    InterlockStore.setCondition(index, "op", value)
                  }
                  disableUnderline={true}
                  minWidth={120}
                ></BlackSelect>

                {isNumberRequired(con.op) && (
                  <>
                    <NumberedTextField
                      value={con.value}
                      onChange={(e) =>
                        InterlockStore.setCondition(index, "value", e)
                      }
                      size={1}
                    />
                    {/* <Text>{"\u00b0C"}</Text> */}
                  </>
                )}
              </div>
              <Divider style={{ background: "#494949", margin: 12 }} />
            </div>
          );
        })}
        <AddButton
          onClick={() =>
            InterlockStore.addCondition(ServerRoomStore.serverRoom)
          }
        />
      </div>
      <div style={{ ...FLEX_COLUMN_START_CENTER, gap: 16, height: "80%" }}>
        {interlock.action.map((a, index) => {
          console.log("action id", a);
          return (
            <InterlockACControl
              key={`action-${index}`}
              position={index + 1}
              index={index}
              active={index == InterlockStore.activeActionIndex}
            />
          );
        })}
        <AddButton onClick={() => onActionAdd()} />
      </div>
    </div>
  );
});
