import React from "react"
import { FLEX_ROW_CENTER } from "../../theme/style"

export default function IconPickerCarousel({icons, width = 48, value, onChange}) {
  let currentIndex = icons.indexOf(value);
  
  return (
    <div style={{ ...FLEX_ROW_CENTER }}>
      <div 
        onClick={() => {
          let newIndex = currentIndex - 1;
          if (newIndex < 0) {
            newIndex = icons.length - 1;
          }

          onChange(icons[newIndex]);
        }}
      >◀</div>

      <div
        style={{
          margin: 16,
        }}
      >
        <img src={value} width={width}></img>
      </div>

      <div 
        onClick={() => {
          let newIndex = currentIndex + 1;
          if (newIndex >= icons.length) {
            newIndex = 0;
          }

          onChange(icons[newIndex]);
        }}
      >▶</div>
    </div>
  )
}