import React from "react";

function iconUrl(device) {
  let variant = "";

  switch (device.device_type_id) {
    case 1:
      variant = "air";
      break;
    case 3:
      variant = "light";
      break;
    case 4:
      variant = "curtain";
      break;
    case 5:
      variant = "door";
      break;
  }

  if (!variant) return "";

  return `/img/control/${variant}-solid${
    device.status == "off" ? "-grey" : ""
  }.svg`;
}

const DEFAULT_SIZE = 24;

export function MapView({
  data,
  onChangeDevice,
  roomMode = false,
  activeRoomId = 0,
  activeDeviceId = 0,
}) {
  const HEIGHT = 600;

  let rooms = roomMode
    ? [
        {
          id: 0,
          position: {
            top: 0,
            left: 0,
            width: 1,
            height: 1,
          },
          children: data.children,
        },
      ]
    : data.children;

  console.log(data);

  if (!rooms) return <div></div>;

  return (
    <div
      style={{
        height: HEIGHT,
        padding: 16,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "relative" }}>
        <img
          src={data.picture}
          style={{ maxWidth: "100%", maxHeight: HEIGHT, objectFit: "contain" }}
        ></img>

        {rooms.map((room) => (
          <div
            style={{
              position: "absolute",
              top: room.position.top * 100 + "%",
              left: room.position.left * 100 + "%",
              width: room.position.width * 100 + "%",
              height: room.position.height * 100 + "%",
              pointerEvents: "none",
            }}
          >
            <div
              style={{ position: "relative", width: "100%", height: "100%" }}
            >
              {room.children && room.children
                .filter((device) => iconUrl(device))
                .map((device) => (
                  <div
                    style={{
                      position: "absolute",
                      top: "calc(" + (device.position.top + device.position.height / 2) * 100 + "% - " + ((device.position.bruno_size || DEFAULT_SIZE) / 2) + "px)",
                      left: "calc(" + (device.position.left + device.position.width / 2) * 100 + "% - " + ((device.position.bruno_size || DEFAULT_SIZE) / 2) + "px)",
                      width: (device.position.bruno_size || DEFAULT_SIZE) + "px",
                      pointerEvents: "auto",
                    }}
                    onClick={() => onChangeDevice(device)}
                  >
                    <div
                      style={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <img
                        src={iconUrl(device)}
                        style={{
                          width: "100%",
                          borderRadius: "50%",
                          display: "flex",
                        }}
                      ></img>

                      {activeDeviceId === device.id && (
                        <div
                          style={{
                            position: "absolute",
                            top: "-5%",
                            left: "-5%",
                            width: "110%",
                            height: "110%",
                            borderRadius: "50%",
                            backgroundColor: "white",
                          }}
                        ></div>
                      )}

                      <img
                        src={iconUrl(device)}
                        style={{
                          width: "100%",
                          borderRadius: "50%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                        }}
                      ></img>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
