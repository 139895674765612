import { useState } from "react";
import {
  BLACK_CARD,
  FLEX_COLUMN,
  FLEX_COLUMN_START,
  FLEX_ROW_CENTER_CENTER,
} from "../../../theme/style";
import { api } from "../../../utils/api";
import { globalLoadingFn } from "../../../utils/loading";
import TextField from "../../common/TextField";

export default function RecoveryPasswordCard({
  onClickLogin,
  onResetPassword,
}) {
  const [email, setEmail] = useState("");
  const [otpSecret, setOtpSecret] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [otpKey, setOtpKey] = useState("");

  async function recoverPassword() {
    const sendOtpResponse = await globalLoadingFn(() =>
      api.post("/resend_otp_email/" + encodeURIComponent(email))
    );

    setOtpSecret(sendOtpResponse.data.otp_secret);
  }

  async function resetPassword() {
    const body = {
      email: email,
      otp_key: otpKey,
      password: newPassword,
      otp_secret: otpSecret,
    };
    const resetPasswordResponse = await globalLoadingFn(() =>
      api.post("/reset_password_email", body)
    );

    onResetPassword();
  }

  return (
    <div
      style={{
        ...BLACK_CARD,
        ...FLEX_COLUMN,
        alignItems: "center",
        justifyContent: "space-around",
        width: 500,
        height: 500,
      }}
    >
      <div
        style={{
          ...FLEX_ROW_CENTER_CENTER,
          fontSize: "2rem",
          marginBottom: 24,
        }}
      >
        Recover Password
      </div>

      <div style={{ ...FLEX_COLUMN_START, gap: 16, width: "80%" }}>
        <TextField
          id="email"
          label="Email"
          value={email}
          onChange={setEmail}
          fullWidth
          style={{ borderRadius: 16 }}
        />
        {otpSecret && (
          <TextField
            id="otpKey"
            label="OTP"
            value={otpKey}
            onChange={setOtpKey}
            fullWidth
            style={{ borderRadius: 16 }}
          />
        )}
        {otpSecret && (
          <TextField
            id="newPassword"
            label="New Password"
            value={newPassword}
            type="password"
            onChange={setNewPassword}
            fullWidth
            style={{ borderRadius: 16 }}
          />
        )}
      </div>

      <div
        style={{ textDecoration: "underline", textAlign: "center" }}
        onClick={onClickLogin}
      >
        Login
      </div>

      {!otpSecret ? (
        <div
          style={{
            borderRadius: 16,
            backgroundColor: "#56D9FE",
            fontSize: "1.5rem",
            color: "black",
            width: "40%",
            padding: 12,
            textAlign: "center",
          }}
          onClick={recoverPassword}
        >
          Send
        </div>
      ) : (
        <div
          style={{
            borderRadius: 16,
            backgroundColor: "#56D9FE",
            fontSize: "1.5rem",
            color: "black",
            width: "50%",
            padding: 12,
            textAlign: "center",
          }}
          onClick={resetPassword}
        >
          Reset Password
        </div>
      )}
    </div>
  );
}
