import React, { useState } from "react"
import useInterval from "../../hooks/useInterval";
import { formatLongDate, formatTime } from "../../utils/datetime";

export default function TimeWithDate() {
  const [time, setTime] = useState(Date.now());

  useInterval(() => setTime(Date.now()), 1000);

  const date = new Date();

  return (
    <div>
      <div style={{fontSize: "2.5rem", lineHeight: "3rem"}}>{formatTime(date)}</div>
      <div style={{fontSize: "1.6rem"}}>
        {formatLongDate(date)}
      </div>
    </div>
  )
}