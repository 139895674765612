import { BLACK_CARD, FLEX_ROW_START } from "../../../theme/style";
import Text from "../../common/Text";

export default function InterlockSummary({ devices }) {
  const message = devices.error > 0 ? "" : "Normal";
  const errorMessage = devices.error > 0 ? `Error ${devices.error}` : "";
  return (
    <div
      style={{
        ...FLEX_ROW_START,
        ...BLACK_CARD,
        marginLeft: "20%",
        height: "100%",
      }}
    >
      {errorMessage != "" && (
        <Text color="red">Interlock : {errorMessage}</Text>
      )}
      {message != "" && <Text>Interlock : {message}</Text>}
    </div>
  );
}
