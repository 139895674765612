import { cloneDeep } from "lodash";
import { makeAutoObservable } from "mobx";
import { api } from "../utils/api";
import { deviceTypeId2controlEntity } from "../utils/device";
import { ACCESS_TOKEN, ACTIVE_BUILDING_ID } from "../utils/globalState";
import { globalLoadingFn } from "../utils/loading";
import { ScheduleStore } from "./ScheduleStore";
import { ServerRoomStore } from "./ServerRoomStore";

export class InterlockStoreClass {
  interlocks: any = [];
  interlocksFirstTime: boolean = true;
  activeInterlockIndex: number = 0;
  activeActionIndex: number = 0;

  editingInterlock: any = {
    name: "Untitled",
    is_active: true,
    is_server_room: true,
    condition: [],
    action: [],
    building_id: ACTIVE_BUILDING_ID,
  };

  templateInterlock: any = {
    name: "Untitled",
    is_active: true,
    is_server_room: true,
    condition: [],
    action: [],
    building_id: ACTIVE_BUILDING_ID,
  };

  editingScheduleEnabled = false;

  constructor() {
    makeAutoObservable(this);
  }

  setInterlocks(interlocks: any) {
    this.interlocks = interlocks;
    this.interlocksFirstTime = false;
  }

  setActiveActionIndex(index) {
    this.activeActionIndex = index;
  }

  setActiveInterlockIndex(index) {
    this.activeInterlockIndex = index;
  }

  newInterlock(isServerRoom: boolean = true) {
    let editingInterlock = cloneDeep(this.templateInterlock);
    editingInterlock.name = "Untitled";
    editingInterlock.is_active = true;
    editingInterlock.is_server_room = isServerRoom;
    editingInterlock.condition = [];
    editingInterlock.action = [];

    return editingInterlock;
  }

  loadInterlockForEdit(interlock) {
    this.activeActionIndex = 0;
    this.editingInterlock = cloneDeep(interlock);
  }

  setName(name) {
    this.editingInterlock.name = name;
  }

  setActive(active) {
    this.editingInterlock.is_active = active;
  }

  // getControlData(device, dontpush = false) {
  //   let data = this.editingInterlock.action.find(d => d.device_id == device.id);

  //   if (!data) {
  //     data = {
  //       device_id: device.id,
  //       device_iot_id: device.device_iot_id,
  //       device_type_id: device.device_type_id,
  //       control_entity: deviceTypeId2controlEntity(device.device_type_id),
  //       data: device[deviceTypeId2controlEntity(device.device_type_id)],
  //       active: false,
  //     };

  //     if (!dontpush) {
  //       this.editingInterlock.action.push(data);
  //     }
  //   }

  //   // console.log(this.editingInterlock);

  //   return data;
  // }

  addControlData(serverRoom) {
    const device = serverRoom.children[0];

    const data = {
      device_id: device.id,
      device_iot_id: device.device_iot_id,
      device_type_id: device.device_type_id,
      control_entity: deviceTypeId2controlEntity(device.device_type_id),
      data: device[deviceTypeId2controlEntity(device.device_type_id)],
      active: false,
      time: 0,
      priority_type: "notDetect",
    };

    this.editingInterlock.action.push(data);

    return data;
  }

  removeControlData(index) {
    this.editingInterlock.action.splice(index, 1);
  }

  setControlData(index, controlKey, controlValue) {
    let data = this.editingInterlock.action[index];
    data.data[controlKey] = controlValue;
  }

  setACTemp(index, temp) {
    this.setControlData(index, "device_temp", temp);
  }

  setACMode(index, mode) {
    this.setControlData(index, "device_mode", mode);
  }

  setACStatus(index, status) {
    this.setControlData(index, "device_status", status.toString());
  }

  setACSwingMode(index, swingMode) {
    this.setControlData(index, "device_swing_mode", swingMode);
  }

  setACFanSpeed(index, fanSpeed) {
    this.setControlData(index, "device_fan_speed", fanSpeed);
  }

  setDeviceId(index, id) {
    this.editingInterlock.action[index].device_id = id;
  }

  setActionTime(index, time) {
    this.editingInterlock.action[index].time = time;
  }

  addAction() {
    this.editingInterlock.action.push({
      device_id: ServerRoomStore.serverRoom.children[0].id,
      active: true,
      control_entity: "device_control_air",
      data: ServerRoomStore.serverRoom.children[0].device_control_air,
      device_type_id: 1,
      time: 5,
    });
  }

  deleteAction(index) {
    this.editingInterlock.action.splice(index, 1);
  }

  addCondition(serverRoom) {
    const device = serverRoom.children[0];

    this.editingInterlock.condition.push({
      device_id: device.id,
      op: "STOP_WORKING",
      value: 0, // value is not used in STOP_WORING
    });
  }

  removeCondition(index) {
    this.editingInterlock.condition.splice(index, 1);
  }

  setCondition(index, key, value) {
    this.editingInterlock.condition[index][key] = value;
    // console.log(this.editingInterlock.condition);
  }

  patchDevice(device, index) {
    let result = cloneDeep(device);
    if (result.children) {
      let children: any[] = [];
      for (let child of result.children) {
        children.push(this.patchDevice(child, index));
      }
      result.children = children;
      return result;
    } else {
      let data = this.editingInterlock.action[index];
      // console.log('ABC', deviceTypeId2controlEntity(device.device_type_id))
      result[deviceTypeId2controlEntity(device.device_type_id)] = data.data;
      let device_status =
        result[deviceTypeId2controlEntity(device.device_type_id)].device_status;
      if (device_status == 0) {
        result.status = "off";
      } else {
        result.status = "on";
      }
      return result;
    }
  }
}

export const InterlockStore = new InterlockStoreClass();

export async function fetchInterlocks(alwaysLoading = false) {
  if (ACCESS_TOKEN && ACTIVE_BUILDING_ID) {
    let response = await globalLoadingFn(
      () => api.get("/interlocks?building_id=" + ACTIVE_BUILDING_ID),
      { firstTime: alwaysLoading || InterlockStore.interlocksFirstTime }
    );
    const interlocks = response.data;
    InterlockStore.loadInterlockForEdit(
      interlocks[InterlockStore.activeInterlockIndex]
    );
    InterlockStore.setInterlocks(interlocks);
  }
}

export async function newInterlock() {
  let interlock = InterlockStore.newInterlock();
  let interlockResponse = await globalLoadingFn(() =>
    api.post("/interlocks", interlock)
  );
  await fetchInterlocks(true);
  InterlockStore.setActiveInterlockIndex(InterlockStore.interlocks.length - 1);
  InterlockStore.loadInterlockForEdit(interlockResponse.data);
}

export async function patchInterlock() {
  const index = InterlockStore.activeActionIndex;
  let interlockResponse = await globalLoadingFn(() =>
    api.patch(
      "/interlocks/" + InterlockStore.editingInterlock.id,
      InterlockStore.editingInterlock
    )
  );
  await fetchInterlocks(true);

  InterlockStore.setActiveActionIndex(index);
}

export async function deleteInterlock(interlock_id) {
  InterlockStore.setActiveInterlockIndex(0);
  await globalLoadingFn(() => api.delete("/interlocks/" + interlock_id));
  await fetchInterlocks(true);
}

export async function getInterlock(interlock_id) {
  return (await globalLoadingFn(() => api.get("/interlocks/" + interlock_id)))
    .data;
}

fetchInterlocks();

setInterval(() => {
  fetchInterlocks();
}, 60000);
