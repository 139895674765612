import { toLongDateString } from "../../../utils/datetime";
import Table from "../../common/Table";

import startCase from "lodash/startCase";

function convertData(data) {
  function isDeviceScopeType(s) {
    return s.scope_type == "device";
  }

  return data.map((d) => {
    const device_schedule_scopes =
      d.device_schedule_scopes.filter(isDeviceScopeType);
    const acNames = device_schedule_scopes.map((s) => s.scope.name);
    const updatedDateString = toLongDateString(d.updated_at);
    const openTime = d.device_schedules
      .find((x) => x.schedule_wrapper_type == "common_on")
      ?.every_time.slice(0, 5);
    const closeTime = d.device_schedules
      .find((x) => x.schedule_wrapper_type == "common_off")
      ?.every_time.slice(0, 5);

    var activeTime = "";
    if (openTime && closeTime) {
      activeTime = openTime + "-" + closeTime;
    } else if (openTime) {
      activeTime = openTime;
    } else {
      activeTime = "ปิด " + closeTime;
    }

    return {
      id: d.id,
      Schedule: d.name,
      Time: activeTime,
      AC: acNames.join(", "),
      Status: startCase(d.status),
      Update: updatedDateString,
    };
  });
}

export default function ScheduleTable({ schedules, onEdit }) {
  return (
    <Table
      headers={["Schedule", "Time", "AC", "Status", "Update"]}
      editable={true}
      onEdit={onEdit}
      data={convertData(schedules)}
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(50px, 1fr))",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    />
  );
}
