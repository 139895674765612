import { TextField as MUITextField } from "@mui/material";
import { GRAY_BACKGROUND_COLOR } from "../../theme/style";

export default function TextField({
  id,
  label = "",
  value,
  onChange,
  style = { borderRadius: 16 },
  fontSize = "1rem",
  ...props
}) {
  return (
    <MUITextField
      id={id}
      label={label}
      variant="standard"
      value={value}
      onChange={(event) => onChange(event.target.value)}
      fullWidth
      style={{
        ...style,
        background: GRAY_BACKGROUND_COLOR,
        padding: 10,
      }}
      InputProps={{
        disableUnderline: true,
        style: {
          fontSize: fontSize,
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: "1.5rem",
          marginLeft: 10,
          // visibility: value == "" ? "visible" : "hidden",
        },
      }}
      {...props}
    />
  );
}
