import React from "react"

export default function Vertical() {
  return (
    <div style={{
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      height: "100%",
    }}>
      <iframe className="vertical-iframe" src="/"></iframe>
      <iframe className="vertical-iframe" src="/?page=control"></iframe>
    </div>
  )
}