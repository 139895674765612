import React, { useState } from 'react';
import Modal from '../components/Modal';
import { alertErrorComplete, alert_success } from './toast';

export let IS_GLOBAL_LOADING = 0;

// Working because only one LoadingContainer
let setLoading = (loading)=>{IS_GLOBAL_LOADING = loading};

export function globalLoading(loading?: boolean | undefined) {
  if (typeof loading === "undefined") loading = IS_GLOBAL_LOADING == 0;

  setLoading(loading ? IS_GLOBAL_LOADING + 1 : IS_GLOBAL_LOADING - 1);
}

export async function globalLoadingFn(fn, {performBack404 = false, firstTime = true, successMessage = ""} = {}) {
  try {
    if (firstTime) {
      globalLoading(true)
    }

    let response = await fn();

    if (successMessage) {
      alert_success(successMessage)
    }

    return response
  } catch (err) {
    alertErrorComplete(err, {performBack404})

    throw err;
  } finally {
    if (firstTime) {
      globalLoading(false)
    }
  }
}

export function Loading() {
  return <div style={{display: "flex", justifyContent: "center", alignItems: "center", zIndex: 100000}}>
    <div className="spinner-border" style={{color: "#9ce2f8" /*"#49C5F1"*/}}></div>
  </div>
}

export function LoadingContainer() {
  const [loading, setLoadingRaw] = useState(IS_GLOBAL_LOADING);

  setLoading = (l) => {
    setLoadingRaw(l)
    IS_GLOBAL_LOADING = l;
  }

  return (
    <Modal show={loading > 0} centerOnly noWrapper>
      <Loading></Loading>
    </Modal>
  )
}