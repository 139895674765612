import { BLACK_CARD } from "../../theme/style";
import PropTypes from "prop-types";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  vertical: PropTypes.bool,
  noBackground: PropTypes.bool,
  boxStyle: PropTypes.any,
};

export default function TabPanel(props) {
  const { children, value, index, noBackground, boxStyle, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
      style={{ width: "100%" }}
    >
      {value === index && (
        <div
          style={
            noBackground ? { ...boxStyle } : { ...BLACK_CARD, ...boxStyle }
          }
        >
          {children}
        </div>
      )}
    </div>
  );
}
