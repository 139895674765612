import React from "react";
import BlackSelect from '../common/BlackSelect';



export default function DeviceListFloorSelect({ data, selectedId, onChange, style = {}, optional = false }) {
  const options = data.children.map((floor) => ({
    value: floor.id,
    label: floor.name,
  }));

  return (
    <div>
      <BlackSelect
        onChange={onChange}
        value={selectedId}
        options={options}
        style={style}
        optional={optional}
      ></BlackSelect>
    </div>
  );
}
