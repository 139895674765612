import { makeAutoObservable } from "mobx";
import { api } from "../utils/api";
import { weeklyBarData } from "../utils/chart";
import { ACCESS_TOKEN, ACTIVE_BUILDING_ID } from "../utils/globalState";
import { globalLoadingFn } from "../utils/loading";

export class SensorStoreClass {
  sensorData: any = {};
  sensorDataFirstTime: boolean = true;

  constructor() {
    makeAutoObservable(this);
  }

  setSensorData(sensorData: any) {
    this.sensorData = sensorData;
    this.sensorDataFirstTime = false;
  }

  getSensorValue() {
    const energyData = this.sensorData;

    const weeklyEnergy = weeklyBarData(energyData?.weekly?.data.find(c => c.name == 'Energy'));
    const dailyTemperature = energyData?.daily?.data.find(c => c.name == 'Temperature');
    const dailyHumidity = energyData?.daily?.data.find(c => c.name == 'Humidity');
    const dailyPM2_5 = energyData?.daily?.data.find(c => c.name == 'PM2.5');
    const dailyCO2 = energyData?.daily?.data.find(c => c.name == 'CO2');
  
    const lastTemperature = dailyTemperature ? dailyTemperature.y[dailyTemperature.y.length - 1] : null;
    const lastHumidity = dailyHumidity ? dailyHumidity.y[dailyHumidity.y.length - 1] : null;
    const lastPM2_5 = dailyPM2_5 ? dailyPM2_5.y[dailyPM2_5.y.length - 1] : null;
    const lastCO2 = dailyCO2 ? dailyCO2.y[dailyCO2.y.length - 1] : null;

    return {
      weeklyEnergy,
      dailyTemperature,
      dailyHumidity,
      dailyPM2_5,
      dailyCO2,
      lastTemperature,
      lastHumidity,
      lastPM2_5,
      lastCO2,
    }
  }
}

export const SensorStore = new SensorStoreClass();

export async function fetchSensorData() {
  if (ACCESS_TOKEN && ACTIVE_BUILDING_ID) {
    let response = await globalLoadingFn(() => api.get('/sensors/bruno_sensor_data?scope_type=building&scope_id=' + ACTIVE_BUILDING_ID), {firstTime: SensorStore.sensorDataFirstTime});
    SensorStore.setSensorData(response.data);
  }
}

fetchSensorData();

setInterval(() => {
  fetchSensorData();
}, 10000);
