const urlParams = new URLSearchParams(window.location.search);

// export const IS_TABLET = window.innerWidth > window.innerHeight
export const IS_TABLET = urlParams.get('tablet') == '1';

export let ACCESS_TOKEN = urlParams.get('access_token') || window.localStorage.getItem('ACCESS_TOKEN');

// export let ACTIVE_BUILDING_ID = 2
export let ACTIVE_BUILDING_ID = parseInt(window.localStorage.getItem('ACTIVE_BUILDING_ID') ?? '0');
export let ACTIVE_ROOM_ID = parseInt(window.localStorage.getItem('ACTIVE_ROOM_ID') ?? '0');
// export let ACTIVE_BUILDING_ID = 15
