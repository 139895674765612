import React from "react"
import SensorBar from "../sensor/SensorBar"
import { SensorDataStruct } from "../sensor/SensorInterface"

export interface VAMItemProps {
  name: string;
  floorName: string;
  supplyFan: boolean;
  exhaustFan: boolean;
  mode: string;
  pressure: string;
  active: boolean;
  RA: SensorDataStruct
  onClick: () => any;
}

export default function VAMItem(props: VAMItemProps) {
  return (
    <div style={{backgroundColor: "rgba(0,0,0," + (props.active ? 1 : 0.3) + ")", padding: 16, borderRadius: 12}} onClick={props.onClick}>
      <div style={{display: 'grid', gridTemplateColumns: '40% 50% 10%', marginBottom: 16}}>
        <div>{props.name}</div>
        <div>Floor: {props.floorName}</div>
        {/* <div>??</div> */}
      </div>

      <hr />

      <div>
        <SensorBar
          mini={true}
          {...props.RA}
        ></SensorBar>
      </div>

      <hr/>

      <div style={{display: 'grid', gridTemplateColumns: '40% 40% 20%', marginTop: 16}}>
        <div>
          <div>Supply Fan: {props.supplyFan ? 'ON' : 'OFF'}</div>
          <div>Exhaust Fan: {props.exhaustFan ? 'ON' : 'OFF'}</div>
        </div>

        <div>
          <div>Mode: {props.mode}</div>
          <div>Pressure: {props.pressure}</div>
        </div>

        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'}}>
          <div style={{textDecoration: 'underline'}}>
             รายละเอียด
          </div>
        </div>
      </div>
    </div>
  )
}