import React, { useEffect, useState } from "react"
import {range} from "lodash"
import { FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { ACCESS_TOKEN, ACTIVE_BUILDING_ID } from "../../utils/globalState";
import HeaderCommon from "../common/HeaderCommon";

export default function EnergyPage(props) {
  return (
    <div style={{height: "100%"}}>
      <HeaderCommon title={props.title || "Energy"} compact={true}></HeaderCommon>

      <div style={{position: "relative"}}>
        <div style={{position: "absolute", top: 0, left: 0, padding: 16, width: "100%"}}>
          <iframe
            src={"https://airin1.com/energy?type=building&id=" + ACTIVE_BUILDING_ID + "&bruno=1&access_token=" + ACCESS_TOKEN}
            style={{
              width: "100%",
              height: "calc(100vh - 200px)",
              border: "none",
            }}
          ></iframe>
        </div>
      </div>
    </div>
  )
}