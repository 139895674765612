import { Switch } from "@mui/material";
import { observer } from "mobx-react-lite";
import {
  deleteAllSchedule,
  deleteSchedule,
  patchAllSchedule,
  patchSchedule,
  ScheduleStore,
} from "../../../stores/ScheduleStore";
import {
  FLEX_COLUMN_CENTER_CENTER,
  FLEX_COLUMN_START_CENTER,
  FLEX_ROW_CENTER_CENTER,
} from "../../../theme/style";
import AddButton from "../../common/AddButton";
import DateSelect from "../../common/DateSelect";
import TextField from "../../common/TextField";
import ScheduleACControl from "./ScheduleACControl";

const DAY_OPTIONS = [
  { value: 0, label: "อาทิตย์" },
  { value: 1, label: "จันทร์" },
  { value: 2, label: "อังคาร" },
  { value: 3, label: "พุธ" },
  { value: 4, label: "พฤหัสบดี" },
  { value: 5, label: "ศุกร์" },
  { value: 6, label: "เสาร์" },
];

export default observer<any>(function ScheduleForm(props) {
  if (!ScheduleStore.editingGroupedSchedule?.id) return <div></div>
  
  console.log({...ScheduleStore.editingGroupedSchedule});

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "4fr 4fr 1fr 1fr",
        gridTemplateRows: "min-content",
        gap: 16,
        margin: "2%",
        height: "100%",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <TextField
        id={ScheduleStore.editingGroupedSchedule.id.toString()}
        value={ScheduleStore.editingGroupedSchedule.name}
        onChange={(v) => {
          ScheduleStore.setName(v);
        }}
        style={{ borderRadius: 32 }}
      ></TextField>

      <DateSelect
        className="schedule-form_page__day-select"
        options={DAY_OPTIONS}
        selected={ScheduleStore.editingGroupedSchedule.repeat_day}
        onChange={(v) => ScheduleStore.setRepeatDays(v)}
      />
      <div style={{ alignSelf: "center" }}>
        <Switch
          size="medium"
          checked={ScheduleStore.editingGroupedSchedule.status == "active"}
          onChange={(e) => ScheduleStore.setActive(e.target.checked)}
        />
      </div>
      <div
        style={{
          ...FLEX_ROW_CENTER_CENTER,
          alignSelf: "center",
          gap: 12,
          width: "100%",
        }}
      >
        <img
          src="/img/common/save.svg"
          width="16px"
          onClick={() => patchAllSchedule()}
        />
        <img
          src="/img/common/delete-white.svg"
          width="20px"
          onClick={() => deleteAllSchedule()}
        />
      </div>
      <div
        style={{
          ...FLEX_COLUMN_START_CENTER,
          alignSelf: "center",
          width: "auto",
          gridColumn: "1 / span 4",
          height: "100%",
          gap: 16,
        }}
      >
        {ScheduleStore.editingGroupedSchedule.device_schedule_wrappers.map(
          (_, index) => {
            return (
              <ScheduleACControl
                key={index}
                index={index}
                active={index == ScheduleStore.activeDeviceScheduleWrapperIndex}
                onClick={() =>
                  ScheduleStore.setActiveDeviceScheduleWrapperIndex(index)
                }
                includeGroup={props.includeGroup ?? false}
              />
            );
          }
        )}
        <AddButton onClick={() => ScheduleStore.addDeviceScheduleWrapper()} />
      </div>
    </div>
  );
});
