import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import PinPage from "./components/common/PinPage";
import DashboardPage from "./components/dashboard/DashboardPage";
import DashboardTabletPage from "./components/dashboard/DashboardTabletPage";
import FloorDevices from "./components/device-list/FloorDevices";
import RoomDevices from "./components/device-list/RoomDevices";
import EnergyPage from "./components/energy/EnergyPage";
import ScheduleBuildingPage from "./components/schedule/ScheduleBuildingPage";
import InterlockPage from "./components/serverroom/InterlockPage";
import ServerRoomPage from "./components/serverroom/ServerRoomPage";
import AccountPage from "./components/settings/AccountPage";
import AppSettingPage from "./components/settings/AppSettingPage";
import GallerySettingPage from "./components/settings/GallerySettingPage";
import LoginPage from "./components/settings/LoginPage";
import UpdatePinPage from "./components/settings/UpdatePinPage";
import VAM from "./components/vam/VAM";
import Vertical from "./components/Vertical";
import { DeviceStore, toggleBreaker } from "./stores/DeviceStore";
import { RouterStore } from "./stores/RouterStore";
import { api } from "./utils/api";
import { ACTIVE_BUILDING_ID, IS_TABLET } from "./utils/globalState";
import { globalLoadingFn } from "./utils/loading";
import { alert_error } from "./utils/toast";

export default observer(function RootRouter() {
  const page = RouterStore.page;
  const section = RouterStore.section;

  useEffect(() => {
    window.resetRootRouterHeight();
  }, []);

  // if (page == 'gallery-settings') return <GallerySettingPage></GallerySettingPage>

  return (
    <div id="router-carousel" style={{ height: "100%" }}>
      <Carousel
        preventMovementUntilSwipeScrollTolerance={true}
        swipeScrollTolerance={
          RouterStore.enableChangeSectionVariable ? 400 : 10000
        }
        showThumbs={false}
        selectedItem={section}
        swipeable={RouterStore.isSwipeable(RouterStore.page[RouterStore.section])}
        onChange={(index) => RouterStore.changeSection(index)}
      >
        {/* <div className="router-section">
          <FloorDevices></FloorDevices>
        </div> */}

        <div className="router-section">
          {page[0] == 'dashboard' && (IS_TABLET ? <DashboardTabletPage></DashboardTabletPage> : <DashboardPage></DashboardPage>)}
          {page[0] == 'gallery-settings' && <GallerySettingPage></GallerySettingPage>}
          {page[0] == 'app-settings' && <AppSettingPage></AppSettingPage>}
          {page[0] == 'login' && <LoginPage></LoginPage>}
          {page[0] == 'vertical' && <Vertical></Vertical>}
          {page[0] == 'schedule' && <ScheduleBuildingPage></ScheduleBuildingPage>}
          {page[0] == 'account' && <AccountPage></AccountPage>}
          {page[0] == 'update-pin' && <UpdatePinPage></UpdatePinPage>}

          {page[0] == "login-pin" && (
            <PinPage
              onClick={(pin) => {
                if (pin == DeviceStore.accessPin) {
                  RouterStore.setAwaitingPinPage(false);
                  RouterStore.navigateToLastPage();
                } else {
                  alert_error('Invalid Pin');
                }
              }}
            ></PinPage>
          )}

          {page[0] == "login-update-pin" && (
            <PinPage
              onClick={(pin) => {
                if (pin == DeviceStore.accessPin) {
                  RouterStore.navigate('update-pin');
                } else {
                  alert_error('Invalid Pin');
                }
              }}
            ></PinPage>
          )}

          {page[0] == "update-breaker-pin" && (
            <PinPage
              title="Enter new pin"
              onClick={async (pin) => {
                await globalLoadingFn(() => api.patch("/buildings/" + ACTIVE_BUILDING_ID, {
                  breaker_pin: pin,
                }));
                RouterStore.navigate('dashboard');
              }}
            ></PinPage>
          )}

          {page[0] == "update-access-pin" && (
            <PinPage
              title="Enter new pin"
              onClick={async (pin) => {
                await globalLoadingFn(() => api.patch("/buildings/" + ACTIVE_BUILDING_ID, {
                  access_pin: pin,
                }));
                RouterStore.navigate('dashboard');
              }}
            ></PinPage>
          )}

          {page[0] == "update-server-room-pin" && (
            <PinPage
              title="Enter new pin"
              onClick={async (pin) => {
                await globalLoadingFn(() => api.patch("/buildings/" + ACTIVE_BUILDING_ID, {
                  server_room_pin: pin,
                }));
                RouterStore.navigate('dashboard');
              }}
            ></PinPage>
          )}
        </div>

        <div className="router-section">
          {page[1] == "control" &&
            (IS_TABLET ? (
              <RoomDevices></RoomDevices>
            ) : (
              <FloorDevices></FloorDevices>
            ))}
          {page[1] == "breaker-pin" && (
            <PinPage
              onClick={(pin) => toggleBreaker(pin, DeviceStore.breaker)}
            ></PinPage>
          )}
        </div>

        <div className="router-section">{page[2] == "vam" && <VAM></VAM>}</div>
        <div className="router-section">
          {page[3] == "server-room" && <ServerRoomPage ></ServerRoomPage>}
          {page[3] == "interlock" && <InterlockPage ></InterlockPage>}
        </div>

        <div className="router-section">
          {page[4] == "energy-page" && <EnergyPage ></EnergyPage>}
        </div>
      </Carousel>
    </div>
  );
});
