import { Divider } from "@mui/material";
import { Children } from "react";
import { DeviceStore } from "../../stores/DeviceStore";
import { ServerRoomStore } from "../../stores/ServerRoomStore";
import { BLACK_CARD, FLEX_COLUMN_CENTER_CENTER } from "../../theme/style";
import { countDevices } from "../../utils/device";
import Stepper from "../common/Stepper";
import Text from "../common/Text";
import DeviceSummary from "../device-list/DeviceSummary";
import InterlockSummary from "../serverroom/interlock/InterlockSummary";
import RoomStatus from "./RoomStatus";
import "./ServerRoomStatus.css";

function ACStatus({ style, devices }) {
  return (
    <div style={style}>
      <Text fontSize="1.75rem">Air condition status</Text>
      <div style={{ ...FLEX_COLUMN_CENTER_CENTER }}>
        <Stepper
          data={[
            {
              label: "1",
              element: <DeviceSummary key="device-summary" devices={devices} />,
            },
            {
              label: "2",
              element: <InterlockSummary key="interlock-summary" devices={devices} />,
            },
          ]}
        />
      </div>
    </div>
  );
}

export default function ServerRoomStatus({
  serverRoom,
  style,
  onEditRoomStatus,
}) {
  const activeServerRoom = serverRoom;
  const devices = countDevices(ServerRoomStore.serverRoom.children, 1);
  console.log(ServerRoomStore.serverRoom)

  return (
    <div
      style={{
        ...BLACK_CARD,
        ...FLEX_COLUMN_CENTER_CENTER,
        height: "100%",
      }}
    >
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "19fr 1fr",
          ...style,
        }}
      >
        <ACStatus style={style} devices={devices} />
        <div>
          <img
            src="/img/common/edit.svg"
            width={25}
            onClick={onEditRoomStatus}
            style={{ textAlign: "right" }}
          ></img>
        </div>
      </div>

      <div className="server-room-status__divider">
        <Divider style={{ background: "gray" }} />
      </div>

      <RoomStatus
        sensorStandard={activeServerRoom.sensor_standard}
        style={style}
        devices={ServerRoomStore.serverRoom.children}
      />
    </div>
  );
}
