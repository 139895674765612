import React from "react";
import { BLACK_CARD } from "../../theme/style";
import { SensorDataStruct } from "../sensor/SensorInterface";
import VAMSensorBar from "./VAMSensorBar";
import Switch from "react-switch"
import { globalLoadingFn } from "../../utils/loading";
import { api } from "../../utils/api";
import { deviceTypeId2controlEntity } from "../../utils/device";
import { fetchBuilding } from "../../stores/DeviceStore";

export interface VAMInnerProps {
  deviceId: number;
  deviceTypeId: number;
  name: string;
  floorName: string;
  supplyFan: boolean;
  exhaustFan: boolean;
  supplyFanLevel: number;
  exhaustFanLevel: number;
  mode: string;
  pressure: string;
  filterPerf: number;
  modeId: number;

  OA: SensorDataStruct;
  RA: SensorDataStruct;
  SA: SensorDataStruct;
}

function VAMInnerFan({ label, level, onoff, onChange, onSwitch }) {
  return (
    <div style={{ ...BLACK_CARD }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{label}</div>
        <div>
          <Switch
            onChange={onSwitch}
            checked={onoff}
            checkedIcon={false}
            uncheckedIcon={false}
            onColor="#FFA726"
          />
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginTop: 16 }}>
        <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
          <div>
            <img src="/img/control/VAM/fan-solid.svg"></img>
          </div>
          <div>Speed</div>
        </div>
        <div onClick={() => onChange(level == 3 ? 1 : level + 1)}>
          <img src={`/img/control/VAM/fan-${level}.svg`} height={60}></img>
        </div>
      </div>
    </div>
  );
}

function VAMStatusText({title, value, color}) {
  return (
    <div style={{display: "flex", flexDirection: "column", justifyContent: "space-around", padding: 12, height: "100%"}}>
      <div>{title}</div>
      <div style={{
        textAlign: "center",
        color,
        fontSize: '2rem',
      }}>{value}</div>
    </div>
  )
}

export default function VAMInner(props: VAMInnerProps) {
  // console.log(props)

  return (
    <div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "60% 1fr",
          columnGap: 16,
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "40% 1fr",
            columnGap: 16,
            padding: 16,
            ...BLACK_CARD,
          }}
        >
          <div>
            <div>ชื่อ &nbsp;{props.name}</div>
          </div>

          <div>
            {/* <div>ช่วงเวลา &nbsp;20:00 - 07:45</div> */}
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            columnGap: 16,
            padding: 16,
            ...BLACK_CARD,
          }}
        >
          <div>
            <div>Mode Pressure: {props.mode}</div>
          </div>
        </div>
      </div>

      <div style={{ ...BLACK_CARD, marginTop: 16, padding: 36 }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "180px 1fr 300px",
            alignItems: "center",
          }}
        >
          <div>
            <VAMSensorBar
              horizontal={false}
              title="OA"
              description="Fresh Air From Outdoor"
              {...props.OA}
            ></VAMSensorBar>
          </div>

          <div>
            <img
              src={"/img/control/VAM/preview/" + props.modeId + ".gif"}
              style={{ width: "100%" }}
            ></img>
          </div>

          <div style={{
            minHeight: 380,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}>
            <VAMSensorBar
              horizontal={true}
              title="RA"
              description="Return Air From Room"
              {...props.OA}
            ></VAMSensorBar>
            <VAMSensorBar
              horizontal={true}
              title="SA"
              description="Supply Air To Room"
              {...props.OA}
            ></VAMSensorBar>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "45% auto auto",
          columnGap: 16,
          marginTop: 16,
        }}
      >
        <div style={{ ...BLACK_CARD }}>
          <div style={{paddingLeft: 12}}>Status</div>

          <div style={{ display: "grid", gridTemplateColumns: "48% 52%", height: "90%" }}>
            <div style={{borderRight: "solid 1px #707070"}}>
              <VAMStatusText
                title="Filter PERF."
                value={props.filterPerf.toFixed(0) + '%'}
                color={props.pressure != "Normal" ? "#ff9b99" : "#5FE3A1"}
              ></VAMStatusText>
            </div>

            <div style={{paddingLeft: 12}}>
              <VAMStatusText
                title="Pressure"
                value={props.pressure}
                color={props.pressure != "Normal" ? "#ff9b99" : "#5FE3A1"}
              ></VAMStatusText>
            </div>
          </div>
        </div>

        <VAMInnerFan
          label="Supply Fan"
          level={props.supplyFanLevel}
          onoff={props.supplyFan}
          onChange={async (level) => {
            await globalLoadingFn(() =>
              api.patch(
                `/devices/${deviceTypeId2controlEntity(
                  props.deviceTypeId
                )}?scope_type=device&scope_id=${props.deviceId}`,
                {device_fan_speed: (level * 2 + 1).toString()}
              )
            );

            await fetchBuilding();
          }}
          onSwitch={async (onoff) => {
            await globalLoadingFn(() =>
              api.post(
                `/devices/${deviceTypeId2controlEntity(
                  props.deviceTypeId
                )}/turn_${onoff ? 'on' : 'off'}?scope_type=device&scope_id=${props.deviceId}`
              )
            );

            await fetchBuilding();
          }}
        ></VAMInnerFan>

        <VAMInnerFan
          label="Exhaust Fan"
          level={props.exhaustFanLevel}
          onoff={props.exhaustFan}
          onChange={async (level) => {
            await globalLoadingFn(() =>
              api.patch(
                `/devices/${deviceTypeId2controlEntity(
                  props.deviceTypeId
                )}?scope_type=device&scope_id=${props.deviceId}`,
                {device_fan_speed: (level * 2 + 1).toString()}
              )
            );

            await fetchBuilding();
          }}
          onSwitch={async (onoff) => {
            await globalLoadingFn(() =>
              api.post(
                `/devices/${deviceTypeId2controlEntity(
                  props.deviceTypeId
                )}/turn_${onoff ? 'on' : 'off'}?scope_type=device&scope_id=${props.deviceId}`
              )
            );

            await fetchBuilding();
          }}
        ></VAMInnerFan>
      </div>
    </div>
  );
}
