import { FLEX_LR_CENTER } from "../../theme/style";
import DetailButton from "../common/DetailButton";

export default function AirConditionerStatusList({
  devices,
  style = {},
  onSelectRoom,
}) {
  return (
    <div
      style={{
        ...FLEX_LR_CENTER,
        ...style,
      }}
    >
      <div
        style={{
          display: "grid",
          width: "80%",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        {devices.map((device) => {
          return (
            <div key={device.id}>
              {device.name}: {device.is_error ? "Error" : "Normal"}
            </div>
          );
        })}
      </div>
      <DetailButton onClick={onSelectRoom} />
    </div>
  );
}
