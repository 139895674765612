import { observer } from "mobx-react-lite";
import React, { useState } from "react"
import useInterval from "../../hooks/useInterval";
import { DeviceStore } from "../../stores/DeviceStore";
import { BLACK_CARD, FLEX_COLUMN_CENTER_CENTER, FLEX_LR_CENTER, FLEX_ROW_CENTER } from "../../theme/style"
import { formatLongDate, formatTime } from "../../utils/datetime";
import { countDevices } from "../../utils/device";

const WEATHER_TEXT = {
  1: 'อากาศแจ่มใส ด้านนอกมีแดดจ้า',
  2: 'มีเมฆเป็นบางส่วน',
  3: 'มีเมฆมาก',
  4: 'เมฆฝนกำลังก่อตัว',
  9: 'มีฝนตก',
  10: 'มีฝนตกหนัก',
  11: 'มีฝนฟ้าคะนอง',
  13: 'มีหิมะตก',
  50: 'มีหมอกมาก',
}

export default observer<any>(function DashboardHeader({ aqi, temp, icon }) {
  const [date, setDate] = useState(Date.now());
  useInterval(() => setDate(Date.now()), 1000);

  const building = DeviceStore.building;

  const hour = new Date().getHours();

  let partText = "";

  if (hour < 6) {
    partText = "กลางคืน"
  } else if (hour < 11) {
    partText = "เช้า"
  } else if (hour < 13) {
    partText = "เที่ยง"
  } else if (hour < 16) {
    partText = "บ่าย"
  } else if (hour < 18) {
    partText = "เย็น"
  } else {
    partText = "กลางคืน"
  }

  let iconId = parseInt(icon[0] + icon[1]);
  let iconUrl = "http://openweathermap.org/img/wn/" + iconId.toString().padStart(2, '0') + (partText == "กลางคืน" ? "n" : "d") + ".png"

  console.log(icon)

  return (
    <div style={{
      display: "grid",
      gridTemplateColumns: "55% auto",
      columnGap: 16
    }}>
      <div style={{...BLACK_CARD, padding: 24}}>
        <div style={{...FLEX_LR_CENTER}}>
          <div>
            <div style={{ fontSize: '2rem' }}>
              สวัสดีตอน{partText}
            </div>

            <div>{formatLongDate(date)}</div>
          </div>

          <div style={{ fontSize: '3rem' }}>
            {formatTime(date)}
          </div>
        </div>

        <div style={{...FLEX_ROW_CENTER}}>
          <div style={{marginRight: 12}}>
            <img src={iconUrl}></img>
          </div>

          <div>
            สภาพอากาศ{partText.replace("กลาง", "")}นี้{WEATHER_TEXT[iconId]}
          </div>
        </div>
      </div>

      <div style={{...BLACK_CARD, padding: 24, paddingLeft: 24, paddingRight: 24}}>
        <div style={{display: "flex", justifyContent: "space-between", height: "100%"}}>
          <div>
            <div style={{fontSize: '1.25rem'}}>{building?.district}</div>
            <div style={{fontSize: '3rem'}}>
              {temp}℃
            </div>
          </div>

          <div style={{...FLEX_COLUMN_CENTER_CENTER}}>
            <img src="/img/bear/2.gif" style={{height: 120}}></img>
          </div>
        </div>
      </div>
    </div>
  )
})