import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { deleteInterlock, InterlockStore, newInterlock, patchInterlock } from "../../stores/InterlockStore";
import {
  BLACK_CARD,
  FLEX_COLUMN_CENTER_CENTER,
  FLEX_LR_CENTER,
  FLEX_ROW_CENTER,
  FLEX_ROW_CENTER_CENTER,
} from "../../theme/style";
import { api } from "../../utils/api";
import { globalLoadingFn } from "../../utils/loading";
import ActiveInactiveText from "../common/ActiveInactiveText";
import Switch from "react-switch";

const CONDITION_OPTION = [
  { value: "STOP_WORKING", label: "หยุดทำงาน", valueRequired: false },
  { value: "START_WORKING", label: "เริ่มทำงาน", valueRequired: false },
  { value: "TEMP_GT", label: "อุณหภูมิสูงกว่า", valueRequired: true },
  { value: "TEMP_LT", label: "อุณหภูมิต่ำกว่า", valueRequired: true },
  // { value: "HUMIDITY_GT", label: "ความชื้นสูงกว่า", valueRequired: true },
  // { value: "HUMIDITY_LT", label: "ความชื้นต่ำกว่า", valueRequired: true },
  // { value: "CO2_GT", label: "CO2 สูงกว่า", valueRequired: true },
  // { value: "CO2_LT", label: "CO2 ต่ำกว่า", valueRequired: true },
  // { value: "PM2_5_GT", label: "PM2.5 สูงกว่า", valueRequired: true },
  // { value: "PM2_5_LT", label: "PM2.5 ต่ำกว่า", valueRequired: true },
];

export default observer(function InterlockPage() {
  const interlocks = InterlockStore.interlocks;

  return (
    <div style={{ ...FLEX_ROW_CENTER, height: "100%" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "150px auto",
          columnGap: 12,
        }}
      >
        <div style={{ ...FLEX_COLUMN_CENTER_CENTER }}>
          {interlocks.map((interlock) => (
            <div style={{}}>
              <ActiveInactiveText
                text={interlock.name}
                active={interlock.id == InterlockStore.editingInterlock.id}
                onClick={() => InterlockStore.loadInterlockForEdit(interlock)}
              ></ActiveInactiveText>
            </div>
          ))}

          <div style={{ ...FLEX_ROW_CENTER_CENTER, marginTop: 24 }}>
            <div
              style={{
                width: 50,
                height: 50,
                textAlign: "center",
                fontSize: "2rem",
                backgroundColor: "black",
                borderRadius: "50%",
              }}
              onClick={() => {
                newInterlock();
              }}
            >
              +
            </div>
          </div>
        </div>

        <div style={{ ...BLACK_CARD }}>
          <div style={{ ...FLEX_LR_CENTER, marginBottom: 24 }}>
            <div>
              <input
                style={{ width: 400 }}
                value={InterlockStore.editingInterlock.name}
                onChange={(e) => InterlockStore.setName(e.target.value)}
              ></input>
            </div>

            <div style={{ ...FLEX_ROW_CENTER }}>
              <div style={{ marginRight: 12 }}>
                <Switch
                  onChange={async (checked) => {
                    InterlockStore.setActive(checked)
                  }}
                  checked={InterlockStore.editingInterlock.is_active}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#FFA726"
                />
              </div>

              <div style={{ marginRight: 12 }} onClick={() => window.confirm("ยืนยันลบ Interlock " + InterlockStore.editingInterlock.name) && deleteInterlock(InterlockStore.editingInterlock.id)}>
                <img src="/img/common/delete-white.svg"></img>
              </div>

              <div style={{ marginRight: 12 }} onClick={() => patchInterlock()}>
                <img src="/img/common/save.svg"></img>
              </div>
            </div>
          </div>

          <div style={{
            display: 'grid',
            gridTemplateColumns: '30% 70%',
          }}>
            <div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
