import {
  BLACK_CARD,
  FLEX_ROW_CENTER,
  FLEX_ROW_CENTER_CENTER,
} from "../../theme/style";
import Text from "../common/Text";

export default function DeviceSummary({ devices }) {
  return (
    <div
      style={{
        ...FLEX_ROW_CENTER_CENTER,
        ...BLACK_CARD,
        width: "100%",
      }}
    >
      <div style={{ padding: 16, borderRight: "2px solid #707070" }}>
        <Text>
          ON: {devices.online}/{devices.total}
        </Text>
      </div>

      <div style={{ padding: 16, borderRight: "2px solid #707070" }}>
        <Text>Error {devices.error || "-"}</Text>
      </div>

      <div style={{ padding: 16 }}>
        <img src="/img/common/checkmark-outlined.svg"></img>
      </div>
    </div>
  );
}
