export default function DeleteButton({
  size = 1,
  color = "white",
  onClick = (e) => {},
  style = {},
}) {
  let IMAGE_SIZE = 20 * size;
  return (
    <div style={{ ...style }} onClick={onClick}>
      <img
        src={`/img/common/${color != "red" ? "delete-" + color : "delete"}.svg`}
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
      ></img>
    </div>
  );
}
