import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/material/styles";
import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

export default function Stepper({ data }) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = data.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {data.map((d) => {
          return <>{d.element}</>;
        })}
      </AutoPlaySwipeableViews>
      <MobileStepper
        style={{ backgroundColor: "black" }}
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={<div></div>}
        backButton={<div></div>}
      />
    </Box>
  );
}
