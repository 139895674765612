import {
  BLACK_CARD,
  FLEX_ROW_CENTER,
  GRAY_BACKGROUND_COLOR,
} from "../../theme/style";

function ACSensorBarItem({
  icon,
  label,
  sublabel = "ภายในห้อง",
  value,
  valueSubLabel,
  color,
  grayscale = false,
}) {
  const img_style = grayscale
    ? { marginRight: 8, filter: "grayscale(100%)" }
    : { marginRight: 8 };

  const text_color = grayscale ? "white" : color;

  return (
    <div
      style={{
        ...FLEX_ROW_CENTER,
        justifyContent: "space-between",
        backgroundColor: "transparent",
        padding: 0,
        borderRadius: 12,
      }}
    >
      <div style={{ ...FLEX_ROW_CENTER }}>
        <img src={icon} style={img_style}></img>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: "1.5rem",
            lineHeight: "",
            marginRight: 8,
          }}
        >
          {value}
        </div>
        <div style={{ color: "white" }}>{valueSubLabel}</div>
      </div>
    </div>
  );
}

function ACCard({ device, onClick, focus, id }) {
  const temp = device.device_control_air?.room_temp;
  return (
    <div
      style={{
        ...BLACK_CARD,
        marginLeft: 8,
        marginRight: 8,
        backgroundColor: focus ? GRAY_BACKGROUND_COLOR : "black",
        flex: "0 0 20%",
        flexBasis: 180,
      }}
      onClick={() => onClick(id)}
    >
      <div
        style={{
          ...FLEX_ROW_CENTER,
          justifyContent: "space-between",
        }}
      >
        <div>{device.name}</div>
        <div>Status: {device.status}</div>
      </div>
      <ACSensorBarItem
        icon={"/img/sensor/temp-outlined-active.svg"}
        label="อุณหภูมิ"
        value={temp || typeof temp === "number" ? parseFloat(temp).toFixed(0) + " ℃" : "-"}
        valueSubLabel=""
        color="#5FE3A1"
      ></ACSensorBarItem>
    </div>
  );
}

export default function ACStatusList({ devices, onClick, focus }) {
  return (
    <>
      {devices.map((device) => {
        return (
          <ACCard
            device={device}
            onClick={onClick}
            focus={focus[device.id.toString()]}
            id={device.id}
            key={device.id}
          />
        );
      })}
    </>
  );
}
