import { SensorDataStruct } from "../sensor/SensorInterface";
import "./ServerRoomSensorBar.css";

export function SensorBarItem({
  icon,
  label,
  sublabel = "ภายในห้อง",
  value,
  valueSubLabel,
  color,
  grayscale = false,
}) {
  const key = label.replace(" ", "").toLowerCase();

  return (
    <div className="server-room-sensor-bar-item__container">
      <div className="server-room-sensor-bar-item__label">
        <img
          //   width="40px"
          className="server-room-sensor-bar-item__img"
          src={icon}
        ></img>
        <div>
          <div className="server-room-sensor-bar-item__label-name">
            {key == "co2" ? (
              <div style={{ display: "flex", fontSize: "1.5rem" }}>
                CO<div style={{ fontSize: "1rem", lineHeight: 3 }}>2</div>
              </div>
            ) : (
              label
            )}
          </div>
          <div className="server-room-sensor-bar-item__sublabel-name">
            {sublabel}
          </div>
        </div>
      </div>

      <div className={`server-room-sensor-bar-item__measurement`}>
        <div
          className={`server-room-sensor-bar-item__measurement-value server-room-sensor-bar-item__measurement-value-${key}`}
        >
          {value} {key == "temperature" ? "\u00b0C" : ""}
        </div>
        <div className="server-room-sensor-bar-item__measurement-unit">
          {valueSubLabel}
        </div>
      </div>
    </div>
  );
}
export interface ServerRoomSensorBarProps extends SensorDataStruct {
  placeLabel?: string;
  grayscale?: boolean;
}

export default function ServerRoomSensorBar({
  placeLabel = "ห้อง",
  temp = 23,
  aqi = 20,
  humidity = 10,
  co2 = 10,
  grayscale = false,
}: ServerRoomSensorBarProps) {
  return (
    <div className="server-room-sensor-bar__container">
      <SensorBarItem
        icon={"/img/sensor/temp-outlined-active.svg"}
        label="Temperature"
        sublabel={"ภายใน" + placeLabel}
        value={temp || typeof temp === "number" ? temp.toFixed(0) : "-"}
        valueSubLabel=""
        color="#5FE3A1"
        grayscale={grayscale}
      ></SensorBarItem>

      <SensorBarItem
        icon={"/img/sensor/aqi-outlined-active.svg"}
        label="Air Quality"
        sublabel={"ภายใน" + placeLabel}
        value={aqi || typeof aqi === "number" ? aqi.toFixed(0) : "-"}
        valueSubLabel="US AQI"
        color="#A3A1FB"
        grayscale={grayscale}
      ></SensorBarItem>

      <SensorBarItem
        icon={"/img/sensor/humidity-outlined-active.svg"}
        label="Humidity"
        sublabel={"ภายใน" + placeLabel}
        value={
          humidity || typeof humidity === "number"
            ? humidity.toFixed(0) + "%"
            : "-"
        }
        valueSubLabel="Normal"
        color="#56D9FE"
        grayscale={grayscale}
      ></SensorBarItem>

      <SensorBarItem
        icon={"/img/sensor/co2-outlined-active.svg"}
        label="CO2"
        sublabel={"ภายใน" + placeLabel}
        value={co2 || typeof co2 === "number" ? co2.toFixed(0) + "%" : "-"}
        valueSubLabel="PPM"
        color="#FFA726"
        grayscale={grayscale}
      ></SensorBarItem>
    </div>
  );
}
