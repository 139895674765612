import { Button } from "@mui/material";
import { FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import Text from "./Text";

export default function AddButton({ onClick = () => {} }) {
  return (
    <Button
      style={{
        ...FLEX_ROW_CENTER_CENTER,
        border: "2px solid #53c8ea",
        borderRadius: 32,
        gap: 24,
        paddingLeft: 16,
        paddingRight: 16,
      }}
      onClick={onClick}
    >
      <Text color={"#53c8ea"}>Add</Text>
      <img src="/img/common/plus.svg" width={16} />
    </Button>
  );
}
