import { makeAutoObservable } from "mobx";
import { api } from "../utils/api";
import { ACCESS_TOKEN, ACTIVE_BUILDING_ID } from "../utils/globalState";
import { globalLoadingFn } from "../utils/loading";
import { cloneDeep } from "lodash";
import { deviceTypeId2controlEntity } from "../utils/device";
import { DeviceStore } from "./DeviceStore";

export class SceneStoreClass {
  scenes: any = [];
  scenesFirstTime: boolean = true;
  editingScene: any = null;

  editingSchedule = {
    "name": "Scene: Untitled",
    "request_reason": "Scene",
    "control_entity": "scene",
    "controls": {
      "scene": {
        "control_type": "fixed",
        "every_time": "13:25",
        "control_data": {
          "scene_id": 0
        }
      },
      "scene_end": {
        "control_type": "fixed",
        "every_time": "13:25",
        "control_data": {
          "scene_id": 0
        }
      },
    },
    "repeat_day": [0, 1, 3],
    "repeat_date": [],
    "include_date": ["2020-11-11", "2020-09-09"],
    "except_date": ["2020-12-12"],
    "scopes": [
      { "scope_type": "building", "scope_id": ACTIVE_BUILDING_ID }
    ],
    "building_id": ACTIVE_BUILDING_ID,
    "timezone": "+07:00",
  }

  editingScheduleEnabled = false;

  constructor() {
    makeAutoObservable(this);
  }

  setScenes(scenes: any) {
    this.scenes = scenes;
    this.scenesFirstTime = false;
    this.updateSceneActive();
  }

  updateSceneActive() {
    console.log("UPDATE SCENE ACTIVE");

    if (DeviceStore.buildingFirstTime) return;

    for (let scene of this.scenes) {
      let allActive = true;
      let nonEmpty = false;
      let activeData = scene.data.filter(x => x.active);
      for (let data of activeData) {
        let device = DeviceStore.devices.find(x => x.id == data.device_id);
        if (!device) continue;
        nonEmpty = true;
        if (device[deviceTypeId2controlEntity(device.device_type_id)].device_status != data.data.device_status) {
          allActive = false;
          break;
        }
      }

      scene.running = allActive && nonEmpty;
    }

    console.log(this.scenes);
  }

  newSceneSchedule() {
    let editingSchedule = cloneDeep(this.editingSchedule);
    editingSchedule.name = "Scene: Untitled";
    editingSchedule.controls = {
      "scene": {
        "control_type": "fixed",
        "every_time": `${new Date().getHours()}:${new Date().getMinutes()}`,
        "control_data": {
          "scene_id": 0
        }
      },
    }
    editingSchedule.repeat_day = [];
    editingSchedule.repeat_date = [];
    editingSchedule.include_date = [];
    editingSchedule.except_date = [];
    this.editingSchedule = editingSchedule;
    return editingSchedule;
  }

  loadSceneForEdit(scene) {
    this.editingScene = scene;
    if (scene.schedule_id) {
      this.editingScheduleEnabled = true;
      this.editingSchedule = scene.schedule_data;
    } else {
      this.editingScheduleEnabled = false;
      this.newSceneSchedule();
    }
  }

  reloadEditingScene() {
    this.loadSceneForEdit(this.scenes.find(x => x.id == this.editingScene.id));
  }

  resetEditingScene() {
    this.editingScene = null;
  }

  setSceneName(name) {
    this.editingScene.name = name;
  }

  setSceneTime(time) {
    this.editingSchedule.controls.scene.every_time = time;
  }

  toggleDay(key, day) {
    if (this.editingSchedule[key].indexOf(day) == -1) {
      this.editingSchedule[key].push(day);
    } else {
      this.editingSchedule[key].splice(this.editingSchedule[key].indexOf(day), 1);
    }
  }

  getControlData(device, dontpush = false) {
    let data = this.editingScene.data.find(d => d.device_id == device.id);

    if (!data) {
      data = {
        device_id: device.id,
        device_iot_id: device.device_iot_id,
        device_type_id: device.device_type_id,
        control_entity: deviceTypeId2controlEntity(device.device_type_id),
        data: device[deviceTypeId2controlEntity(device.device_type_id)],
        active: false,
      };

      if (!dontpush) {
        this.editingScene.data.push(data);
      }
    }

    // console.log(this.editingScene);

    return data;
  }

  setControlActive(device, active) {
    let data = this.getControlData(device);
    data.active = active;
  }

  setFavorite(favorite) {
    this.editingScene.favorite = favorite;
  }

  setScheduleEnabled(enabled) {
    this.editingScheduleEnabled = enabled;
  }

  setSceneTimeEnd(time) {
    this.editingScene.schedule_end = time;
  }

  setIcon(icon) {
    this.editingScene.icon = icon;
  }

  setControlData(device, controlKey, controlValue) {
    let data = this.getControlData(device);
    data.data[controlKey] = controlValue;
  }

  patchDevice(device) {
    let result = cloneDeep(device);
    if (result.children) {
      let children: any[] = [];
      for (let child of result.children) {
        children.push(this.patchDevice(child));
      }
      result.children = children;
      return result;
    } else {
      let data = this.getControlData(device, true);
      // console.log('ABC', deviceTypeId2controlEntity(device.device_type_id))
      result[deviceTypeId2controlEntity(device.device_type_id)] = data.data;
      let device_status = result[deviceTypeId2controlEntity(device.device_type_id)].device_status;
      if (device_status == 0) {
        result.status = 'off';
      } else {
        result.status = 'on';
      }
      return result;
    }
  }

  getFavoriteScenes() {
    return this.scenes.filter(scene => scene.favorite);
  }

  getSceneForDeviceList() {
    let deviceIds = this.editingScene.data.filter(x => x.active).map(x => x.device_id);
    let devices: any[] = [];

    for (let id of deviceIds) {
      devices.push(DeviceStore.devices.find(x => x.id == id));
    }

    devices = devices.filter(x => x);

    let result = this.patchDevice({children: devices});

    result.id = 0;

    return result;
  }
}

export const SceneStore = new SceneStoreClass();

export async function fetchScenes(alwaysLoading = false) {
  if (ACCESS_TOKEN && ACTIVE_BUILDING_ID) {
    let response = await globalLoadingFn(() => api.get('/schedules/scene/buildings/' + ACTIVE_BUILDING_ID), {firstTime: alwaysLoading || SceneStore.scenesFirstTime});
    SceneStore.setScenes(response.data);
  }
}

export async function newScene() {
  let editingSchedule = SceneStore.newSceneSchedule();
  await globalLoadingFn(() => api.post('/schedules/scene/buildings/' + ACTIVE_BUILDING_ID, editingSchedule))
  await fetchScenes(true);
  SceneStore.loadSceneForEdit(editingSchedule);
}

// Just update single data
export async function updateSceneData(scene_id = SceneStore.editingScene.id, data = SceneStore.editingScene.data) {
  let scene = (await globalLoadingFn(() => api.get('/schedules/scene/' + scene_id))).data;
  scene.data = data;
  
  await globalLoadingFn(() => api.put('/schedules/scene/' + scene_id + '/data', scene.data))
  await fetchScenes(true);
  SceneStore.reloadEditingScene();
}

export async function updateSceneMetadata(scene_id, {name, active, favorite, icon, schedule_end, refreshData = true}) {
  await globalLoadingFn(() => api.put('/schedules/scene/' + scene_id, {name, active, favorite, icon, schedule_end}))
  if (refreshData) {
    await fetchScenes(true);
    SceneStore.reloadEditingScene();
  }
}

export async function updateSceneSchedule(scene_id = SceneStore.editingScene.id) {
  if (SceneStore.editingScheduleEnabled) {
    await globalLoadingFn(() => api.put('/schedules/scene/' + scene_id + '/schedule', SceneStore.editingSchedule))
  } else {
    await globalLoadingFn(() => api.delete('/schedules/scene/' + scene_id + '/schedule'))
  }
  
  await fetchScenes(true);
  SceneStore.reloadEditingScene();
}

export async function deleteScene(scene_id) {
  await globalLoadingFn(() => api.delete('/schedules/scene/' + scene_id))
  await fetchScenes(true);
  SceneStore.resetEditingScene();
}

export async function getScene(scene_id) {
  return (await globalLoadingFn(() => api.get('/schedules/scene/' + scene_id))).data;
}

fetchScenes();

setInterval(() => {
  fetchScenes();
}, 60000);
