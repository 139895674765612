import { observer } from "mobx-react-lite";
import React from "react"
import { DeviceStore } from "../../stores/DeviceStore";
import { RouterStore } from "../../stores/RouterStore";
import { SceneStore } from "../../stores/SceneStore";
import { FLEX_LR_CENTER, FLEX_ROW_CENTER, BLACK_CARD } from "../../theme/style";
import { api } from "../../utils/api";
import { countDevices, ZERO_DEVICE_COUNT } from "../../utils/device";
import { globalLoadingFn } from "../../utils/loading";
import HeaderCommon from "../common/HeaderCommon"
import { MapView } from "../device-list/MapView";

function DeviceCountCard({ label, amount, logo, color, unit }) {
  return (
    <div style={{ ...BLACK_CARD, marginTop: 8, marginBottom: 8 }}>
      <div style={{ ...FLEX_ROW_CENTER, marginTop: 8 }}>
        <img src={logo}></img>
        <div
          style={{ fontSize: "2.25rem", color, marginLeft: 36, marginRight: 8, flexGrow: 1 }}
        >
          {amount}
        </div>
        <div style={{ fontSize: "1.5rem" }}>{unit}</div>
      </div>
    </div>
  );
}

export default observer(function DashboardTabletPage() {
  const favoriteScenes = SceneStore.getFavoriteScenes();

  const lightCount = DeviceStore.activeRoom?.children ? countDevices(DeviceStore.activeRoom, 3) : ZERO_DEVICE_COUNT;
  const airCount = DeviceStore.activeRoom?.children ? countDevices(DeviceStore.activeRoom, 1) : ZERO_DEVICE_COUNT;
  const curtainCount = DeviceStore.activeRoom?.children ? countDevices(DeviceStore.activeRoom, 4) : ZERO_DEVICE_COUNT;
  const doorCount = DeviceStore.activeRoom?.children ? countDevices(DeviceStore.activeRoom, 5) : ZERO_DEVICE_COUNT;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >      
      <HeaderCommon compact={true} title=" "></HeaderCommon>

      <div style={{ padding: 24 }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '280px 1fr',
            columnGap: 16,
            marginBottom: 16,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <DeviceCountCard
              label="จำนวนหลอดไฟที่เปิด"
              amount={lightCount.online}
              logo="/img/control/light/light-active-circle.svg"
              color="#FFA726"
              unit="ดวง"
            ></DeviceCountCard>
  
            <DeviceCountCard
              label="จำนวนแอร์ที่เปิด"
              amount={airCount.online}
              logo="/img/control/air/air-active-circle.svg"
              color="#56D9FE"
              unit="เครื่อง"
            ></DeviceCountCard>

            <DeviceCountCard
              label="จำนวนม่านที่เปิด"
              amount={curtainCount.online}
              logo="/img/control/curtain/curtain-active-circle.svg"
              color="#5FE3A1"
              unit="จุด"
            ></DeviceCountCard>

            <DeviceCountCard
              label="จำนวนประตูที่เปิด"
              amount={doorCount.online}
              logo="/img/control/door/door-active-circle.svg"
              color="#A3A1FB"
              unit="จุด"
            ></DeviceCountCard>
          </div>

          <div>
            <MapView 
              data={DeviceStore.activeRoom}
              onChangeDevice={(device) => {}}
              activeDeviceId={0}
              activeRoomId={DeviceStore.activeRoom?.id}
              roomMode={true}
            ></MapView>
          </div>
        </div>

        <div style={{ ...FLEX_LR_CENTER, marginBottom: 16, fontSize: '1.3rem' }}>
          <div><b>Favorite Scene</b></div>
          <div onClick={() => {
            RouterStore.setControlPageTabMode('scene');
            RouterStore.navigate('control');
          }}><u>More Scene</u></div>
        </div>

        <div style={{ ...FLEX_ROW_CENTER, overflowX: 'auto', marginBottom: 24 }}>
          {favoriteScenes.map(scene =>
            <div
              style={{
                ...BLACK_CARD,
                ...FLEX_ROW_CENTER,
                paddingTop: 28,
                paddingBottom: 28,
                paddingLeft: 36,
                paddingRight: 36,
                marginRight: 16,
                minWidth: 240,
              }}
              onClick={async () => {
                await globalLoadingFn(() => api.post('/schedules/scene/' + scene.id + '/apply'));
              }}
            >
              <div style={{ marginRight: 16}}>
                <img src={scene.icon}></img>
              </div>
              <div>
                {scene.name}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})