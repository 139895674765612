import { observer } from "mobx-react-lite";
import React from "react";
import { DeviceStore } from "../../stores/DeviceStore";
import { FLEX_ROW_CENTER } from "../../theme/style";

export default observer<any>(function DeviceTypeSelect({
  activeDeviceType,
  setActiveDeviceType,
  onBreakerSelect,
}) {
  return (
    <div
      style={{
        ...FLEX_ROW_CENTER,
        justifyContent: "space-between",
        borderRadius: 100,
        backgroundColor: "black",
        padding: 12,
        paddingLeft: 28,
        paddingRight: 28,
      }}
    >
      <div
        className="hover-pointer device-type-select-btn"
        onClick={() => setActiveDeviceType(3)}
      >
        <img
          src={`/img/control/light-outlined-${
            activeDeviceType == 3 ? "active" : "white"
          }.svg`}
        ></img>
      </div>
      
      <div
        className="hover-pointer device-type-select-btn"
        onClick={() => setActiveDeviceType(1)}
      >
        <img
          src={`/img/control/air-outlined-${
            activeDeviceType == 1 ? "active" : "white"
          }.svg`}
        ></img>
      </div>

      <div
        className="hover-pointer device-type-select-btn"
        onClick={() => setActiveDeviceType(4)}
      >
        <img
          src={`/img/control/curtain-outlined-${
            activeDeviceType == 4 ? "active" : "white"
          }.svg`}
        ></img>
      </div>

      <div
        className="hover-pointer device-type-select-btn"
        onClick={() => setActiveDeviceType(5)}
      >
        <img
          src={`/img/control/door-outlined-${
            activeDeviceType == 5 ? "active" : "white"
          }.svg`}
        ></img>
      </div>

      {DeviceStore.breaker &&
        <div
          className="hover-pointer device-type-select-btn"
          onClick={() => {
            setActiveDeviceType(6);
            onBreakerSelect();
          }}
        >
          <img
            src={`/img/control/breaker-outlined-${
              activeDeviceType == 6 ? "active" : "white"
            }.svg`}
          ></img>
        </div>
      }
    </div>
  );
})
