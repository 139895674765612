import { Button, Tab, Tabs } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import {
  fetchInterlocks,
  InterlockStore,
  newInterlock,
} from "../../../stores/InterlockStore";
import { ServerRoomStore } from "../../../stores/ServerRoomStore";
import {
  FLEX_COLUMN_START,
  FLEX_COLUMN_START_CENTER,
  FLEX_ROW_CENTER_CENTER,
} from "../../../theme/style";
import TabPanel from "../../common/TabPanel";
import Text from "../../common/Text";
import InterlockForm from "./InterlockForm";
import "./InterlockFormPage.css";

type InterlockFormPageProps = {
  onBackToDetail: () => {};
};

export default observer(function InterlockFormPage<InterlockFormPageProps>({
  onBackToDetail,
}) {
  const interlocks = InterlockStore.interlocks;
  const serverRoom = ServerRoomStore.serverRoom;

  useEffect(() => {
    fetchInterlocks();
  }, []);

  const handleChange = (_, newValue) => {
    InterlockStore.setActiveInterlockIndex(newValue);
    InterlockStore.setActiveActionIndex(0);
    InterlockStore.loadInterlockForEdit(interlocks[newValue]);
  };

  const addInterlock = () => {
    newInterlock();
  };

  const orientation =
    window.innerHeight < window.innerWidth ? "landscape" : "portrait";

  return (
    <div
      style={{
        ...FLEX_COLUMN_START,
        width: "100%",
        height: "100%",
      }}
    >
      <div className="interlock-form-page__interlock-title">
        <img
          src="/img/common/left-arrow-gray.svg"
          width={36}
          onClick={onBackToDetail}
        />
        <Text style={{ ...FLEX_ROW_CENTER_CENTER }}>
          {serverRoom.name} &gt; Interlock
        </Text>
      </div>
      <div className="interlock-form-page__container">
        <div
          style={{
            ...FLEX_COLUMN_START_CENTER,
            height: "100%",
            flexBasis: "10%",
          }}
        >
          <Tabs
            value={InterlockStore.activeInterlockIndex}
            onChange={handleChange}
            orientation="vertical"
          >
            {interlocks.map((s) => {
              return <Tab key={s.id} label={s.name} />;
            })}
          </Tabs>
          <Button onClick={addInterlock}>
            <img src="/img/common/plus-white.svg" width={32} />
          </Button>
        </div>
        {interlocks.map((il, index) => {
          return (
            <TabPanel
              value={InterlockStore.activeInterlockIndex}
              index={index}
              key={`interlock-${il.id}`}
              boxStyle={{ height: orientation == "landscape" ? 600 : 1000 }}
            >
              <InterlockForm key={il.id} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
});
