import React from "react";
import { getDeviceColor } from "../../theme/deviceTheme";
import { FLEX_ROW_CENTER, FLEX_ROW_CENTER_CENTER } from "../../theme/style";
import { api } from "../../utils/api";
import { deviceTypeId2controlEntity } from "../../utils/device";
import { globalLoadingFn } from "../../utils/loading";

export default function ControlButton({
  scopeType,
  scopeId,
  device,
  image = "",
  label = "",
  active = false,
  controlKey,
  controlValueFn,
  refreshData,
  padding = 6,
  noBorder = false,
  size = 1,
  onControl = (device, controlKey, controlValue) => {},
  overrideOnControl = false,
  width = "auto",
  backgroundColor = "gray",
  ...props
}) {
  let IMAGE_SIZE = (noBorder ? 24 : 20) * size;

  return (
    <div
      style={{
        ...FLEX_ROW_CENTER_CENTER,
        padding: padding,
        width: width,
        // alignItems: "center",
        margin: 4,
        ...(!noBorder
          ? {
              backgroundColor: backgroundColor,
              borderRadius: 12,
              border: active
                ? `2px solid ${getDeviceColor(device.device_type_id)}`
                : "none",
              color: active ? getDeviceColor(device.device_type_id) : "white",
            }
          : {}),
      }}
      onClick={async (e) => {
        e.preventDefault();
        if (!overrideOnControl) {
          if (controlKey == "device_status") {
            let onoff = controlValueFn(device) == 1 ? "on" : "off";
            await globalLoadingFn(() =>
              api.post(
                `/devices/${deviceTypeId2controlEntity(
                  device.device_type_id
                )}/turn_${onoff}?scope_type=${scopeType}&scope_id=${scopeId}`
              )
            );
          } else {
            await globalLoadingFn(() =>
              api.patch(
                `/devices/${deviceTypeId2controlEntity(
                  device.device_type_id
                )}?scope_type=${scopeType}&scope_id=${scopeId}`,
                {
                  [controlKey]: controlValueFn(device),
                }
              )
            );
          }
        }

        onControl(device, controlKey, controlValueFn(device));

        refreshData();
      }}
    >
      {image && (
        <img
          src={image + (active ? "-active" : "") + ".svg"}
          width={IMAGE_SIZE}
          height={IMAGE_SIZE}
        ></img>
      )}
      {label && (
        <div style={{ marginLeft: 8 }}>
          <div>{label}</div>
        </div>
      )}
      {props.children}
    </div>
  );
}
